import {
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  colors,
} from "@mui/material"
import React, { useState } from "react"
import {
  Alert,
  Box,
  Button,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material"
import {
  FormControlStyles,
  InputLabelStyles,
  PrimarybuttoStyle,
  containerStyles,
  credentialsStyles,
  forgetStyles,
  innerloginstyles,
  leftstyles,
  loginstyles,
  logostyles,
  rightstyles,
  signinStyle,
  textinputStyles,
} from "./forgotpasswordts"
import Logo from "../../../../assets/img/atlas-world-logo.png"
import { CheckBox, TextInputField } from "../../../fields"
import { PrimaryButton } from "../../../button"
import axios from "axios"
import { formDataRequest } from "../../../../services/api-service"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { setAdminDetails, setMessage } from "../../../../models/admin/adminSlice"
// const [login,setLogin] = useState()

const Forgotpassword = () => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const dispatch = useAppDispatch()

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault()
    console.log(username, password)
    try {
      const response = await formDataRequest.post("/api/admin/login", {
        email: username,
        password: password,
      })
      console.log("Login successful!", response.data.admin)
      if (response.data.token) {
        dispatch(
          setAdminDetails({
            token: response.data.token,
            id: response.data.admin.id,
            isLoggedIn: true,
            name: response.data.admin.name,
            username: response.data.admin.email,
          //   message: {
          //     body: "",
          //     severity: "error",
          // },
        }),
        )
        window.location.href = '/admin/producttable';
        dispatch(setMessage({ body: "Login Successful.", severity: "success" }))
      } else {
        dispatch(
          setMessage({ body: "Invalid Credentials.", severity: "error" }),
        )
      }
      
    } catch (error) {
      console.error("Login failed!", error)
    }
    
  }
  const handleForgotpassword = async (e: React.FormEvent) => {
    
      
    window.location.href = '/admin/otpview';
   
}


  return (
    <Container sx={containerStyles}>
      {/* <Grid container rowSpacing={3}> */}
      <Container sx={leftstyles}>
        <Box
          component="img"
          sx={logostyles}
          alt="Atlas World Logo"
          src={Logo}
        />
      </Container>
      <Container sx={rightstyles}>
        <Box sx={loginstyles}>
          <Container sx={innerloginstyles}>
            <Typography sx={signinStyle} variant="h5">
              Forgot Password
            </Typography>
            <Box sx={credentialsStyles}>
              <form style={{ width: "100%" }}>
                <FormControl sx={FormControlStyles}>
                  <InputLabel sx={InputLabelStyles}></InputLabel>
                  <Typography sx={{color:"white"}}>Please enter your email address to recieve a code to reset your acount password</Typography>
                </FormControl>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel sx={InputLabelStyles}></InputLabel>
                  <TextInputField
                    sx={textinputStyles}
                    placeholder="Enter your Email Address"
                    name="password"
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormControl>
                <PrimaryButton sx={PrimarybuttoStyle} onClick={handleForgotpassword}>
                  Send Code
                </PrimaryButton>
              </form>
            </Box>
          </Container>
        </Box>
      </Container>
      {/* </Grid> */}
    </Container>
  )
}

export default Forgotpassword
