import styled from "styled-components"

export const MainContainer = styled.main<{ drawerwidth: string }>`
  width: calc(100% - ${(props) => props.drawerwidth});
  flex-grow: 1;
  margin-left: ${(props) => props.drawerwidth};
  margin-top: 70px;
  padding-left: 34px;
  padding-right: 34px;
  padding-bottom: 10px;
`
