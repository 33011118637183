export const ContainerStyles = {
  height: "111px",
  maxWidth: "unset !important",
  paddingRight: { xs: "16px", sm: "90px", md: "0 !important" },
  paddingLeft: { xs: "16px", sm: "90px" },
  position: { xs: "relative", md: "unset" },
}

export const LinkStyles = {
  fontWeight: 700,
  color: { xs: "#FF5600", md: "#FFFFFF" },
  fontSize: "20px",
  cursor: "pointer",
  marginLeft: { xs: "0", md: "32px" },
  "& a": {
    fontWeight: 700,
    color: { xs: "#FF5600", md: "#FFFFFF" },
    textDecoration: "none",
  },
}
