export const ContainerStyles = {
  backgroundColor: "rgba(0, 0, 0, 0.3)",
  maxWidth: "unset !important",
  position: "fixed",
  right: 0,
  top: 0,

  // minHeight: "80vh",
  minHeight: "100vh",
  maxHeight: "100px",
  display: "flex",
  width: "100%",
  zIndex: 1000,
}

export const InnerContainerStyles = {
  width: "100%",
  maxWidth: "1120px !important",
  position: "relative",
  backgroundColor: "white",
  minHeight: { xs: "100vh", lg: "100vh" },
  pt: { xs: "100px !important", sm: "100px !important" },
  pb: { xs: "100px !important", sm: "100px !important" },
  pr: { xs: "50px !important", sm: "100px !important" },
  pl: { xs: "50px !important", sm: "100px !important" },
  zIndex: 1000,
  overflowY: "scroll",
}

export const FormContainer = {
  background: "rgba(255, 255, 255, 0.9)",
  display: "flex",
  flexDirection: "column",
  gap: "41px",
  mt: "47px",
  px: "84px",
  py: "74px",
  borderRadius: "10px",
}

export const Heading = {
  fontSize: "40px",
  fontWeight: "700",
  lineHeight: "43px",
  letterSpacing: "0em",
  textAlign: "center",
}

export const SubHeading = {
  fontSize: "29px",
  fontWeight: "700",
  lineHeight: "30px",
  letterSpacing: "0em",
  textAlign: "left",
  mt: "-54px",
  mb: "35px",
}

export const TwoColFields = {
  display: "flex",
  gap: { xs: "10px", md: "20px" },
  flexDirection: { xs: "column", md: "row" },
  position: "relative",
  mb: "30px",
}

export const ButtonStyles = {
  width: "auto",
  height: "25px",
  borderRadius: "50%",
  fontSize: "25px",
  cursor: "pointer",
}

export const ImageBox = {
  padding: "unset",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgba(100, 100, 100, 0.1)",
  borderRadius: "10px",
  cursor: "pointer",
  width: "100px",
  height: "100px",
}

export const ImageBoxSelected = {
  padding: "unset",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgba(100, 100, 100, 0.1)",
  border: "2px solid #999",
  borderRadius: "10px",
  cursor: "pointer",
  width: "100px",
  height: "100px",
  backgroundSize:"contain"
}

export const imageTag = {
  width: "100%",
  height: "auto",
  "@media (min-width: 600px)": {
    maxWidth: "20px",
  },
  "@media (min-width: 900px)": {
    maxWidth: "20px",
  },
}
