import { ChangeEvent, useEffect, useState } from "react"
import { Box, CircularProgress, Grid, Typography } from "@mui/material"
import { useDispatch } from "react-redux"
import { addAlert } from "../../../../models/alert/alertSlice"
import SidePopup from "../../../../components/sidePopUp"
import CustomInput from "../../../../components/customInput"
import CustomButton from "../../../../components/buttons"
import { AXIOSINSTANCE } from "../../../../services/api-service"

interface EditUserProps {
  isPopupOpen: boolean
  selectedID: number
  handleClosePopup: any
}

interface UserFormData {
  id: number | null
  userName: string
  email: string
  contactNo: number | null
}

const EditUser = ({
  isPopupOpen,
  handleClosePopup,
  selectedID,
}: EditUserProps) => {
  const dispatch = useDispatch()

  const [formData, setFormData] = useState<UserFormData>({
    userName: "",
    email: "",
    contactNo: null,
    id: null,
  })

  const [contactNoError, setContactNoError] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)
  const [pageLoading, setPageLoading] = useState(true)

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }))
  }

  // Fetch trader details
  const fetchUserDetail = async () => {
    try {
      const response = await AXIOSINSTANCE().get(`/api/admin/${selectedID}`)

      if (response.status == 200) {
        setFormData({
          userName: response.data.data.name,
          email: response.data.data.email,
          contactNo: response.data.data.mobile.substring(3),
          id: response.data.data.id,
        })
      }
    } catch (error) {
      setPageLoading(false)
      console.log("Fetch trader error", error)
    } finally {
      setPageLoading(false)
    }
  }

  // check contact number
  const checkContactNumber = async (value: any) => {
    if (value.length <= 10) {
      setFormData((prevData) => ({
        ...prevData,
        contactNo: value,
      }))
    }
    if (value.length < 9) {
      setContactNoError("Contact number is invalid")
    } else {
      setContactNoError("")
    }
  }

  async function handleButtonaction(): Promise<void> {
    try {
      setLoading(true)
      if (!formData.userName || !formData.email || !formData.contactNo) {
        dispatch(
          addAlert({
            alertState: true,
            alertType: "Warning",
            alertMessage: "Error",
            alertDescription: "All fields Required",
          }),
        )
        return
      }

      const body: any = {}
      body.id = formData.id
      if (formData.userName) {
        body.name = formData.userName
      }

      if (formData.email) {
        body.email = formData.email
      }

      if (formData.contactNo) {
        body.mobile = `+94${formData.contactNo}`
      }

      const response = await AXIOSINSTANCE().post(`/api/admin/update`, body)

      if (response.status == 200) {
        dispatch(
          addAlert({
            alertState: true,
            alertType: "Success",
            alertMessage: "Successfully Updated",
            alertDescription: "User successfully updated.",
          }),
        )
        handleClosePopup()
      }
    } catch (error: any) {
      const errorMessage = error.response.data.errors
      let alertDescription = ""

      // Concatenate error messages
      for (const key in errorMessage) {
        if (errorMessage.hasOwnProperty(key)) {
          const errorMessagesArray = errorMessage[key]
          errorMessagesArray.forEach((message: any) => {
            alertDescription += `${message}\n`
          })
        }
      }

      dispatch(
        addAlert({
          alertState: true,
          alertType: "Error",
          alertMessage: "Error",
          alertDescription: alertDescription.trim(),
        }),
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchUserDetail()
  }, [])
  return (
    <SidePopup
      onClose={handleClosePopup}
      title={"Edit User"}
      open={isPopupOpen}
    >
      {pageLoading ? (
        // Show loader while loading
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              padding: "30px",
              flex: 1,
              overflow: "hidden",
              overflowY: "auto",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "#344054",
                      }}
                    >
                      Full Name
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <CustomInput
                      id={0}
                      TextFieldName={"userName"}
                      labelText={""}
                      TextFieldType={"text"}
                      variant={"outlined"}
                      onchangeFunction={handleFieldChange}
                      errorTextState={false}
                      errorText={""}
                      value={formData.userName}
                      textFieldSize={"small"}
                      placeHolderText={"Yoshika"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* email */}
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "#344054",
                      }}
                    >
                      Email
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <CustomInput
                      id={0}
                      TextFieldName={"email"}
                      labelText={""}
                      TextFieldType={"text"}
                      variant={"outlined"}
                      onchangeFunction={handleFieldChange}
                      errorTextState={false}
                      errorText={""}
                      value={formData.email}
                      textFieldSize={"small"}
                      placeHolderText={"yoshika@gmail.com"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* phone number*/}
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "#344054",
                      }}
                    >
                      Phone Number
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <CustomInput
                      id={3}
                      TextFieldName={"contactNo"}
                      labelText={""}
                      placeHolderText={"771234567"}
                      TextFieldType={"number"}
                      variant={"outlined"}
                      onchangeFunction={(e: any) => {
                        checkContactNumber(e.target.value)
                      }}
                      errorTextState={contactNoError === "" ? false : true}
                      errorText={contactNoError}
                      value={formData.contactNo}
                      textFieldSize={"small"}
                      startAdornment={
                        <div
                          style={{
                            borderRight: "1px solid #D0D5DD",
                            paddingRight: "10px",
                            height: "44px",
                            display: "flex",
                            alignItems: "center",
                            marginRight: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#000000",
                            }}
                          >
                            +94
                          </Typography>
                        </div>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <div style={{ marginTop: "auto" }}>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                padding: 2,
                backgroundColor: "#ffffff",
                gap: "8px",
                marginTop: "auto",
                borderTop: "1px solid #ccc",
              }}
            >
              <Grid item>
                <CustomButton
                  variant={"outlined"}
                  buttonText={"Cancel"}
                  id={1}
                  buttonFunction={() => {
                    handleClosePopup()
                  }}
                  style={{
                    borderRadius: "10px",
                    width: "110px",
                  }}
                  type="secondary"
                  buttonWidth="110px"
                />
              </Grid>
              <Grid item>
                <CustomButton
                  variant={"contained"}
                  buttonText={
                    loading ? (
                      <CircularProgress sx={{ color: "white" }} size={28} />
                    ) : (
                      "Update"
                    )
                  }
                  disableState={loading ? true : false}
                  id={0}
                  buttonFunction={() => {
                    handleButtonaction()
                  }}
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                    borderRadius: "10px",
                  }}
                  buttonWidth="110px"
                />
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </SidePopup>
  )
}

export default EditUser
