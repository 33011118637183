import { SxProps, TextField, Theme } from "@mui/material"

const CustomInput = ({
  id,
  TextFieldName,
  labelText,
  variant,
  TextFieldType,
  placeHolderText,
  value,
  textFieldSize,
  inputRefValue,
  onKeyPressFunctionData,
  errorTextState,
  errorText,
  style,
  startAdornment,
  endAdornment,
  inputDisabled,
  multiLine,
  onchangeFunction,
}: {
  id: number
  TextFieldName: string
  labelText: string
  TextFieldType?: string
  variant: "outlined" | "filled" | "standard"
  value?: any
  textFieldSize?: "small" | "medium"
  inputRefValue?: any
  placeHolderText?: string
  inputDisabled?: boolean
  onchangeFunction?: (e: any) => void
  onKeyPressFunctionData?: {
    eventName: string
    eventFunction: (e?: any) => void
  }
  errorTextState?: boolean
  errorText?: string
  style?: SxProps<Theme>
  startAdornment?: any
  endAdornment?: any
  multiLine?: boolean
}) => {
  const keyDownFunction = (event: any) => {
    if (event.key == onKeyPressFunctionData?.eventName) {
      onKeyPressFunctionData?.eventFunction()
    }
  }

  return (
    <>
      <TextField
        key={id}
        name={TextFieldName}
        label={labelText}
        variant={variant}
        value={value || ""}
        type={TextFieldType || "text"}
        error={errorTextState || false}
        helperText={errorText || ""}
        size={textFieldSize}
        placeholder={placeHolderText || ""}
        inputRef={inputRefValue}
        disabled={inputDisabled || false}
        sx={{
          ...(style
            ? style
            : {
                width: "100%",
                "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                "& input": { height: "27px", fontSize: "14px" },
                "& label": { fontSize: "14px", paddingTop: "3px" },
              }),
        }}
        InputProps={{
          startAdornment: startAdornment ? startAdornment : null,
          endAdornment: endAdornment ? endAdornment : null,
          style: { fontSize: "16px" },
        }}
        onChange={onchangeFunction}
        onKeyUp={keyDownFunction}
        multiline={multiLine || false}
        rows={multiLine ? 4 : 1}
      />
    </>
  )
}

export default CustomInput
