import {
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  colors,
  styled,
} from "@mui/material"
import React, { useState } from "react"
import {
  Alert,
  Box,
  Button,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material"
import {
  FormControlStyles,
  InputLabelStyles,
  PrimarybuttoStyle,
  containerStyles,
  credentialsStyles,
  forgetStyles,
  innerloginstyles,
  leftstyles,
  loginstyles,
  logostyles,
  rightstyles,
  signinStyle,
  textinputStyles,
} from "./otpviewstyles"
import Logo from "../../../../assets/img/atlas-world-logo.png"
import { CheckBox, TextInputField } from "../../../fields"
import { PrimaryButton } from "../../../button"
import axios from "axios"
import { formDataRequest } from "../../../../services/api-service"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { setAdminDetails, setMessage } from "../../../../models/admin/adminSlice"
// import { MuiOtpInput } from 'mui-one-time-password-input'
// const [login,setLogin] = useState()

const Otpview = () => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const dispatch = useAppDispatch()

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault()
    console.log(username, password)
    try {
      const response = await formDataRequest.post("/api/admin/login", {
        email: username,
        password: password,
      })
      console.log("Login successful!", response.data.admin)
      if (response.data.token) {
        dispatch(
          setAdminDetails({
            token: response.data.token,
            id: response.data.admin.id,
            isLoggedIn: true,
            name: response.data.admin.name,
            username: response.data.admin.email,
          //   message: {
          //     body: "",
          //     severity: "error",
          // },
        }),
        )
        window.location.href = '/admin/producttable';
        dispatch(setMessage({ body: "Login Successful.", severity: "success" }))
      } else {
        dispatch(
          setMessage({ body: "Invalid Credentials.", severity: "error" }),
        )
      }
      
    } catch (error) {
      console.error("Login failed!", error)
    }
    
  }

  const [otp, setOtp] = React.useState('')

  const handleChange = (newValue) => {
    setOtp(newValue)
  }
  // const CustomOtpInput = styled(MuiOtpInput)({
  //   '& .MuiOutlinedInput-root': {
  //     background: 'white',
  //     '& input': {
  //       color: 'black',
  //       width: '36px', // Adjust the width
  //       height: '36px', // Adjust the height
  //       fontSize: '24px',
  //       fontWeight:'bold', // Adjust the font size if needed
  //       textAlign: 'center',
  //       // paddingLeft:"45px",
  //       // paddingRight:"0px"
  //       // left:"15px"
  //     },
  //   },
  // });
  const handleForgotpassword = async (e: React.FormEvent) => {
    
      
    window.location.href = '/admin/resendpassword';
   
}
  return (
    <Container sx={containerStyles}>
      {/* <Grid container rowSpacing={3}> */}
      <Container sx={leftstyles}>
        <Box
          component="img"
          sx={logostyles}
          alt="Atlas World Logo"
          src={Logo}
        />
      </Container>
      <Container sx={rightstyles}>
        <Box sx={loginstyles}>
          <Container sx={innerloginstyles}>
            <Typography sx={signinStyle} variant="h5">
              Enter OTP Code
            </Typography>
            <Box sx={credentialsStyles}>
              <form style={{ width: "100%" }}>
                <FormControl sx={FormControlStyles}>
                  <InputLabel sx={InputLabelStyles}></InputLabel>
                  <Typography sx={{color:"white"}}>Please enter the 5 - digit OTP code sent to your email address.</Typography>
                </FormControl>
                {/* <FormControl sx={{ width: "100%" }}>
                  <InputLabel sx={InputLabelStyles}></InputLabel>
                  
                </FormControl> */}
                <Box sx={{
                  paddingLeft:"12%",
                  width:"100%",
                  paddingTop:"15px"
                  }}>
                {/* <CustomOtpInput value={otp} onChange={handleChange} /> */}
                </Box>
                <PrimaryButton sx={PrimarybuttoStyle} onClick={handleForgotpassword}>
                  Send Code
                </PrimaryButton>
                <Box sx={{color:"white",display:"flex",paddingTop:"30px",paddingLeft:"15%"}}>
                  <Typography>Didn't Recieved the code yet?</Typography>
                  <Typography sx={{fontWeight:"bold",paddingLeft:"5px"}}>Re-send code</Typography>
                </Box>
              </form>
            </Box>
          </Container>
        </Box>
      </Container>
      {/* </Grid> */}
    </Container>
  )
}

export default Otpview
