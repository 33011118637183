import {
  Alert,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material"
import { containerStyles } from "./styles"
import LandingHeroVideo from "../../assets/videos/landing-page-animation.mp4"
import LandingNewVideo from "../../assets/videos/landing-page-new.mp4"
import LoginBg from "../../assets/img/landing-page-login-bg.png"
import RegisterBg from "../../assets/img/landing-page-register-bg.png"
import { CheckBox, TextInputField } from "../../components/fields"
import { useEffect, useState } from "react"
import { PrimaryButton } from "../../components/button"
import { useNavigate } from "react-router-dom"
import Circle1 from "../../assets/img/landing-page-register-circle-1.png"
import Circle2 from "../../assets/img/landing-page-register-circle-2.png"
import Circle3 from "../../assets/img/landing-page-register-circle-3.png"
import Circle4 from "../../assets/img/landing-page-register-circle-4.png"
import Game1 from "../../assets/img/Quiz_cardImg.jpeg"
import Game2 from "../../assets/img/Craft_cardImg.jpeg"
import Game3 from "../../assets/img/community-app-send.png"
import PlayGameBg from "../../assets/img/landing-page-playgame-bg.png"
import { formDataRequest } from "../../services/api-service"
import { useAppDispatch, useAppSelector } from "../../app/hooks"

import {
  setImage,
  setMessage,
  setUserDetails,
} from "../../models/user/userSlice"
import AtLogo from "../../assets/img/atlogo.png"
import ForgetPassword from "../../components/modal/forgotPassword"
import banner from "../../assets/img/hero-banner.jpeg"
import { Helmet } from "react-helmet"

interface IBanner {
  image: string
  link: string
  file_type: string
}

const LandingPage = () => {
  const navigate = useNavigate()

  const API_URL = import.meta.env.VITE_BASE_URL
  const user = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()
  const [membershipID, setMembershipID] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [forgetPasswordModal, setForgetPasswordModal] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [heroBannerData, setHeroBannerData] = useState<IBanner>({
    image: "",
    link: "",
    file_type: "",
  })

  const checkMobile = () => {
    let validity = /^[0-9+]{1,12}$/.test(membershipID)
    return validity
  }
  const handleLogin = async () => {
    if (!membershipID) {
      dispatch(setMessage({ body: "Mobile is Required", severity: "error" }))
      return
    }
    if (!checkMobile()) {
      dispatch(setMessage({ body: "Invalid Mobile Number", severity: "error" }))
      return
    }
    let formatNumber = membershipID.slice(-9)
    const payload = {
      membership_id: "+94" + formatNumber,
      password: password,
    }

    // const payload = {
    //   membership_id: "ATL - 132062",
    //   password: "Abc@1234",
    // }

    // console.log(payload);

    const response = await formDataRequest
      .post("/api/login", payload)
      .then((res) => res.data)
      .catch((e) => dispatch(setMessage(e.response.data.message)))

    if (response.token) {
      dispatch(
        setUserDetails({
          token: response.token,
          membership_id: response.user.membership_id,
          isLoggedIn: true,
        }),
      )
      if (parseInt(response.data.image, 10) > 0) {
        dispatch(setImage({ ...user, user_image: response.user.profile_image }))
      }
      dispatch(setMessage({ body: "Login Successful.", severity: "success" }))
    } else {
      dispatch(setMessage({ body: "Invalid Credentials.", severity: "error" }))
    }
  }

  const fetchData = async () => {
    const response = await formDataRequest.get("/api/getCampaigns")
    if (response) {
      setHeroBannerData(response.data.data)
    }
  }

  const imageStyle = {
    width: "400px",
    marginBottom: "20px", // Initial margin for non-mobile views
  }

  const mobileMediaQuery = "@media only screen and (max-width: 600px)"
  const mobileImageStyle = {
    ...imageStyle,
    [mobileMediaQuery]: {
      marginBottom: "10px", // Adjusted margin for mobile view
    },
  }

  const ShowPasswordFunction = () => {
    setShowPassword(!showPassword)
  }

  useEffect(() => {
    if (user.isLoggedIn) {
      navigate("/home")
    }
    fetchData()
  }, [user.isLoggedIn])

  const handleForgotPassword = () => {}
  return (
    <>
      <Helmet>
        <title>Atlas World</title>
      </Helmet>

      {forgetPasswordModal && (
        <ForgetPassword
          isOpen={forgetPasswordModal}
          setIsOpen={setForgetPasswordModal}
        />
      )}
      <Container
        sx={{
          ...containerStyles,
          padding: "0 !important",
          backgroundColor: "#FF5600",
        }}
      >
        <Box
          onClick={() => {
            if (heroBannerData?.link) {
              window.open(heroBannerData?.link, "_blank")
            }
          }}
        >
          {/* <video
            style={{ width: "100%" }}
            src={LandingNewVideo}
            autoPlay
            muted
            loop
          /> */}
          {heroBannerData?.file_type == "image" ? (
            <img
              src={`${API_URL}${heroBannerData?.image}`}
              alt="Logo"
              style={{ width: "100%", cursor: "pointer", maxHeight: "100vh" }}
            />
          ) : (
            <video
              style={{ width: "100%", cursor: "pointer" }}
              src={`${API_URL}${heroBannerData?.image}`}
              autoPlay
              muted
              loop
            />
          )}
        </Box>
      </Container>
      <div id="Login">
        <Container
          sx={{
            ...containerStyles,
            background: `url(${LoginBg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            px: { xs: "16px", sm: "90px" },
            py: "56px",
          }}
        >
          <Container
            sx={{
              maxWidth: "809px !important",
              background: "rgba(255,255,255,0.7)",
              borderRadius: "20px",
              px: { md: "128px !important" },
              py: "43px !important",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontSize: "40px !important",
                color: "#FF5600",
                fontWeight: 700,
                lineHeight: "46px",
                textAlign: "center",
              }}
            >
              Welcome to ATLAS World
            </Typography>
            <Typography
              sx={{
                color: "#000000",
                fontSize: "16px",
                lineHeight: "20.8px",
                textAlign: "center",
                mt: "19px",
              }}
            >
              We are a comprehensive online platform that aims to be your go to
              resource for all your educational needs. When you sign up, you
              unlock a world of possibilties.{" "}
            </Typography>
            <form style={{ width: "100%" }}>
              <FormControl
                sx={{ width: "100%", mb: "33px", mt: "40px", display: "flex" }}
              >
                {user.message.body ? (
                  <Alert severity={user.message.severity} sx={{ mb: "20px" }}>
                    {user.message.body}
                  </Alert>
                ) : (
                  ""
                )}
                <InputLabel
                  sx={{
                    position: "relative",
                    transform: "none",
                    color: "#000000",
                    fontSize: "20px",
                    mb: "3px",
                  }}
                >
                  Mobile Number
                </InputLabel>

                <TextInputField
                  placeholder="Enter your Mobile Number"
                  name="phone"
                  value={membershipID}
                  prefix="+94"
                  onChange={(e: any) => {
                    const inputValue = e.target.value.replace(/^\+94/, "")
                    if (
                      !/^[0-9]*$/.test(inputValue) ||
                      inputValue.length > 10
                    ) {
                      return
                    } else {
                      setMembershipID(inputValue)
                    }
                  }}
                  type="text"
                  keyPressFunction={handleLogin}
                />
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel
                  sx={{
                    position: "relative",
                    transform: "none",
                    color: "#000000",
                    fontSize: "20px",
                    mb: "3px",
                  }}
                >
                  Password
                </InputLabel>
                <TextInputField
                  placeholder="Enter your Password"
                  name="password"
                  onChange={(e: any) => setPassword(e.target.value)}
                  type="password"
                  keyPressFunction={handleLogin}
                  showPasswordFunction={ShowPasswordFunction}
                  showPassword={showPassword}
                  value={password}
                />
              </FormControl>
              <PrimaryButton
                sx={{ mt: "21px", height: "45px" }}
                onClick={handleLogin}
              >
                Login
              </PrimaryButton>
              <Typography textAlign="center" sx={{ my: "14px" }}>
                Or
              </Typography>
              <PrimaryButton
                sx={{ height: "45px" }}
                onClick={() => navigate("/register")}
              >
                Register
              </PrimaryButton>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  mt: "36px",
                }}
              >
                <Typography
                  sx={{
                    cursor: "pointer",
                    color: "#000000",
                    textDecoration: "underline",
                  }}
                >
                  <a
                    style={{}}
                    onClick={() => {
                      setForgetPasswordModal(true)
                    }}
                  >
                    Forgot Password ?
                  </a>
                </Typography>
              </Box>
            </form>
          </Container>
        </Container>
      </div>
      <Box
        sx={{
          backgroundImage: `url(${RegisterBg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          // height: { xs: "auto", md: "110vh" },
          width: "100%",
          paddingBottom: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <img style={{ width: "375px" }} src={AtLogo} />
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "45px", sm: "60px" },
                  lineHeight: "70.1px",
                  fontWeight: 586,
                  letterSpacing: "-0.065em",
                  color: "#ffffff",
                  display: "inline",
                  background: "#EC1E19",
                }}
              >
                Start Learning
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "45px", sm: "60px" },
                  lineHeight: "70.1px",
                  fontWeight: 586,
                  letterSpacing: "-0.105em",
                  color: "#ffffff",
                  display: "inline",
                  background: "#EC1E19",
                }}
              >
                with Free Classes !
              </Typography>
              <Button
                onClick={() => navigate("/register")}
                sx={{
                  background: "#ffffff",
                  color: "#FF6F08",
                  border: "5px solid #FF6F08",
                  borderRadius: "60px",
                  height: "62px",
                  width: "280px",
                  fontSize: "28px",
                  fontWeight: 700,
                  marginTop: "40px",
                  "&:hover": {
                    background: "#FF6F08",
                    color: "#ffffff",
                  },
                }}
              >
                REGISTER
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                maxWidth: { xs: "200px", md: "258px" },
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  border: "15px solid #DE2B0D",
                  width: "250px",
                  height: { xs: "250px", md: "250px" },
                  borderRadius: "50%",
                  overflow: "hidden",
                  background: `url(${Circle4})`,
                  backgroundPosition: "center",
                }}
              />
              <Typography
                sx={{
                  fontSize: "35px",
                  fontWeight: 700,
                  lineHeight: "37px",
                  letterSpacing: "0em",
                  textAlign: "center",
                  color: "#ffffff",
                }}
              >
                <a
                  href="#"
                  style={{ textDecoration: "none", color: "#ffffff" }}
                >
                  Grade 1 - 5
                </a>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              spacing={3}
              sx={{ height: { xs: "auto", md: "50vh" } }}
            >
              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <Box
                  sx={{
                    maxWidth: { xs: "200px", md: "258px" },
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      border: "15px solid #DE2B0D",
                      width: "250px",
                      height: { xs: "250px", md: "250px" },
                      borderRadius: "50%",
                      overflow: "hidden",
                      background: `url(${Circle1})`,
                      backgroundPosition: "center",
                    }}
                  ></Box>
                  <Typography
                    sx={{
                      fontSize: "35px",
                      fontWeight: 700,
                      lineHeight: "37px",
                      letterSpacing: "0em",
                      textAlign: "center",
                      color: "#ffffff",
                    }}
                  >
                    <a
                      href="#"
                      style={{ textDecoration: "none", color: "#ffffff" }}
                    >
                      Scholarships
                    </a>
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <Box
                  sx={{
                    maxWidth: { xs: "200px", md: "258px" },
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    position: { xs: "relative", md: "absolute" },
                  }}
                >
                  <Box
                    sx={{
                      border: "15px solid #DE2B0D",
                      width: "250px",
                      height: { xs: "250px", md: "250px" },
                      borderRadius: "50%",
                      overflow: "hidden",
                      background: `url(${Circle3})`,
                      backgroundPosition: "center",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "35px",
                      fontWeight: 700,
                      lineHeight: "37px",
                      letterSpacing: "0em",
                      textAlign: "center",
                      color: "#ffffff",
                    }}
                  >
                    <a
                      href="#"
                      style={{ textDecoration: "none", color: "#ffffff" }}
                    >
                      Grade 6 - 11
                    </a>
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <Box>
                  <Box
                    sx={{
                      border: "15px solid #DE2B0D",
                      width: "250px",
                      height: { xs: "250px", md: "250px" },
                      borderRadius: "50%",
                      overflow: "hidden",
                      background: `url(${Circle2})`,
                      backgroundPosition: "center",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "35px",
                      fontWeight: 700,
                      lineHeight: "37px",
                      letterSpacing: "0em",
                      textAlign: "center",
                      color: "#ffffff",
                    }}
                  >
                    <a
                      href="#"
                      style={{ textDecoration: "none", color: "#ffffff" }}
                    >
                      O Levels
                    </a>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {/* <Container
        sx={{
          ...containerStyles,
          background: `url(${RegisterBg})`,
          backgroundSize: "cover",
          height: { xs: "auto", md: "100vh" },
          backgroundRepeat: "no-repeat",
          px: { xs: "16px", sm: "90px" },
          pb: "56px",
        }}
      >
        <Box
            sx={{
              width: "300px",
              display: "flex",
              alignItems: "end",
              position: "relative",
              left: { sm: "37%", md: "30%", lg:"70%" },
            }}
          >
            <img
              style={{ width: "300px"}}
              src={AtLogo}
            />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: { xs: "center", xl: "flex-start" },
            pl: { xs: "0px !important", lg: "164px" },
            mt: {xs: "-60px", md: "-80px", lg: "-120px"}
          }}
        >
          <Box
            sx={{
              maxWidth: "586px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "42px",
            }}
          >
            <Box sx={{ textAlign: "center", pt: "85px" }}>
              <Typography
                sx={{
                  fontSize: "63px",
                  lineHeight: "70.1px",
                  fontWeight: 700,
                  px: "20px",
                  letterSpacing: "-0.065em",
                  color: "#ffffff",
                  display: "inline",
                  background: "#EC1E19",
                }}
              >
                Start Learning with Free Classes !
              </Typography>
            </Box>
            <Button
              onClick={() => navigate("/register")}
              sx={{
                background: "#ffffff",
                color: "#FF6F08",
                border: "5px solid #FF6F08",
                borderRadius: "60px",
                height: "62px",
                width: "280px",
                fontSize: "28px",
                fontWeight: 700,
                "&:hover": {
                  background: "#FF6F08",
                  color: "#ffffff",
                },
              }}
            >
              REGISTER
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            position: "relative",
            height: "68%",
            width:"90%",
            display: "flex",
            flexDirection: "row",
            mt: "50px",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "100px",
          }}
        >
          <Box
            sx={{
              maxWidth: { xs: "200px", md: "258px" },
              width: "100%",
              mb:"60px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: { xs: "relative", md: "absolute" },
              bottom: { xs: "unset", md: "100px" },
              left: { xs: "unset", md: "75px" },
            }}
          >
            <Box
              sx={{
                border: "15px solid #DE2B0D",
                width: "200px",
                height: { xs: "200px", md: "200px" },
                borderRadius: "50%",
                overflow: "hidden",
                background: `url(${Circle1})`,
                backgroundPosition: "center",
              }}
            ></Box>
            <Typography
              sx={{
                fontSize: "35px",
                fontWeight: 700,
                lineHeight: "37px",
                letterSpacing: "0em",
                textAlign: "center",
                color: "#ffffff",
              }}
            >
              <a href="#" style={{ textDecoration: "none", color: "#ffffff" }}>
                Scholarships
              </a>
            </Typography>
          </Box>
          <Box
            sx={{
              maxWidth: { xs: "200px", md: "258px" },
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: { xs: "relative", md: "absolute" },
              bottom: { xs: "unset", md: "180px" },
              left: { xs: "unset", md: "40%"},
            }}>
            <Box
              sx={{
                border: "15px solid #DE2B0D",
                width: "200px",
                height: { xs: "200px", md: "200px" },
                borderRadius: "50%",
                overflow: "hidden",
                background: `url(${Circle4})`,
                backgroundPosition: "center",
              }}
            />
            <Typography
              sx={{
                fontSize: "35px",
                fontWeight: 700,
                lineHeight: "37px",
                letterSpacing: "0em",
                textAlign: "center",
                color: "#ffffff",
              }}>
              <a href="#" style={{ textDecoration: "none", color: "#ffffff" }}>
                Grade 1 - 5
              </a>
            </Typography>
          </Box>
          <Box
            sx={{
              maxWidth: { xs: "200px", md: "258px" },
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: { xs: "relative", md: "absolute" },
              bottom: { xs: "unset", md: "140px" },
              left: { xs: "unset", md: "72%"},
            }}
          >
            <Box
              sx={{
                border: "15px solid #DE2B0D",
                width: "200px",
                height: { xs: "200px", md: "200px" },
                borderRadius: "50%",
                overflow: "hidden",
                background: `url(${Circle3})`,
                backgroundPosition: "center",
              }}
            />
            <Typography
              sx={{
                fontSize: "35px",
                fontWeight: 700,
                lineHeight: "37px",
                letterSpacing: "0em",
                textAlign: "center",
                color: "#ffffff",
              }}
            >
              <a href="#" style={{ textDecoration: "none", color: "#ffffff" }}>
                Grade 6 - 11
              </a>
            </Typography>
          </Box>
          <Box
            sx={{
              maxWidth: { xs: "200px", md: "258px" },
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: { xs: "relative", md: "absolute" },
              bottom: { xs: "unset", md: "620px",lg:"500px",xl:"600px" },
              left: { xs: "unset", md: "90%", lg:"80%",xl:"60%" },
            }}
          >
            <Box
              sx={{
                border: "15px solid #DE2B0D",
                width: "200px",
                height: { xs: "200px", md: "200px" },
                borderRadius: "50%",
                overflow: "hidden",
                background: `url(${Circle2})`,
                backgroundPosition: "center",
              }}
            />
            <Typography
              sx={{
                fontSize: "35px",
                fontWeight: 700,
                lineHeight: "37px",
                letterSpacing: "0em",
                textAlign: "center",
                color: "#ffffff",
              }}
            >
              <a href="#" style={{ textDecoration: "none", color: "#ffffff" }}>
                O Levels
              </a>
            </Typography>
          </Box>
        </Box>
      </Container> */}
      <Container
        sx={{
          ...containerStyles,
          px: { xs: "16px", sm: "90px" },
          pt: "118px",
          pb: "93px",
        }}
      >
        <Typography
          sx={{
            textTransform: "uppercase",
            fontSize: "40px",
            lineHeight: "46px",
            fontWeight: 700,
            color: "#FF5600",
            textAlign: "center",
            mb: "32px",
          }}
        >
          LEARN AND PLAY ONLINE
        </Typography>
        <Grid
          container
          // spacing={20}
          sx={{
            gap: "16px",
            mt: "76px",
            flexDirection: { xs: "column", lg: "row" },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item
            sx={{
              width: {
                xs: "250px",
                sm: "400px",
                md: "350px",
              },
              cursor: "pointer",
            }}
            onClick={() => {
              window.open(
                "https://play.google.com/store/apps/details?id=com.atlas.quizmaster",
                "_blank",
              )
            }}
          >
            <img
              src={Game1}
              alt=""
              style={{
                borderRadius: "10px",
                width: "inherit",
              }}
            />
          </Grid>
          <Grid
            item
            sx={{
              width: {
                xs: "250px",
                sm: "400px",
                md: "350px",
              },
              cursor: "pointer",
            }}
            onClick={() => {
              window.open(
                "https://play.google.com/store/apps/details?id=com.atlas.craftbuddies",
                "_blank",
              )
            }}
          >
            <img
              src={Game2}
              alt=""
              style={{
                borderRadius: "10px",
                width: "inherit",
              }}
            />
          </Grid>

          <Grid
            item
            sx={{
              width: {
                xs: "250px",
                sm: "400px",
                md: "350px",
              },
            }}
          >
            <img
              src={Game3}
              alt=""
              style={{
                borderRadius: "10px",
                width: "inherit",
              }}
            />
          </Grid>
        </Grid>
      </Container>
      {/* <Container
        sx={{
          ...containerStyles,
          px: { xs: "16px", sm: "90px" },
          pt: "118px",
          pb: "93px",
        }}
      >
        <Typography
          sx={{
            textTransform: "uppercase",
            fontSize: "40px",
            lineHeight: "46px",
            fontWeight: 700,
            color: "#FF5600",
            textAlign: "center",
            mb: "32px",
          }}
        >
          WHAT CAN ATLAS WORLD DO FOR YOU ?
        </Typography>
        <Typography
          sx={{
            fontSize: "25px",
            fontWeight: 400,
            lineHeight: "33px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "#000000",
            mb: "83px",
          }}
        >
          Beyond the classroom, we offer a plethora of materials to support
          self-paced learning, ensuring a well-rounded educational experience.
        </Typography>
        <Box
          sx={{
            maxWidth: "1008px",
            margin: "auto",
          }}
        >
          <video style={{ width: "100%" }} src={LandingHeroVideo} controls />
        </Box>
      </Container> */}
    </>
  )
}

export default LandingPage
