import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { formDataAuthRequest } from '../../../services/api-service'
import { Alert, Box, CircularProgress, Container, FormControl, Grid, IconButton, Modal, Typography } from '@mui/material'
import { TextInputField } from '../../fields'
import { PrimaryButton } from '../../button'
import { MuiOtpInput } from 'mui-one-time-password-input'
import { GridCloseIcon } from '@mui/x-data-grid'
import { resetUser } from '../../../models/user/userSlice'

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "250px", sm: "500px", md: "600px" },
    bgcolor: "#FFEFCE",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
  }

function ResetMobile({ isOpen, setIsOpen }) {
    const [userNumber, setUserNumber] = useState<string>("")
    const [OTPNumber, setOTPNumber] = useState("")
    const [modalState, setModalState] = useState<string | null>("enterNumber")
    const [alertMessage, setAlertMessage] = useState({
      body: null,
      severity: null,
    })
    const [resendTimer, setResendTimer] = useState(120)
    const [isResendDisabled, setIsResendDisabled] = useState(true)
    const [isLoading, setIsLoading] = useState(false)

    const user = useAppSelector((state) => state.user)
    const dispatch = useAppDispatch()

    const checkMobile = () => {
        let validity = /^[0-9+]{9,12}$/.test(userNumber)
        return validity
      }

    //   Handle new mobile no
      const handleMobileSubmit = async () => {
        if (!userNumber) {
          setAlertMessage({
            body: "Mobile is Required",
            severity: "error",
          })
          return
        }
        if (!checkMobile()) {
          setAlertMessage({ body: "Invalid Mobile Number", severity: "error" })
          return
        }
        let formatNumber = userNumber.slice(-9)
        const payload = {
          membership_id: user.membership_id,
          mobile:"+94" + formatNumber
        }
    
        try {
          setIsLoading(true)
          await formDataAuthRequest(user.token)
            .post("api/requestChangeMobileNumber", payload)
            .then((res) => {
              setIsLoading(false)
              setModalState("EnterOTP")
            })
        } catch (error) {
          setIsLoading(false)
          console.log(error)
          setAlertMessage({ body: error.response.data.error, severity: "error" })
        }
      }

    //  handle otp sumbit
    const handleOtpSubmit = async (getOTPNumber: any) => {
        if (getOTPNumber == null) {
          if (!OTPNumber || OTPNumber.length != 5) {
            setAlertMessage({ body: "OTP is Invalid", severity: "error" })
            setOTPNumber("")
            return
          }
        }
        try {
          setIsLoading(true)
          let formatNumber = userNumber.slice(-9)
          const payload = {
            membership_id: user.membership_id,
            mobile:"+94" + formatNumber,
            otp:getOTPNumber
          }
    
          await formDataAuthRequest(user.token)
            .post("api/verifyMobileNumber", payload)
            .then((res) => {
              setIsLoading(false)
              setModalState("SuccessPopup")
            })
        } catch (error) {
          console.log(error)
          setOTPNumber("")
          setIsLoading(false)
          setAlertMessage({ body: error.response.data.message, severity: "error" })
        }
      }
      
    //   resend otp 
    const handleResendSubmit = async () => {
        setResendTimer(120)
        setIsResendDisabled(true)
        let formatNumber = userNumber.slice(-9)
        const payload = {
            membership_id: user.membership_id,
            mobile:"+94" + formatNumber
          }
    
        try {
            await formDataAuthRequest(user.token)
            .post("api/requestChangeMobileNumber", payload)
            .then((res) => {
              setAlertMessage({ body: "OTP resent", severity: "success" })
            })
        } catch (error) {
          console.log(error)
          setAlertMessage({ body: error.response.data.message, severity: "error" })
        }
      }

      const formatTime = (seconds: any) => {
        const minutes = Math.floor(seconds / 60)
        const remainingSeconds = seconds % 60
        return `${minutes}:${
          remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
        }`
      }

      const modalViewer = () => {
        switch (modalState) {
          case "enterNumber":
            return (
              <>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "5px",
                  }}
                >
                  <Typography style={{ fontSize: "1.5em" }}>
                    {"Reset Mobile Number"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "5px",
                  }}
                >
                  <Typography style={{ fontSize: "1em" }}>
                    {"Enter your new phone number"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%", mb: "33px", display: "flex" }}>
                    <TextInputField
                      placeholder="Enter your Mobile Number"
                      name="phone"
                      value={userNumber}
                      prefix="+94"
                      onChange={(e: any) => {
                        const inputValue = e.target.value.replace(/^\+94/, "")
                        if (
                          !/^[0-9]*$/.test(inputValue) ||
                          inputValue.length > 10
                        ) {
                          return
                        } else {
                          setUserNumber(inputValue)
                        }
                      }}
                      keyPressFunction={() => {
                        handleMobileSubmit()
                      }}
                      type="text"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  {isLoading ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <PrimaryButton
                      onClick={() => {
                        handleMobileSubmit()
                      }}
                    >
                     Send Code
                    </PrimaryButton>
                  )}
                </Grid>
              </>
            )
    
          case "EnterOTP":
            return (
              <>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "5px",
                  }}
                >
                  <Typography style={{ fontSize: "1.5em" }}>
                    {"Mobile Number Verification"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "5px",
                  }}
                >
                  <Typography style={{ fontSize: "1em" }}>
                    {`Enter the 5 digit OTP code sent to the number ${
                      "+94" + userNumber.slice(-9)
                    }.`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <MuiOtpInput
                    value={OTPNumber}
                    onChange={(e) => {
                      setOTPNumber(e)
                    }}
                    length={5}
                    autoFocus
                    className="my-custom-class"
                    onComplete={(e) => {
                      handleOtpSubmit(e)
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: "15px" }}>
                  {isLoading ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <PrimaryButton
                      onClick={() => {
                        handleOtpSubmit(null)
                      }}
                    >
                      Verify
                    </PrimaryButton>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: "5px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography>
                    Didn't receive the code yet ?{" "}
                    {isResendDisabled ? (
                      <span>{formatTime(resendTimer)}</span>
                    ) : (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          color: "blue",
                        }}
                        onClick={() => {
                          handleResendSubmit()
                        }}
                      >
                        {`Resend Code`}
                      </span>
                    )}
                  </Typography>
                </Grid>
              </>
            )
          case "SuccessPopup":
            return (
              <>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "5px",
                  }}
                >
                  <Typography style={{ fontSize: "1.5em" }}>
                    {"Mobile Number successfully changed"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                    <PrimaryButton
                      onClick={async () => {
                      await dispatch(resetUser())
                        setIsOpen()
                      }}
                    >
                   Done
                    </PrimaryButton>
                 
                </Grid>
              </>
            )
          default:
            break
        }
      }


      useEffect(() => {
        if (alertMessage.body != null) {
          const interval = setTimeout(() => {
            setAlertMessage({ body: null, severity: null })
          }, 2000)
    
          return () => {
            clearTimeout(interval)
          }
        }
      }, [alertMessage])


      useEffect(() => {
        let countdownInterval: any
    
        if (resendTimer > 0 && isResendDisabled) {
          countdownInterval = setInterval(() => {
            setResendTimer((prevTimer) => prevTimer - 1)
          }, 1000)
        } else {
          setIsResendDisabled(false)
        }
    
        return () => {
          clearInterval(countdownInterval)
        }
      }, [resendTimer, isResendDisabled])
    
      useEffect(() => {
        if (modalState === "EnterOTP") {
          setIsResendDisabled(true)
          setResendTimer(120)
        } else {
          setResendTimer(0)
        }
      }, [modalState])

  return (
    <>
      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={2} direction="column">
            <Grid item xs={12}>
              <IconButton
                aria-label="close"
                onClick={() => {
                  setIsOpen(false)
                }}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "black",
                }}
              >
                <GridCloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Container
                sx={{ height: { xs: "120px", sm: "80px", md: "75px" } }}
              >
                {alertMessage.body ? (
                  <Alert
                    severity={alertMessage.severity}
                    sx={{ width: { xs: "200px", sm: "420px", md: "500px" } }}
                  >
                    <div
                      style={{
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {alertMessage.body}
                    </div>
                  </Alert>
                ) : (
                  ""
                )}
              </Container>
            </Grid>
            {modalViewer()}
          </Grid>
        </Box>
      </Modal>
    </>
  )
}

export default ResetMobile