
export const containerStyles = {
  height: "100vh", 
  maxWidth: "unset !important",
  width: "100%",
  display: "flex",
  justifyContent: {sm:"center", md: "space-evenly"},
  alignItems: "center",
  flexDirection: {sm:"column", md: "row"}
  // background: "rgb(240, 128, 128)",
  
}

export const loginstyles = {
  height: 400,
  width: "80%",
  background: "rgb(255,86,0)",
  borderRadius: '16px',
  gap:"10px"
  // maxHeight: { xs: 97, md: 97 },
}
export const innerloginstyles = {
  display: "flex",
  justifyContent: 'center',
  // background: "rgb(240, 128, 128)",
  padding: "20px",
  flexDirection: "column", 
  alignItems: "center", 
  marginTop: "30px", 
};
export const signinStyle={
  display: "flex",
  pr:"55%",
  color: "white",
}

export const credentialsStyles = {
  display: "flex", // Added to ensure vertical alignment
  flexDirection: "column",
  alignItems: "center",
  justifyContent: 'center',
  width:"90%",
  pt:"4%"
};

export const textinputStyles = {
  background: "rgb(255, 121, 54)",
  height:"20%"
};

export const FormControlStyles = {
  width: "100%", 
  mb: "10px", 
  mt: "20px",
  height:"10%",
};
export const InputLabelStyles = {
  
  position: "relative",
  transform: "none",
  color: "#000000",
  fontSize: "14px",
  mb: "2px",
};

export const PrimarybuttoStyle={
  background:"white !important",
  mt: "21px", height: "35px" ,
  color:"black",
  fontSize:"15px",
}

  
export const leftstyles={
  display:"flex",
  justifyContent: 'center',
}
export const rightstyles={
  display:"flex",
  justifyContent: 'center',
}


export const logostyles={
  
  
  justifyContent: 'center',
  maxHeight: { xs: 97, md: 180 },
 
}

export const forgetStyles={
  
  
  display:"flex",
  justifyContent:"end",
  flexDirection: "row", 
  width:"283px"
  
  

 
}
