import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit"
import userReducer from "../models/user/userSlice"
import adminReducer from "../models/admin/adminSlice"
import alertReducer from "../models/alert/alertSlice"
import messageModalReducer from "../models/messageModal/messageModalSlice"
import storage from "redux-persist/lib/storage"
import { persistReducer, persistStore } from "redux-persist"
import thunk from "redux-thunk"

const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: ["user"],
}

const userPersistConfig = {
  key: "user",
  storage,
  blacklist: ["message"],
}

const adminPersistConfig = {
  key: "admin",
  storage,
  blacklist: ["message"],
}

const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, userReducer),
  admin:persistReducer(adminPersistConfig, adminReducer),
  alert:alertReducer,
  messageModal:messageModalReducer
})

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
})

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
