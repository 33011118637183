import { useEffect, useState } from "react"
import { Box, CircularProgress, Divider, Grid, Typography } from "@mui/material"
// import { useDispatch } from "react-redux"
import SidePopup from "../../../../components/sidePopUp"
import CustomButton from "../../../../components/buttons"
import { AXIOSINSTANCE } from "../../../../services/api-service"
import EditProduct from "../editProduct"

const API_URL = import.meta.env.VITE_BASE_URL

interface ProductDetailProps {
  isPopupOpen: boolean
  handleClosePopup: any
  selectedProductID: number | undefined
}

export interface ProductData {
  id: number
  title: string
  image: string
  link: string
 
}

const ProductDetails = ({
  isPopupOpen,
  handleClosePopup,
  selectedProductID,
}: ProductDetailProps) => {
  // const dispatch = useDispatch()

  const [productDetail, setProductDetail] = useState<ProductData | undefined>(
    undefined
  )
 
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false)
  const [pageLoading, setPageLoading]  = useState<boolean>(true)

  // Fetch product details
  const fetchProductDetail = async () => {
    try {
      const response = await AXIOSINSTANCE().get(`/api/product/${selectedProductID}`)

      if (response.status == 200) {
        setProductDetail(response.data.data)
        setPageLoading(false)
      }
    } catch (error : any) {
      setPageLoading(false)
      console.log("Fetch trader error", error)
    } 
  }

  const handleOpenEditPopup = () => {
    setIsEditPopupOpen(true)
  }

  const handleEditClosePopup = () => {
    fetchProductDetail()
    setIsEditPopupOpen(false)
  }

  useEffect(() => {
    fetchProductDetail()
  }, [])

  return (
    <SidePopup
      onClose={handleClosePopup}
      title={"Product Detail"}
      open={isPopupOpen}
      isClose={true}>
      {pageLoading ? (
        // Show loader while loading
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}>
          <CircularProgress  />
        </Box>
      ) :
       (<Box
        sx={{
          flex: 1,
          overflow: "hidden",
          overflowY: "auto",
          paddingBottom: "80px",
          paddingLeft:"35px",
          paddingRight:"35px",
          paddingTop:"35px"
        }}>
      
      <Grid container  >
<Grid xs={6}  >
    <div style = {{ border:"1px solid #E6E6E6",borderRadius:"10px",width:"100%", overflow:"hidden"}}>
    <img
        src={`${API_URL}${productDetail.image}`}
        style={{ width: "100%"}}
    />
    </div>

</Grid>
<Grid xs={6} container direction="column" alignItems="flex-end" >
    <Grid item xs={2} >
    <CustomButton
                variant={"outlined"}
                buttonText={"Edit"}
                id={0}
                buttonFunction={() => {
                handleOpenEditPopup()
                }}
                type="secondary"
              />
    </Grid>

</Grid>
      </Grid>

      <Grid container sx={{border:"1px solid #E6E6E6",borderRadius:"10px"}} marginTop={"30px"}>
        <Grid container xs={12} sx={{padding:"20px"}}>
<Grid item xs={4} >
    <Typography sx={{fontSize:"14px", fontWeight:500, color:"#B9B9B9"}}>Product Name</Typography>
</Grid>
<Grid item xs={8}>
    <Typography sx={{fontSize:"16px", fontWeight:400, color:"#000000", textAlign:"right"}} >{productDetail.title}</Typography>
</Grid>
        </Grid>
<Divider sx={{width:'100%'}} />
<Grid container xs={12} sx={{padding:"20px"}}>
<Grid item xs={4}>
    <Typography sx={{fontSize:"14px", fontWeight:500, color:"#B9B9B9"}}>Product URL</Typography>
</Grid>
<Grid item xs={8}>
    <Typography sx={{fontSize:"16px", fontWeight:400, color:"#000000", textAlign:"right", overflowX:'hidden'}} >{productDetail.link}</Typography>
</Grid>
        </Grid>

      </Grid>

        {/* Edit popup */}
        {isEditPopupOpen && (
          <EditProduct
          handleClosePopup={handleEditClosePopup}
            isPopupOpen={isEditPopupOpen}
            selectedID={selectedProductID}
          />
        )}
      </Box>)}
    </SidePopup>
  )
}

export default ProductDetails
