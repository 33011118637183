import { Alert, Container, Snackbar } from "@mui/material"
import { useEffect, useState } from "react"
const MainContainer = (props: { children?: React.ReactNode }) => {
  return (
    <Container
      sx={{
        maxWidth: "unset !important",
        padding: "0 !important",
        overflow: "hidden",
        overflowY: "auto",
      }}
    >
      {props.children}
      
    </Container>
  )
}

export default MainContainer
