export const districts = [
  {
    value: "Ampara",
    label: "Ampara",
  },
  {
    value: "Anuradhapura",
    label: "Anuradhapura",
  },
  {
    value: "Badulla",
    label: "Badulla",
  },
  {
    value: "Batticaloa",
    label: "Batticaloa",
  },
  {
    value: "Colombo",
    label: "Colombo",
  },
  {
    value: "Galle",
    label: "Galle",
  },
  {
    value: "Gampaha",
    label: "Gampaha",
  },

  {
    value: "Hambantota",
    label: "Hambantota",
  },

  {
    value: "Jaffna",
    label: "Jaffna",
  },

  {
    value: "Kalutara",
    label: "Kalutara",
  },

  {
    value: "Kandy",
    label: "Kandy",
  },

  {
    value: "Kegalle",
    label: "Kegalle",
  },

  {
    value: "Kilinochchi",
    label: "Kilinochchi",
  },
  {
    value: "Kurunegala",
    label: "Kurunegala",
  },
  {
    value: "Mannar",
    label: "Mannar",
  },

  {
    value: "Matale",
    label: "Matale",
  },

  {
    value: "Matara",
    label: "Matara",
  },

  {
    value: "Moneragala",
    label: "Moneragala",
  },

  {
    value: "Mullaitivu",
    label: "Mullaitivu",
  },
  {
    value: "Nuwara Eliya",
    label: "Nuwara Eliya",
  },

  {
    value: "Polonnaruwa",
    label: "Polonnaruwa",
  },

  {
    value: "Puttalam",
    label: "Puttalam",
  },

  {
    value: "Ratnapura",
    label: "Ratnapura",
  },
  {
    value: "Trincomalee",
    label: "Trincomalee",
  },

  {
    value: "Vavuniya",
    label: "Vavuniya",
  },
]
