import { ThemeProvider } from "@mui/material"
import "./App.css"
import Footer from "./components/footer"
import Header from "./components/header"
import MainLayout from "./layouts/main"
import { Outlet, useLocation } from "react-router-dom"
import { theme } from "./theme"




function App() {
    const location = useLocation();
    const isAdminPage = () => {
      return location.pathname.includes('/admin');
    }
  
    return (
      <ThemeProvider theme={theme}>
        {!isAdminPage() && <Header />}
        <MainLayout mainElements={<Outlet />} />
        {!isAdminPage() && <Footer />}
      </ThemeProvider>
    )
  }

export default App
