import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { addMessageModal } from "../../../models/messageModal/messageModalSlice"

const SingleListItem = ({
  id,
  icon,
  Text,
  path,
  action,
}: {
  id: number
  icon: any
  Text: string
  path?: any
  action?: string
}) => {
    const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const listButtonAction = () => {
    if (action === "navigate") {
      navigate(path)
    }
    else {
      dispatch(
        addMessageModal({
          modalState: true,
          modalComponentName: "Logout",
        }),
      )
    }
  }

  return (
    <>
      <ListItemButton
        sx={{
          borderRadius: "10px",
          // "&:hover": {
          //     backgroundColor: "red",
          //   },
          "&.Mui-selected": {
            backgroundColor: "#FF7936",
          },
          "&.Mui-selected:hover": {
            backgroundColor: "#FF7936",
          },
        }}
        key={id}
        onClick={() => {
          listButtonAction()
        }}
        selected={pathname === path ? true : false}
      >
        <ListItemIcon sx={{ fontSize: "16px", color: "#ffffff" }}>
          {icon}
        </ListItemIcon>
        <ListItemText
          sx={{
            "&.MuiListItemText-root .MuiTypography-root": {
              fontSize: "16px",
              fontWeight: 500,
            },
          }}
          primary={Text}
        />
      </ListItemButton>
    </>
  )
}

export default SingleListItem
