import {
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  TextFieldProps,
} from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { useState } from "react"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import dayjs, { Dayjs } from "dayjs"

interface TextInputField {
  placeholder: string
  value?: string
  required?: boolean
  sx?: any
  onChange: any
  keyPressFunction?: any
  type: string
  name: string
  showPassword?: boolean
  showPasswordFunction?: any
  prefix?: string
}

const validatePassword = (password) => {
  const minLength = 8;
  const hasNumber = /\d/;
  const hasUppercase = /[A-Z]/;
  const hasLowercase = /[a-z]/;
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
  return (
    password.length >= minLength &&
    hasNumber.test(password) &&
    hasUppercase.test(password) &&
    hasLowercase.test(password) &&
    hasSpecialChar.test(password)
  );
};

export const TextInputField = (props: TextInputField) => {
  const [isPasswordValid, setIsPasswordValid] = useState(true);

  const handleKeyPress = (e: any) => {
    if (props.keyPressFunction && e.key === 'Enter') {
      props.keyPressFunction();
    }
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    props.onChange(e);
    if (props.type === 'password') {
      setIsPasswordValid(validatePassword(value));
    }
  };

  return (
    <TextField
      name={props.name}
      type={
        props.type == "password"
          ? props.showPassword
            ? "text"
            : "password"
          : props.type
      }
      placeholder={props.placeholder}
      required={props.required}
      onChange={handleChange}
      onKeyUp={handleKeyPress}
      value={props.value}
      InputProps={{
        startAdornment: props.prefix ? (
          <InputAdornment position="start">{props.prefix}</InputAdornment>
        ) : null,
        endAdornment:
          props.type == "password" ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={props.showPasswordFunction}
                edge="end"
              >
                {props.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ) : null,
      }}
      sx={{
        height: "62px",
        width: "100%",
        borderRadius: "10px",
        background: "#ffffff",
        backgroundColor: props.type === "password" ? "#fff" : "#ECECEC",
        border: props.type === "password"  && props.value ? 2 : null,
        borderColor: props.type === "password" ? (!isPasswordValid ? "red" : "green") : null,
        fontSize: "20px",
        "& .MuiInputBase-root": {
          height: "100%",
        },
        "& fieldset": { border: "none" },
        "& input": {
          color: "#686868 !important",
          backgroundColor: "white",
          fontSize: "20px",
          borderRadius: props.prefix ? "0px 9px 9px 0px" : "9px 9px 9px 9px",
          marginLeft: props.prefix ? "14px" : "0px",
          px: "26px",
          "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
          },
          "&[type=number]": {
            MozAppearance: "textfield",
          },
        },
        "& #placeholder": {
          opacity: 1,
        },
        ...props.sx,
      }}
    />
  )
}

interface FormInputTextField
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  required?: boolean
  rounded?: boolean
  placeholder: string
  name: string
  onChange?: any
  value?: string
  disabled?: boolean
  focused?: boolean
  sx?: any
}

export const FormInputTextField = (props: FormInputTextField) => {
  return (
    <TextField
      type={props.type}
      label={props.label}
      variant="outlined"
      required={props.required}
      placeholder={props.placeholder}
      name={props.name}
      onChange={props.onChange}
      disabled={props.disabled}
      focused={props.focused}
      value={props.value}
      InputProps={{
        startAdornment: props.prefix ? (
          <InputAdornment position="start">{props.prefix}</InputAdornment>
        ) : null,
      }}
      sx={{
        height: "76px",
        width: "100%",
        "& .MuiInputBase-root": {
          borderRadius: props.rounded ? "10px" : "0px",
          background: "#ffffff",
        },
        "& input": {
          px: "20px",
          py: "14px",
          fontSize: "20px",
          color: "#686868",
        },
        "& .MuiFormLabel-root": {
          fontSize: "20px",
          color: "#848884 !important",
        },
        "& .MuiFormLabel-asterisk": {
          color: "#FF6F08",
          fontSize: "24px",
        },
        "& fieldset": {
          borderWidth: "0.5px",
          borderColor: "#000000",
        },
        "& .MuiOutlinedInput-root.Mui-focused fieldset": {
          borderColor: "#FFA561 !important",
        },
        "& .MuiOutlinedInput-root.Mui-focused legend": {
          pr: "20px",
          color: "#000000",
        },
      }}
    />
  )
}

interface FormSelect {
  handleChange: any
  value?: any
  name: string
  label: string
  options: { value: string | number; label: string }[]
  placeholder: string
  required: boolean
  sx?: any
  rounded?: boolean
  multiple?: boolean
}

export const FormSelect = (props: FormSelect) => {
  const [selected, setSelected] = useState<string | number>("")

  return (
    <FormControl
      required={props.required}
      fullWidth
      sx={{
        height: "57px",
        width: "100%",
        paddingBottom: "20px",
        "& .MuiInputBase-root": {
          borderRadius: props.rounded ? "10px" : "0px",
          background: "#ffffff",
        },
        "& input": {
          px: "20px",
          py: "14px",
          fontSize: "20px",
          color: "#686868",
        },
        "& .MuiFormLabel-root": {
          fontSize: "20px",
          color: "#848884 !important",
        },
        "& .MuiFormLabel-asterisk": {
          color: "#FF6F08",
          fontSize: "24px",
        },
        "& fieldset": {
          borderWidth: "0.5px",
          borderColor: "#000000",
        },
        "& .MuiOutlinedInput-root.Mui-focused fieldset": {
          borderColor: "#FFA561 !important",
        },
        "& .MuiOutlinedInput-root.Mui-focused legend": {
          pr: "20px",
          color: "#000000",
        },
        ...props.sx,
      }}
    >
      <InputLabel id={props.label}>{props.label}</InputLabel>
      <Select
        multiple={props.multiple}
        labelId={props.label}
        name={props.name}
        autoFocus
        value={props.value}
        label={props.label}
        onChange={props.handleChange}
        sx={{
          height: "100%",
        }}
      >
        {props.options.map((option) => (
          <MenuItem
            onClick={() => setSelected(option.value)}
            value={option.value}
            key={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

interface FormSelectMultiple {
  handleChange: any
  value?: any
  name: string
  label: string
  options: { value: string | number; label: string }[]
  placeholder: string
  required: boolean
  sx?: any
  rounded?: boolean
  multiple?: boolean
}

export const FormSelectMultiple = (props: FormSelectMultiple) => {
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  }
  const [selected, setSelected] = useState<string | number>("")
  return (
    <FormControl
      required={props.required}
      fullWidth
      sx={{
        height: "57px",
        width: "100%",
        paddingBottom: "20px",
        "& .MuiInputBase-root": {
          borderRadius: props.rounded ? "10px" : "0px",
          background: "#ffffff",
        },
        "& input": {
          px: "20px",
          py: "14px",
          fontSize: "20px",
          color: "#686868",
        },
        "& .MuiFormLabel-root": {
          fontSize: "20px",
          color: "#848884 !important",
        },
        "& .MuiFormLabel-asterisk": {
          color: "#FF6F08",
          fontSize: "24px",
        },
        "& fieldset": {
          borderWidth: "0.5px",
          borderColor: "#000000",
        },
        "& .MuiOutlinedInput-root.Mui-focused fieldset": {
          borderColor: "#FFA561 !important",
        },
        "& .MuiOutlinedInput-root.Mui-focused legend": {
          pr: "20px",
          color: "#000000",
        },
        ...props.sx,
      }}
    >
      <InputLabel id={props.label}>{props.label}</InputLabel>
      <Select
        labelId={props.label}
        name={props.name}
        multiple
        autoFocus
        value={props.value}
        onChange={props.handleChange}
        input={<OutlinedInput label="Tag" />}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
      >
        {props.options.map((option) => (
          <MenuItem key={option?.value} value={option?.value}>
            <Checkbox checked={props.value.indexOf(option?.value) > -1} />
            <ListItemText primary={option.value} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

interface FormDatePicker {
  label: string
  rounded: boolean
  onChange?: any
  value?: string
  sx?: any
}

export const FormDatePicker = (props: FormDatePicker) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={dayjs(props.value)}
        onChange={props.onChange}
        sx={{
          height: "76px",
          width: "100%",
          "& .MuiInputBase-root": {
            borderRadius: props.rounded ? "10px" : "0px",
          },
          "& input": {
            px: "20px",
            py: "14px",
            fontSize: "20px",
            color: "#686868",
            backgroundColor: "#fff",
          },
          "& .MuiFormLabel-root": {
            fontSize: "20px",
            color: "#848884",
          },
          "& .MuiFormLabel-asterisk": {
            color: "#FF6F08",
            fontSize: "24px",
          },
          "& fieldset": {
            borderWidth: "0.5px",
            borderColor: "#000000",
          },
          "& .MuiOutlinedInput-root.Mui-focused fieldset": {
            borderColor: "#FFA561 !important",
          },
          "& .MuiOutlinedInput-root.Mui-focused legend": {
            pr: "20px",
            color: "#000000",
          },
          ...props.sx,
        }}
        slotProps={{
          textField: {
            required: true,
          },
        }}
        label={props.label}
        disableFuture
      />
    </LocalizationProvider>
  )
}

interface CheckBox {
  onChange?: any
  checked?: boolean
  label: string
  required?: boolean
  sx?: any
}

export const CheckBox = (props: CheckBox) => {
  return (
    <FormControlLabel
      sx={{
        "& .MuiFormControlLabel-label": {
          fontWeight: 400,
        },
        "& .MuiFormControlLabel-asterisk": {
          color: "#FF6F08",
        },
      }}
      required={props.required}
      label={props.label}
      control={
        <Checkbox
          checked={props.checked}
          onChange={props.onChange}
          color="primary"
          sx={{
            color: "black",
            "&.Mui-checked": {
              color: "black",
            },
            ...props.sx,
          }}
        />
      }
    />
  )
}
