import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material"
import { districts } from "../../pages/register/districts"
import { SubHeading, TwoColFields } from "./styles"
import {
  FormDatePicker,
  FormInputTextField,
  FormSelect,
  FormSelectMultiple,
} from "../fields"
import { useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import * as Styles from "./styles"
import { MdDelete, MdOutlineClose } from "react-icons/md"
import { PrimaryButton } from "../button"
import { formDataAuthRequest } from "../../services/api-service"
import {
  setImage,
  setMessage,
  setUserDetails,
} from "../../models/user/userSlice"
import dayjs from "dayjs"
import AvatarService from "../../services/image-service"

const EditProfileModal = (props: { isOpen: any; type: string }) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.user)
  const imageService = new AvatarService()

  const [childrenData, setChildrenData] = useState<any[]>(
    user.userDetails.children_details,
  )
  const [image, setUserImage] = useState<number>(
    parseInt(imageService.getAvatar(user.userDetails.image), 10),
  )
  const [firstName, setFirstName] = useState(user.userDetails.first_name)
  const [lastName, setLastName] = useState(user.userDetails.last_name)
  const [email, setEmail] = useState(user.userDetails.email)
  const [mobile, setMobile] = useState(user.userDetails.mobile)
  const [district, setDistrict] = useState(user.userDetails.district)



  const checkEmail = () => {
    let validity = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
    return validity
  }
  const checkFirstname = () => {
    let validity = /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/.test(firstName)
    return validity
  }
  const checkLastname = () => {
    let validity = /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/.test(lastName)
    return validity
  }
  const checkMobile = () => {
    let validity = /^\d{9}$/.test(mobile)
    return validity
  }
  const checkChildName = (name) => {
    let validity = /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/.test(name)
    return validity
  }

  const handleSubmit = async () => {
    if (props.type == "image") {
      const payload = {
        membership_id: user.membership_id,
        profile_image: image,
      }
      try {
        const response = await formDataAuthRequest(user.token).post(
          "/api/updateProfileImage",
          payload,
        )
        if (await response.data) {
          dispatch(setImage({ ...user, user_image: image }))
          await dispatch(
            setMessage({
              body: "Image Updated Successfully",
              severity: "success",
            }),
          )
          props.isOpen(false)
        }
      } catch (e: any) {
        await dispatch(
          setMessage({ body: e.response.data.message, severity: "error" }),
        )
      }
    } else {
      validateForm()
    }
  }

  const alertRef = useRef(null)

  useEffect(() => {
    if (user.message.body) {
      alertRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [user.message.body])

  const validateForm = async () => {
    if (!firstName) {
      dispatch(
        setMessage({ body: "First Name is Required", severity: "error" }),
      )
      return
    }

    if (!checkFirstname()) {
      dispatch(setMessage({ body: "Invalid First Name", severity: "error" }))
      return
    }

    if (!lastName) {
      dispatch(setMessage({ body: "Last Name is Required", severity: "error" }))
      return
    }

    if (!checkLastname()) {
      dispatch(setMessage({ body: "Invalid Last Name", severity: "error" }))
      return
    }

    if (!email) {
      dispatch(setMessage({ body: "Email is Required", severity: "error" }))
      return
    }

    if (!checkEmail()) {
      dispatch(setMessage({ body: "Invalid Email", severity: "error" }))
      return
    }

    // if (!mobile) {
    //   dispatch(setMessage({ body: "Mobile is Required", severity: "error" }))
    //   return
    // }

    // if(!checkMobile()){
    //   dispatch(setMessage({ body: "Invalid Mobile Number", severity: "error" }))
    //   return
    // }

    if (!district) {
      dispatch(setMessage({ body: "District is Required", severity: "error" }))
      return
    }
    let isChildrenDataEmpty = childrenData.length === 0
    let isInvalidChildName = false

    for (let i = 0; i < childrenData.length; i++) {
      const child = childrenData[i]
      if (
        !child.Child_Name ||
        child.Child_Name == '' ||
        !child.DOB ||
        !child.Grade ||
        !child.Subjects ||
        child.Subjects.length === 0
      ) {
        isChildrenDataEmpty = true
        break
      }
      if (!checkChildName(child.Child_Name)) {
        isInvalidChildName = true
        break
      }
    }

    if (isInvalidChildName) {
      dispatch(
        setMessage({
          body: "Invalid Child Name",
          severity: "error",
        }),
      )
      return
    }

    if (isChildrenDataEmpty) {
      dispatch(
        setMessage({
          body: "Children Details are Required",
          severity: "error",
        }),
      )
      return
    }

    const payload = {
      membership_id: user.membership_id,
      first_name: firstName,
      last_name: lastName,
      email: email,
      // mobile: mobile,
      district: district,
      children_details: childrenData,
    }

    try {
      const response = await formDataAuthRequest(user.token).post(
        "/api/updateZohoRecord",
        payload,
      )

      if ((await response.data.data[0].code) === "SUCCESS") {
        const updatedData = {
          first_name: firstName,
          last_name: lastName,
          mobile: user.userDetails.mobile,
          email: email,
          district: district,
          children_details: childrenData,
          record_source: user.userDetails.record_source,
        }
        
        dispatch(setUserDetails({ ...user , userDetails: updatedData }))
        await dispatch(
          setMessage({
            body: "User Updated Successfully",
            severity: "success",
          }),
        )
        // setTimeout(() => {
        // props.isOpen(false)
        // }, 2000)
      }
    } catch (e: any) {
      await dispatch(
        setMessage({ body: e.response.data.message, severity: "error" }),
      )
    }
  }

  const addField = () => {
    setChildrenData((prevData) => {
      const newData = [
        ...prevData,
        {
          Child_Name: "",
          DOB: "",
          Grade: "",
          Subjects: [],
        },
      ]
      return newData
    })
  }

  const removeField = (index: number) => {
    if (index !== 0) {
      const newFields = [...childrenData]
      newFields.splice(index, 1)
      setChildrenData(newFields)
    }
  }

  const handleChange = (index: number, field_name: any, value: any) => {
    let copyData = [...childrenData]
    let newData = {
      Child_Name: copyData[index].Child_Name,
      DOB: copyData[index].DOB,
      Grade: copyData[index].Grade,
      Subjects: copyData[index].Subjects,
    }
    switch (field_name) {
      case "Child_Name":
        newData.Child_Name = value
        break
      case "DOB":
        newData.DOB = value
        break
      case "Grade":
        newData.Grade = value
        break
      case "SubjectsSingle":
        newData.Subjects = value
        break
      case "SubjectsMultiple":
        newData.Subjects = value
        break
      default:
        break
    }
    copyData[index] = newData
    setChildrenData(copyData)
  }
  const getUserData = async () => {
    const response = await formDataAuthRequest(user.token).post(
      "/api/getZohoRecord",
      { membership_id: user.membership_id },
    )

    if (response) {
      const childrenDetails = []

      response.data.record.Children_Details.map((child) => {
        const data = {
          Child_Name: child.Child_Name,
          DOB: child.DOB,
          Grade: child.Grade,
          Subjects: child.Subjects,
        }
        childrenDetails.push(data)
      })

      const data = {
        first_name: response.data.record.First_Name,
        last_name: response.data.record.Last_Name,
        record_source: response.data.record.Name,
        mobile: response.data.record.Mobile,
        email: response.data.record.Email,
        district: response.data.record.District,
        children_details: childrenDetails,
        student_id : response.data.record.Student_ID,
      }

      dispatch(setUserDetails({ ...user, userDetails: data }))
      if (parseInt(response.data.image, 10) > 0) {
        dispatch(setImage({ ...user, user_image: response.data.image }))
      }
    }
  }
  const closeModel = () => {
    props.isOpen(false)
    getUserData();
  }
  return (
    // <Box sx={{display:"flex",flexDirection:"column", height}}></Box>
    <Container sx={Styles.ContainerStyles}>
      <Container sx={Styles.InnerContainerStyles}>
        <Box
          sx={{
            position: "absolute",
            cursor: "pointer",
            right: "18px",
            top: "18px",
            "& svg": {
              width: "32px",
              height: "32px",
            },
          }}
        >
          <MdOutlineClose onClick={() => closeModel()} />
        </Box>
          <>
            <Typography variant="h2" sx={SubHeading}>
              User Details
            </Typography>
            <Box sx={TwoColFields}>
              <FormInputTextField
                rounded
                placeholder="Enter your First Name"
                label="First Name"
                focused
                onChange={(e: any) => setFirstName(e.target.value)}
                value={firstName}
                type="text"
                required
                name="first_name"
              />
              <FormInputTextField
                rounded={true}
                placeholder="Enter your Last Name"
                label="Last Name"
                onChange={(e: any) => setLastName(e.target.value)}
                value={lastName}
                required
                focused
                name="last_name"
              />
            </Box>
            <Box sx={TwoColFields}>
              <FormInputTextField
                rounded
                placeholder="Enter your Email Address"
                label="Email Address"
                value={email}
                focused
                onChange={(e: any) => setEmail(e.target.value)}
                type="email"
                required
                name="email_address"
              />
              {/* <FormInputTextField
                rounded
                placeholder="Enter your Mobile Number"
                label="Mobile Number"
                required
                value={mobile}
                onChange={(e: any) => setMobile(e.target.value)}
                focused
                name="mobile_number"
              /> */}
              {/* </Box>
        <Box sx={TwoColFields}> */}
              <FormSelect
                sx={{
                  width: { xs: "100%", lg: "50%" },
                }}
                rounded
                handleChange={(e: any) => setDistrict(e.target.value)}
                placeholder="District"
                label="District"
                value={district}
                required
                name="email_address"
                options={districts}
              />
            </Box>
            <Divider sx={{ my: "62px" }} />
            <Typography variant="h2" sx={SubHeading}>
              Details of Children
            </Typography>
            <Box>
              {childrenData?.map((field, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      ...TwoColFields,
                      marginBottom: { xs: "60px", lg: "30px" },
                      alignItems: { xs: "flex-start", lg: "center" },
                    }}
                  >
                    <FormInputTextField
                      rounded
                      placeholder="Sarah Smith"
                      label="Child’s Name"
                      required
                      value={field.Child_Name}
                      onChange={(e: any) =>
                        handleChange(index, "Child_Name", e.target.value)
                      }
                      name="child_name"
                    />
                    <FormDatePicker
                      rounded
                      label="Date of Birth"
                      value={field.DOB}
                      onChange={(e: any) =>{
                        handleChange(index, "DOB", dayjs(e).format('YYYY-MM-DD'))
                      }
                      }
                    />
                    <FormSelect
                      sx={{
                        width: { xs: "100%", lg: "50%" },
                      }}
                      rounded
                      placeholder="Grade"
                      label="Grade"
                      required
                      value={field.Grade}
                      handleChange={(e: any) =>
                        handleChange(index, "Grade", e.target.value)
                      }
                      name="grade"
                      options={[
                        { value: "Pre-School", label: "Pre-School" },
                        { value: "Grade 01", label: "Grade 01" },
                        { value: "Grade 02", label: "Grade 02" },
                        { value: "Grade 03", label: "Grade 03" },
                        { value: "Grade 04", label: "Grade 04" },
                        { value: "Grade 05", label: "Grade 05" },
                        { value: "Grade 06", label: "Grade 06" },
                        { value: "Grade 07", label: "Grade 07" },
                        { value: "Grade 08", label: "Grade 08" },
                        { value: "Grade 09", label: "Grade 09" },
                        { value: "Grade 10", label: "Grade 10" },
                        { value: "Grade 11", label: "Grade 11" },
                      ]}
                    />
                    {/* <FormSelect
                      sx={{
                        width: { xs: "100%", lg: "50%" },
                      }}
                      rounded
                      placeholder="Subject"
                      label="Subject"
                      required
                      value={field.Subjects}
                      handleChange={(e: any) =>
                        handleChange(index, "SubjectsSingle", [e.target.value])
                      }
                      name="subject"
                      options={[
                        {value: "Scholarship Seminars",label: "Scholarship Seminars",},
                        { value: "Math Classes", label: "Math Classes" },
                        { value: "Science Classes", label: "Science Classes" },
                        { value: "English Classes", label: "English Classes" },
                        { value: "ICT Classes", label: "ICT Classes" },
                        { value: "Art Classes", label: "Art Classes" },
                      ]}
                    /> */}
                    <FormSelectMultiple
                      sx={{
                        width: { xs: "100%", lg: "50%" },
                      }}
                      handleChange={(e: any) => {
                        console.log(e.target.value)
                        handleChange(index, "SubjectsMultiple", e.target.value)
                      }}
                      name={"subject"}
                      label={"Subject"}
                      rounded
                      value={field.Subjects}
                      options={[
                        {
                          value: "Scholarship Seminars",
                          label: "Scholarship Seminars",
                        },
                        { value: "Math Classes", label: "Math Classes" },
                        { value: "Science Classes", label: "Science Classes" },
                        { value: "English Classes", label: "English Classes" },
                        { value: "ICT Classes", label: "ICT Classes" },
                        { value: "Art Classes", label: "Art Classes" },
                      ]}
                      placeholder={"Subject"}
                      required
                    />

                    {index != 0 ? (
                      <Box
                        sx={{
                          ...Styles.ButtonStyles,
                          width: "25px",
                        }}
                      >
                        <MdDelete onClick={() => removeField(index)} />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          height: "25px",
                          borderRadius: "50%",
                          fontSize: "25px",
                          width: "25px"
                        }}
                      >
                        <div style={{ width: "25px" }}></div>
                      </Box>
                    )}
                  </Box>
                )
              })}
            </Box>
            <Box
              sx={{
                ...Styles.ButtonStyles,
              }}
            >
              <Button
                sx={{
                  color: "black",
                  textDecoration: "underline",
                  fontWeight: 700,
                }}
                onClick={addField}
              >
                + Add a child
              </Button>
            </Box>
          </>
        {user.message.body ? (
          <Alert severity={user.message.severity} ref={alertRef}>
            {user.message.body}
          </Alert>
        ) : (
          ""
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            mt: "35px",
            gap: "64px",
          }}
        >
          <PrimaryButton
            sx={{ backgroundColor: "#686868" }}
            onClick={() => closeModel()}
          >
            Cancel
          </PrimaryButton>
          <PrimaryButton onClick={() => handleSubmit()}>
            Save Changes
          </PrimaryButton>
        </Box>
      </Container>
    </Container>
  )
}

export default EditProfileModal
