import { createTheme } from "@mui/material"

export const theme = createTheme({
  typography: {
    fontFamily: `"Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
})
