import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material"
import { GridCloseIcon } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react"
import { TextInputField } from "../../fields"
import { useAppDispatch } from "../../../app/hooks"
import { PrimaryButton } from "../../button"
import {
  formDataAuthRequest,
} from "../../../services/api-service"
import "./styles.css"
import { setMessage } from "../../../models/user/userSlice"
import OtpVerification from "../otpVerification"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "250px", sm: "500px", md: "600px" },
  bgcolor: "#FFEFCE",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
}

const ForgetPassword = ({ isOpen, setIsOpen }) => {
  const [OTPToken, setOTPToken] = useState<string | null>(null)
  const [alertMessage, setAlertMessage] = useState({
    body: null,
    severity: null,
  })
  const [isModalOpen, setIsModalOpen] = useState(true)
  const [newPassword, setNewPassword] = useState<string | null>(null)
  const [confirmPassword, setConfirmPassword] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const dispatch = useAppDispatch()

  const checkPassword = () => {
    let validity = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(newPassword)
    return validity
  }


  const handlePasswordSubmit = async () => {
    if (!newPassword) {
      setAlertMessage({ body: "You Have to type password", severity: "error" })
      return
    }
    if (!confirmPassword) {
      setAlertMessage({
        body: "You Have to type Confirm Password",
        severity: "error",
      })
      return
    }
    if (newPassword != confirmPassword) {
      setAlertMessage({ body: "Password doesn't Match", severity: "error" })
      return
    }
    if (!checkPassword()) {
      setAlertMessage({
        body: "password must contain a minimum of 8 characters, have a uppercase and lowercase letters, a number and a special character. Spaces are not permitted",
        severity: "error",
      })
      return
    }

    const payload = {
      password: newPassword,
    }

    try {
      setIsLoading(true)
      await formDataAuthRequest(OTPToken)
        .post("api/reset-password", payload)
        .then((res) => {
          console.log(res)
          setIsLoading(false)
          dispatch(
            setMessage({
              body: "Password reset successfully",
              severity: "success",
            }),
          )
          setIsOpen(false)
        })
    } catch (error) {
      console.log(error)
      setOTPToken(error.response.data.token)
      setIsLoading(false)
      setAlertMessage({
        body: error.response.data.error || error.response.data.message,
        severity: "error",
      })
    }
  }

  useEffect(() => {
    if (alertMessage.body != null) {
      const interval = setTimeout(() => {
        setAlertMessage({ body: null, severity: null })
      }, 2000)

      return () => {
        clearTimeout(interval)
      }
    }
  }, [alertMessage])
  
  return (
    <>
      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={2} direction="column">
            <Grid item xs={12}>
              <IconButton
                aria-label="close"
                onClick={() => {
                  setIsOpen(false)
                }}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "black",
                }}
              >
                <GridCloseIcon />
              </IconButton>
            </Grid>
            {alertMessage.body && (
              <Grid item xs={12}>
                <Container>
                  <Alert
                    severity={alertMessage.severity}
                    sx={{ width: { xs: "180px", sm: "220px", md: "350px" } }}
                  >
                    <div
                      style={{
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {alertMessage.body}
                    </div>
                  </Alert>
                </Container>
              </Grid>
            )}
            {isModalOpen ? (
              <OtpVerification
                mobileAPI="api/forget-password/request"
                otpAPI="api/forget-password/verify-otp"
                handleClosePopup={() => {
                  setIsModalOpen(false)
                }}
                isForgotPassword={true}
                setAlertMessage={(value: any) => {
                  setAlertMessage(value)
                }}
                setOtpToken={(value: string) => {
                  setOTPToken(value)
                }}
              />
            ) : (
              <>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "5px",
                  }}
                >
                  <Typography style={{ fontSize: "1.5em" }}>
                    "Reset Password
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "5px",
                  }}
                >
                  <Typography style={{ fontSize: "1em" }}>
                    {
                      "Your password must contain a minimum of 8 characters, have a uppercase and lowercase letters, a number and a special character. Spaces are not permitted."
                    }
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextInputField
                    placeholder="Enter New Password"
                    name="password"
                    onChange={(e: any) => {
                      setNewPassword(e.target.value)
                    }}
                    type="password"
                    showPasswordFunction={()=>setShowPassword(!showPassword)}
                    showPassword={showPassword}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInputField
                    placeholder="Confirm New Password"
                    name="confirmPassword"
                    onChange={(e: any) => {
                      setConfirmPassword(e.target.value)
                    }}
                    type="password"
                    showPasswordFunction={()=> setShowConfirmPassword(!showConfirmPassword)}
                    showPassword={showConfirmPassword}
                  />
                </Grid>
                <Grid item xs={12}>
                  {isLoading ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <PrimaryButton
                      onClick={() => {
                        handlePasswordSubmit()
                      }}
                    >
                      Create Password
                    </PrimaryButton>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Modal>
    </>
  )
}

export default ForgetPassword
