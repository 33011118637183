import React, { useState } from "react"
import { Box, Grid, IconButton, Modal, Typography } from "@mui/material"
import { GridCloseIcon } from "@mui/x-data-grid"
import AvatarService from "../../../services/image-service"
import { PrimaryButton } from "../../button"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { formDataAuthRequest } from "../../../services/api-service"
import { setImage, setMessage } from "../../../models/user/userSlice"

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "200px", sm: "auto" },
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
}

const ProfilePic = ({ isOpen, setIsOpen }) => {
    const imageService = new AvatarService()
    const dispatch = useAppDispatch()
    const user = useAppSelector((state) => state.user)
    const [image, setUserImage] = useState<number>(
        user?.user_image 
    )

    const handleSubmit = async () => {
        const payload = {
            membership_id: user.membership_id,
            profile_image: image,
        }
        try {
            const response = await formDataAuthRequest(user.token).post(
                "/api/updateProfileImage",
                payload,
            )
            if (await response.data) {
                dispatch(setImage({ ...user, user_image: image }))
                dispatch(
                    setMessage({
                        body: "Image Updated Successfully",
                        severity: "success",
                    }),
                )
                setIsOpen(false)
            }
        } catch (e: any) {
            dispatch(
                setMessage({ body: e.response.data.message, severity: "error" }),
            )
            setIsOpen(false)
        }
    }

    return (
        <>
            <Modal
                open={isOpen}
                onClose={() => {
                    setIsOpen(false)
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container spacing={2} direction="column">
                        <Grid item xs={12}>
                            <IconButton
                                aria-label="close"
                                onClick={() => {
                                    setIsOpen(false)
                                }}
                                sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                    color: "black",
                                }}
                            >
                                <GridCloseIcon />
                            </IconButton>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginBottom: "5px",
                            }}
                        >
                            <Typography style={{ fontSize: "3em" }}>
                                {"Update Avatar"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <Grid container spacing={2}>
                                    {imageService.getAvatars().map((avatar, index) => (
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={4}
                                            lg={4}
                                            key={index}
                                            style={{ display: "flex", justifyContent: "center" }}
                                        >
                                            <div
                                                style={{
                                                    cursor: "pointer",
                                                    border: index + 1 === image ? "2px solid #525151" : "none",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    backgroundColor: "#d1cbcb",
                                                    borderRadius: "10px",
                                                }}
                                                onClick={() => setUserImage(index + 1)}
                                            >
                                                <img src={avatar} alt="avatar" height={"85px"} />
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} mt={3}>
                                <Grid item xs={12} sm={6}>
                                    {" "}
                                    <PrimaryButton
                                        sx={{ backgroundColor: "#686868" }}
                                        onClick={() => setIsOpen(false)}
                                    >
                                        Cancel
                                    </PrimaryButton>{" "}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <PrimaryButton onClick={() => {handleSubmit()}}>Save Changes</PrimaryButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Other components */}
                    </Grid>
                </Box>
            </Modal>
        </>
    )
}

export default ProfilePic
