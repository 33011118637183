import {
  Box,
  Checkbox,
  Grid,
  Paper,
  Switch,
  Typography,
  useTheme,
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { GridColDef } from "@mui/x-data-grid"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import PageTitleSection from "../../../components/pageTitle"
import CustomInput from "../../../components/inputBox"
import CustomButton from "../../../components/buttons"
import CustomTable from "../../../components/dataGrid"
import { addAlert } from "../../../models/alert/alertSlice"
import { AXIOSINSTANCE } from "../../../services/api-service"
import ProductDetails from "./productDetails"
import AddProduct from "./addProduct"
import ActionModalPopup from "../../../components/actionModal"

const API_URL = import.meta.env.VITE_BASE_URL

function ProductManagement() {
  const dispatch = useDispatch()
  const [tablePage, setTablePage] = useState(1)
  const [tableRowPerPage, setTableRowPerPage] = useState(10)
  const [tableRow, setTableRow] = useState([])
  const [tableAllRowCount, setTableAllRowCount] = useState(0)
  const [productName, setProductName] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [isDetailPopupOpen, setIsDetailPopupOpen] = useState(false)
  const [currentSelectedProductID, setSelectedProductID] = useState<number>()
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [isStatusPopup, setIsStatusPopup] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState<number>()
 const token = JSON.parse(localStorage.getItem("persist:admin"))

  const theme: any = useTheme()


  const columns: GridColDef[] = [
    { field: "id", headerName: "Pr.No", flex: 1, align: "center" },
    {
      field: "image",
      headerName: "Product",
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            <img
              src={`${API_URL + params.value}`}
              style={{ width: "50px", height: "50px", cursor: "pointer" }}
              onClick={() => {
                handleOpenDetailPopup()
                setSelectedProductID(params?.row?.id)
              }}
            />
          </div>
        )
      },
    },
    { field: "title", headerName: "Name", flex: 1, align: "center" },
    {
      field: "link",
      headerName: "URL",
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              fontSize: "14px",
              textDecoration: "underline",
              cursor: "pointer",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            onClick={() => {
              window.open(params.value, "_blank")
            }}
          >
            {params.value}
          </Typography>
        )
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            <Switch
              checked={params.value == 1 ? true : false}
              onChange={() => {
                setSelectedProductID(params?.row?.id)
                setSelectedStatus(params.value)
                setIsStatusPopup(true)
              }}
              inputProps={{ "aria-label": "controlled" }}
              sx={{
                width: 42,
                height: 26,
                padding: 0,
                m: 1,
                "& .MuiSwitch-switchBase": {
                  padding: 0,
                  margin: "2px",
                  transitionDuration: "300ms",
                  "&.Mui-checked": {
                    transform: "translateX(16px)",
                    color: "#fff",
                    "& + .MuiSwitch-track": {
                      backgroundColor: "#FF5600",
                      opacity: 1,
                      border: 0,
                    },
                    "&.Mui-disabled + .MuiSwitch-track": {
                      opacity: 0.5,
                    },
                  },
                  "&.Mui-focusVisible .MuiSwitch-thumb": {
                    color: "#FF5600",
                    border: "6px solid #fff",
                  },
                  "&.Mui-disabled .MuiSwitch-thumb": {
                    color: "#979797",
                  },
                  "&.Mui-disabled + .MuiSwitch-track": {
                    opacity: 0.3,
                  },
                },
                "& .MuiSwitch-thumb": {
                  boxSizing: "border-box",
                  width: 22,
                  height: 22,
                },
                "& .MuiSwitch-track": {
                  borderRadius: 26 / 2,
                  backgroundColor: "#AFAFBD",
                  opacity: 1,
                  transition: theme.transitions.create(["background-color"], {
                    duration: 500,
                  }),
                },
              }}
            />
          </div>
        )
      },
    },
  ]

  // Fetch products data
  const fetchProductData = async (filterState: boolean) => {
    let parameters: any = {
      page: tablePage,
    }
    try {
      setLoading(true)

      if (filterState) {
        if (productName) {
          parameters.title = productName
        }
      }
      const { data } = await AXIOSINSTANCE().get(`/api/product/get`, {
        params: parameters,
      })
      setTableRow(data.data.data)
      setTableAllRowCount(data.data.total)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      dispatch(
        addAlert({
          alertState: true,
          alertType: "Error",
          alertMessage: "Error",
          alertDescription: "Something is Wrong",
        }),
      )
    }
  }

  const handlePageChange = (newPage: number) => {
    setTablePage(newPage + 1)
  }

  const handleSearch = () => {
    setTablePage(1)
    fetchProductData(true)
  }

  // change status
  const changeStatus = async () => {
    try {
      await AXIOSINSTANCE().post(`/api/product/status`, {
        ids: [currentSelectedProductID],
        status: selectedStatus == 1 ? 2 : 1,
      })
      fetchProductData(false)
      dispatch(
        addAlert({
          alertState: true,
          alertType: "Success",
          alertMessage: "Successfully updated",
          alertDescription: "Product status successfully updated",
        }),
      )
    } catch (error: any) {
      dispatch(
        addAlert({
          alertState: true,
          alertType: "Error",
          alertMessage: "Error",
          alertDescription: "Product status change is unsuccessful.",
        }),
      )
    }
  }

  // Handle bulk action
  const selectedRowsFunction = async (rows: any) => {
    try {
      await AXIOSINSTANCE().post("api/product/delete", {
        ids: rows,
      })
      fetchProductData(false)
      dispatch(
        addAlert({
          alertState: true,
          alertType: "Success",
          alertMessage: "Successfully deleted",
          alertDescription: "Product successfully deleted",
        }),
      )
    } catch (error) {
      console.log(error)
      dispatch(
        addAlert({
          alertState: true,
          alertType: "Error",
          alertMessage: "Error",
          alertDescription: "Product delete is unsuccessful.",
        }),
      )
    }
  }

  const handleOpenDetailPopup = () => {
    setIsDetailPopupOpen(true)
  }

  const handleDetailClosePopup = () => {
    fetchProductData(false)
    setIsDetailPopupOpen(false)
  }

  const handleOpenAddPopup = () => {
    setIsAddPopupOpen(true)
  }

  const handleAddClosePopup = () => {
    fetchProductData(false)
    setIsAddPopupOpen(false)
  }

  // useEffect(() => {
  //   if (token.isLoggedIn =="false") {
  //     window.location.href = "/admin/login";
  //   } 
  // }, [])

  useEffect(() => {
    fetchProductData(true)
  }, [tablePage, tableRowPerPage])

  return (
    <>
      <Box sx={{ width: "100%", height: "100%" }}>
        {/* ........................page Title Component......................... */}
        <PageTitleSection
          title={"Product Management"}
          btnText={"Add Product"}
          btnFunction={() => {
            handleOpenAddPopup()
          }}
          btnIcon={<AddIcon />}
        />
        {/* ..........................................Filter Grid............................... */}
        <Grid
          container
          spacing={2}
          marginTop={"10px"}
          marginBottom={"20px"}
          paddingTop={"50px"}
        >
          <Grid item xs={6} sm={6} md={8}>
            <CustomInput
              id={0}
              TextFieldName={"Search Product"}
              labelText={""}
              placeHolderText={"Search Product"}
              TextFieldType={"text"}
              variant={"outlined"}
              value={productName}
              onchangeFunction={(e: any) => {
                setProductName(e.target.value)
              }}
              errorTextState={false}
              errorText={""}
              textFieldSize={"small"}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 2,
            }}>
         
            <Grid item xs={6}>
              <CustomButton
                variant={"contained"}
                buttonText={"Search"}
                id={0}
                buttonFunction={() => {
                  handleSearch()
                }}
              />
               </Grid>
  <Grid item xs={6}>
<CustomButton
                variant={"outlined"}
                buttonText={"Clear Filter"}
                id={0}
                buttonFunction={() => {
                setProductName('')
                fetchProductData(false)
                }}
                type="secondary"
              />
             
            </Grid>
            </Grid>
          </Grid>
        
        {/* .............................. page Paper .................................... */}
        <Paper
          sx={{
            padding: 2,
            display: "flex",
            gap: 2,
            flexDirection: "column",
            width: "100%",
            height: "100%",
            border: "1px solid #EAECF0",
            borderRadius: 5,
            zIndex: 5,
          }}
        >
          {/* ............................................ Bulk Action Section ............................................ */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 4,
              marginLeft: "10px",
              border: "1px solid #EAECF0",
              borderRadius: 5,
              height: "44px",
              visibility: selectedRows.length != 0 ? "visible" : "hidden",
            }}
          >
            <Checkbox
              size="small"
              disabled={true}
              checked={selectedRows.length != 0 ? true : false}
              sx={{
                "& .MuiSvgIcon-root": {
                  width: "16px",
                  height: "16px",
                  borderRadius: "4px",
                  color: "#FF5600",
                },
              }}
            />
            <Typography
              sx={{
                width: "150px",
              }}
            >
              {`${selectedRows.length} Item Selected`}
            </Typography>
            <CustomButton
              variant={"text"}
              buttonText={"Delete"}
              id={0}
              buttonFunction={() => {
                setIsDelete(true)
              }}
              style={{
                borderRadius: "10px",
                border: "1px solid #ffffff",
                color: "#FF5600",
              }}
              type="secondary"
              buttonWidth="50px"
            />
          </div>
          {/* ........................................... Table Section .............................................. */}
          <div style={{ width: "100%", height: "100%" }}>
            <CustomTable
              rows={tableRow}
              columns={columns}
              checkboxState={true}
              columnGroupingState={false}
              columnGroupingModel={[]}
              allRowCount={tableAllRowCount}
              initialPageSize={tableRowPerPage}
              page={tablePage - 1}
              dataLoading={loading}
              onPageChange={handlePageChange}
              setSelectedRows={setSelectedRows}
              setInitialPageSize={setTableRowPerPage}
            />
          </div>
        </Paper>

        {isDetailPopupOpen && (
          <ProductDetails
            isPopupOpen={isDetailPopupOpen}
            handleClosePopup={handleDetailClosePopup}
            selectedProductID={currentSelectedProductID}
          />
        )}
        {/* Add product */}
        {isAddPopupOpen && (
          <AddProduct
            isPopupOpen={isAddPopupOpen}
            handleClosePopup={() => handleAddClosePopup()}
          />
        )}

        {/* Add product */}
        {isAddPopupOpen && (
          <AddProduct
            isPopupOpen={isAddPopupOpen}
            handleClosePopup={() => handleAddClosePopup()}
          />
        )}

        {/* Delete modal */}
        <ActionModalPopup
          onClose={() => setIsDelete(false)}
          open={isDelete}
          title="Do you want to delete this product?"
          onAction={() => {
            selectedRowsFunction(selectedRows)
            setIsDelete(false)
          }}
        />

        {/* status modal */}
        <ActionModalPopup
          onClose={() => setIsStatusPopup(false)}
          open={isStatusPopup}
          title="Do you want change status this product?"
          onAction={() => {
            changeStatus()
            setIsStatusPopup(false)
          }}
        />
      </Box>
    </>
  )
}

export default ProductManagement
