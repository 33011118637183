import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import MainContainer from "../../containers/mainContainer"
import { setMessage } from "../../models/user/userSlice"
import { Alert, Snackbar } from "@mui/material"
import CookieConsent from 'react-cookie-consent';

const MainLayout = (props: { mainElements?: React.ReactNode }) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.user)
  useEffect(() => {
    if (user.message.body) {
      setTimeout(() => {
        dispatch(setMessage({ ...user.message, body: "" }))
      }, 5000)
    }
  }, [user.message.body])

  return (
    <MainContainer>
      {props.mainElements}
      <div>
      <CookieConsent
        location="bottom"
        buttonText="Accept All"
        cookieName="myAwesomeCookieName2"
        style={{
          background: "#333",
          color: "#fff",
          textAlign: "center",
          fontSize: "13px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        buttonStyle={{
          background: "#ff5600",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          padding: "8px 20px",
          fontSize: "13px",
          cursor: "pointer"
        }}
        onAccept={() => {
          console.log("Accepted cookies");
        }}
        onDecline={() => {
          console.log("Declined cookies");
        }}
      >
        <span>
          This site uses cookies to offer you a better browsing experience. Find out <a href="https://atlasworld.atlas.lk/cookie_policy_aw.pdf" style={{ color: "#ff5600" }}>more</a> on how we use cookies.
        </span>
      </CookieConsent>
    </div>
    </MainContainer>
  )
}

export default MainLayout
