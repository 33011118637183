import { Box } from '@mui/material'

function BannerCard({image,fileType,onClick}:
    {
        image:string,
        fileType:string,
        onClick:() => void,
    }) {

  return (
<Box
      sx={{
        borderRadius: '10px',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      {fileType=="image"?
      (
      <img
        style={{
          width: '100%',
          height:"364px",
          display: 'block', 
          margin: 0, 
          padding: 0, 
          cursor:"pointer"
        }}
        src={image}
        onClick={()=>onClick()}
        alt="Banner"
      />):
      (
<video
            style={{ 
              width: '100%',
            height:"364px",
            display: 'block', 
            margin: 0, 
            padding: 0, 
            cursor:"pointer",
            objectFit: "cover",
          }}
            src={image}
            autoPlay
            muted
            loop
            onClick={()=>onClick()}
          /> 
      )}
      
    </Box>
  )
}

export default BannerCard