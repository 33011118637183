import {
    CircularProgress,
    IconButton,
    InputAdornment,
    Typography,
  } from "@mui/material"
  import { Box } from "@mui/system"
  import { useEffect, useState } from "react"
  import { Visibility, VisibilityOff } from "@mui/icons-material"
//   import { loginApi } from "../../../api/login.api"
import CustomInput from "../../../../components/inputBox"
import CustomButton from "../../../../components/buttons"
import { formDataRequest } from "../../../../services/api-service"
import { setAdminDetails } from "../../../../models/admin/adminSlice"
import { useAppDispatch } from "../../../../app/hooks"
  
  const loginButton = {
    borderRadius: "25px",
    height: "54px",
    color: "#0a0a0a",
    border: "1px solid black",
    "&:hover": { border: "1px solid black" },
  }
  
  const LoginBox = ({ setViewState }: { setViewState: any }) => {
    const [showPassword, setShowPassword] = useState(false)
    const [fieldValue, setFieldValue] = useState({
      email: "",
      emailError: false,
      emailErrorMsg: "",
      emailBorderColor: "#C4C4C4",
      password: "",
      passwordError: false,
      passwordErrorMsg: "",
      passwordBorderColor: "#C4C4C4",
    })
    const [error, setError] = useState({
      state: false,
      massage: "",
    })
    const [loading, setLoading] = useState(false)
    const token = JSON.parse(localStorage.getItem("persist:admin"))
    const dispatch = useAppDispatch()
  
    const handleInputValue = (e: any) => {
      if (e.target.name == "email") {
        if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(fieldValue.email)) {
          setFieldValue((pre) => {
            return {
              ...pre,
              emailBorderColor: "#9EC924",
            }
          })
        } else {
          setFieldValue((pre) => {
            return {
              ...pre,
              emailBorderColor: "#C4C4C4",
            }
          })
        }
      }
  
      if (e.target.name == "password") {
        if (/^.{8,}$/.test(fieldValue.password)) {
          setFieldValue((pre) => {
            return {
              ...pre,
              passwordBorderColor: "#9EC924",
            }
          })
        } else {
          setFieldValue((pre) => {
            return {
              ...pre,
              passwordBorderColor: "#C4C4C4",
            }
          })
        }
      }
  
      if (fieldValue.emailError && e.target.name == "email") {
        setFieldValue((pre) => {
          return { ...pre, emailError: false, emailErrorMsg: "" }
        })
      }
      if (fieldValue.passwordError && e.target.name == "password") {
        setFieldValue((pre) => {
          return { ...pre, passwordError: false, passwordErrorMsg: "" }
        })
      }
      if (error.state) {
        setError({
          state: false,
          massage: "",
        })
      }
      setFieldValue((pre) => {
        return { ...pre, [e.target.name]: e.target.value }
      })
    }
  
    const loginFunction = async () => {
      if (fieldValue.email == "") {
        setFieldValue((pre) => {
          return {
            ...pre,
            emailError: true,
            emailErrorMsg: "You need to Enter Email",
          }
        })
        return
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(fieldValue.email)) {
        setFieldValue((pre) => {
          return {
            ...pre,
            emailError: true,
            emailErrorMsg: "You need to Enter valid Email",
          }
        })
        return
      }
      if (fieldValue.password == "") {
        setFieldValue((pre) => {
          return {
            ...pre,
            passwordError: true,
            passwordErrorMsg: "You need to Enter Password",
          }
        })
        return
      }
      try {
        setLoading(true)
        const body = {
          email: fieldValue.email,
          password: fieldValue.password,
        }
  
        const response = await formDataRequest.post("/api/admin/login",body);

        if (response.data.token) {
            dispatch(
              setAdminDetails({
                token: response.data.token,
                id: response.data.admin.id,
                isLoggedIn: true,
                name: response.data.admin.name,
                username: response.data.admin.email,
              })
            );
            setLoading(false)

            window.location.href = "/admin/producttable";
          }
      } catch (error: any) {
        setFieldValue((pre) => {
            return {
              ...pre,
              emailBorderColor: "#FF2828",
              passwordBorderColor: "#FF2828"

            }
          })
        setLoading(false)
        setError({
          state: true,
          massage: "Invalid credentials",
        })
      }
    }
    useEffect(() => {
      if (token?.isLoggedIn === "true") {
        window.location.href = "/admin/producttable";
      } 
    }, [token])

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          padding: 5,
          gap: 3,
          width: "50%",
        }}>
        <Typography sx={{ fontSize: "40px", fontWeight: 700 , color:"#424B5A"}}>
          Sign In
        </Typography>
        <CustomInput
          id={0}
          TextFieldName={"email"}
          labelText={""}
          placeHolderText={"Enter Email Address"}
          TextFieldType={"text"}
          variant={"outlined"}
          value={fieldValue.email}
          style={{
            height: "64px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "35px",
              fieldset: {
                borderColor: fieldValue.emailBorderColor,
              },
            },
            "& .MuiOutlinedInput-input": {
              marginLeft: "10px",
            },
          }}
          onchangeFunction={handleInputValue}
          errorTextState={fieldValue.emailError}
          errorText={fieldValue.emailErrorMsg}
          onKeyPressFunctionData={{
            eventName: "Enter",
            eventFunction: loginFunction,
          }}
        />
        <CustomInput
          id={1}
          TextFieldName={"password"}
          labelText={""}
          placeHolderText={"Enter Password"}
          TextFieldType={showPassword ? "text" : "password"}
          variant={"outlined"}
          value={fieldValue.password}
          style={{
            height: "64px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "35px",
              fieldset: {
                borderColor: fieldValue.passwordBorderColor,
              },
            },
            "& .MuiOutlinedInput-input": {
              marginLeft: "10px",
            },
          }}
          onchangeFunction={handleInputValue}
          errorTextState={fieldValue.passwordError}
          errorText={fieldValue.passwordErrorMsg}
          onKeyPressFunctionData={{
            eventName: "Enter",
            eventFunction: loginFunction,
          }}
          endAdornment={
            <InputAdornment position="end" sx={{ marginRight: "10px" }}>
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  setShowPassword((show) => !show)
                }}
                edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Typography
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
              color:"#818181",
              fontSize:16
            }}
            onClick={() => {
              setViewState("forgetPassword")
            }}>
            Forgot Password
          </Typography>
        </div>
        {error.state ? (
          <Typography sx={{ color: "#ff2929", display: "block" }}>
            {error.massage}
          </Typography>
        ) : (
          <div style={{ height: "24px" }}></div>
        )}
        <CustomButton
          variant={'outlined'}
          buttonText={loading ? <CircularProgress /> : 'Sign In'}
          disableState={loading ? true : false}
          id={0}
          buttonFunction={loginFunction}
          style={loginButton}
          type="secondary"
        />
      </Box>
    )
  }
  
  export default LoginBox
  