import { Button, SxProps, Theme } from "@mui/material"

const CustomButton = ({
  variant,
  buttonText,
  id,
  style,
  paramOne,
  paramTwo,
  iconStart,
  disableState,
  type,
  buttonWidth,
  buttonFunction,
}: {
  variant: "text" | "contained" | "outlined"
  buttonText: any
  id: number
  style?: SxProps<Theme>
  paramOne?: any
  paramTwo?: any
  iconStart?: JSX.Element
  disableState?: boolean
  type?: "secondary";
  buttonWidth?: string
  buttonFunction: (paramOne?: any, paramTwo?: any) => void
}) => {
  const defaultStyles: SxProps = {
    height: "40px",
    width: buttonWidth || "100%",
    borderRadius: "8px",
    textTransform: "none",
    fontSize: 14,
    boxShadow: "none",
    backgroundColor: "#FF5600",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#FF560e",
    },
  }

  const secondaryStyles: SxProps = {
    height: "40px",
    width: buttonWidth || "100%",
    borderRadius: "8px",
    textTransform: "none",
    fontSize: 14,
    boxShadow: "none",
    backgroundColor: "#ffffff",
    borderColor:"#D0D5DD",
    color: "#000000",
    "&:hover": {
      backgroundColor: "#ffffff",
      borderColor:"#000000",
    },
  }

  return (
    <>
      <Button
        key={id}
        sx={{
          ...(type == "secondary" ? secondaryStyles : defaultStyles),
          ...(style ? style : {}),
        }}
        variant={variant}
        disabled={disableState || false}
        startIcon={iconStart ? iconStart : ""}
        onClick={() => {
          buttonFunction(paramOne, paramTwo)
        }}>
        {buttonText}
      </Button>
    </>
  )
}

export default CustomButton
