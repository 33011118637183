import { useState } from "react"
import CssBaseline from "@mui/material/CssBaseline"
import DrawerContext from "../../util/DrawerContext"
import { MainContainer } from "./styles"
import SideBar from "../layout/sideBar"
import Header from "../layout/header"
import AlertComponent from "../alert"
import LogoutModal from "../logoutModal"

const MainComponent = ({ children }: { children: any }) => {
  const [drawerWidth, setDrawerWidth] = useState<string>("260px")


  return (
    <DrawerContext.Provider value={{ drawerWidth, setDrawerWidth }}>
      <CssBaseline />
      <Header drawerWidth={drawerWidth} setDrawerWidth={setDrawerWidth} />
      <SideBar drawerwidth={drawerWidth} />
      <AlertComponent/>
      <LogoutModal />
      <MainContainer drawerwidth={drawerWidth}>{children}</MainContainer>
    </DrawerContext.Provider>
  )
}

export default MainComponent
