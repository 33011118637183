import { Box, Button, Grid, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import upload_icon from "../../assets/img/Uploader_Icon.svg"
import { useDispatch } from "react-redux"
import ArticleIcon from "@mui/icons-material/Article"
import CloseIcon from "@mui/icons-material/Close"
import ImageIcon from "@mui/icons-material/Image"
import RefreshIcon from "@mui/icons-material/Refresh"
import AddIcon from "@mui/icons-material/Add"
import { addAlert } from "../../models/alert/alertSlice"

type FileTypes = "image/jpeg" | "image/png" | "video/mp4"

const Uploader = ({
  imageData,
  setImageData,
  setImageFileNameData,
  containerText,
  allowMultipleUpload,
  fileTypes,
  addMoreState = true,
}: {
  imageData?: any
  setImageData?: any
  setImageFileNameData?: any
  containerText: string
  allowMultipleUpload: boolean
  fileTypes: FileTypes[]
  addMoreState?: boolean
}) => {
  const dispatch = useDispatch()
  const [dragging, setDragging] = useState(false)
  const [files, setFiles] = useState<any[]>([])
  const [filesName, setFilesName] = useState<any>()
  const fileInputRef = useRef<any>(null)

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setDragging(true)
  }

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setDragging(false)
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    const droppedFiles = Array.from(e.dataTransfer.files)
    handleFiles(droppedFiles)
  }

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files || [])
    handleFiles(selectedFiles)
  }

  const removeLastPeriod = (text: string) => {
    const lastPeriodIndex = text.lastIndexOf(".")
    if (lastPeriodIndex === -1 || lastPeriodIndex === 0) {
      return text
    }
    return text.slice(0, lastPeriodIndex)
  }

  const getFileExtension = (mimeType: string) => {
    const parts = mimeType.split("/")
    return parts[1]
  }

  const handleFiles = (selectedFiles: File[]) => {
   try{ 
    if (!allowMultipleUpload) {
      selectedFiles = [selectedFiles[0]] // If multiple uploads not allowed, only consider the first file
    }
    const validFiles = selectedFiles.filter((file: any) =>
      fileTypes.includes(file.type)
    )
    const invalidFiles = selectedFiles.filter(
      (file) => !validFiles.includes(file)
    )
    if (invalidFiles.length > 0) {
      dispatch(
        addAlert({
          alertState: true,
          alertType: "Warning",
          alertMessage: "Warning",
          alertDescription: "Only JPEG, PNG, or PDF files are allowed.",
        })
      )
    }
    let newFiles: any[] = []
    if (allowMultipleUpload) {
      newFiles = [...files]
    }
    validFiles.forEach((file, _index) => {
      if (file.size < 20971520) {
        setFilesName(
          {
            name: `${removeLastPeriod(file.name)}`,
            type: getFileExtension(file.type),
          },
        )
        if (setImageData) {
          setImageData([...selectedFiles])
        }
        const reader = new FileReader()
        reader.onload = () => {
          newFiles.push(reader.result)
          setFiles([...newFiles])
        }
        reader.readAsDataURL(file)
      } else {
        dispatch(
          addAlert({
            alertState: true,
            alertType: "Warning",
            alertMessage: "Warning",
            alertDescription: "File size must be less than 15MB.",
          })
        )
      }
    })
    setDragging(false)}
    catch(error){
      console.log("file upload error", error)
    }
  }

  const handleRemoveFile = (index: number) => {
    const newFiles = [...files]
    newFiles.splice(index, 1)
    setFiles(newFiles)
    const newFileName = [...filesName]
    newFileName.splice(index, 1)
    setFilesName(newFileName)
    if (setImageFileNameData) {
      setImageFileNameData(newFileName)
    }
    if (setImageData) {
      setImageData(newFiles)
    }
  }

  useEffect(() => {
    if (imageData) {
      setFiles(imageData)
    }
  }, [imageData])

  useEffect(() => {
    if (Array.isArray(filesName)) {
        const fileNames = filesName.map((file) => {
            return file.name;
        });
        setImageFileNameData(fileNames);
    } else {
        setImageFileNameData([]);
    }
}, [filesName]);
  
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          border: "1px solid #ccc",
          borderRadius: 8,
          padding: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: dragging ? "#f0f0f0" : "#ffffff",
        }}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}>
        {files?.length > 0 ? (
          allowMultipleUpload ? (
            <Box
              sx={{
                height: "100%",
                width: "100%",
                padding: "5px",
              }}>
              <Grid
                container
                sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
                {files.map((_file, index) => (
                  <Grid item xs={4} key={index} sx={{ padding: "5px" }}>
                    <Box
                      sx={{
                        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                        border: "1px solid #D0D5DD",
                        borderRadius: "8px",
                        height: "44px",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        width: "100%",
                      }}>
                      {filesName[index].type === "pdf" ? (
                        <ArticleIcon sx={{ color: "#8B8B8B" }} />
                      ) : (
                        <ImageIcon sx={{ color: "#8B8B8B" }} />
                      )}

                      <div style={{ width: "100%", height: "20px" }}>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            width: "170px", // Set the desired width
                            whiteSpace: "nowrap", // Prevent the text from wrapping
                            overflow: "hidden", // Hide overflow text
                            textOverflow: "ellipsis", // Add ellipsis at the end if text overflows
                            "&:hover": {
                              textDecoration: "underline",
                              textTransform: "none",
                              cursor: "pointer",
                            },
                          }}
                          // onClick={()=>{setPopViewData({state:true,data:file})}}
                        >
                          {`${filesName[index].name}.${filesName[index].type}`}
                        </Typography>
                      </div>
                      <div
                        style={{
                          width: "40px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}>
                        <CloseIcon
                          sx={{
                            cursor: "pointer",
                            width: "18px",
                            height: "18px",
                          }}
                          onClick={() => handleRemoveFile(index)}
                        />
                      </div>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : (
              
             (filesName.type==='mp4') ?( 
         <video
              style={{ maxWidth: "100%", maxHeight: "100%" }}
              src={files[0]}
              autoPlay
              muted
              loop
            />   
          ):( 
          <img
                style={{ maxWidth: "100%", maxHeight: "100%" }}
                src={files[0]}
                alt="No Image Available"
              />
           )   
          )
        ) : 
        
        (
          <Grid
            container
            sx={{ display: "flex", flexDirection: "row", width: "400px" }}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}>
              <img src={upload_icon} width={"43px"} height={"43px"} />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: "5px",
              }}>
              <Button
                component="label"
                variant="contained"
                tabIndex={-1}
                sx={{
                  textTransform: "none",
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#000000",
                  backgroundColor: "#ffffff",
                  boxShadow: 0,
                  display: "flex",
                  alignItems: "center",
                  padding: 0,
                  "&:hover": {
                    textDecoration: "underline",
                    textTransform: "none",
                    color: "#000000",
                    backgroundColor: "#ffffff",
                    boxShadow: 0,
                    display: "flex",
                    alignItems: "center",
                    padding: 0,
                  },
                }}>
                {`${containerText}`}
                <input
                  type="file"
                  accept={fileTypes.join('", "') + '"'}
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileInputChange}
                  multiple={allowMultipleUpload}
                />
              </Button>
            </Grid>
          </Grid>
        )}
      </div>
      {addMoreState && files.length > 0 && (
        <Button
          component="label"
          variant="text"
          tabIndex={-1}
          sx={{
            textDecoration: "underline",
            textTransform: "none",
            color: "#000000",
            backgroundColor: "#ffffff",
            display: "flex",
            justifyContent: "left",
            "&:hover": {
              textDecoration: "underline",
              textTransform: "none",
              color: "#000000",
              backgroundColor: "#ffffff",
              display: "flex",
              justifyContent: "left",
            },
          }}
          startIcon={
            allowMultipleUpload ? (
              <AddIcon sx={{ width: "16px", height: "16px" }} />
            ) : (
              <RefreshIcon sx={{ width: "16px", height: "16px" }} />
            )
          }>
          {allowMultipleUpload ? "Add more files" : "Reupload"}
          <input
            type="file"
            accept={fileTypes.join('", "') + '"'}
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileInputChange}
            multiple={allowMultipleUpload}
          />
        </Button>
      )}
    </>
  )
}

export default Uploader
