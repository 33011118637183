import {
  Alert,
  Box,
  Container,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material"
import {
  ButtonStyles,
  ContainerStyles,
  FormContainer,
  Heading,
  InnerContainerStyles,
  SubHeading,
  TextContainer,
  TwoColFields,
} from "./styles"
import { useEffect, useState } from "react"
import { PrimaryButton } from "../../components/button"
import { formDataAuthRequest } from "../../services/api-service"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  resetUser,
  setImage,
  setUserDetails,
} from "../../models/user/userSlice"
import UserPlaceholder from "../../assets/img/user-avatar-placeholder.png"
import { MdEdit } from "react-icons/md"
import EditProfileModal from "../../components/modal/edit-profile-modal"
import { useNavigate } from "react-router-dom"
import AvatarService from "../../services/image-service"
import ProfilePic from "../../components/modal/ProfilePic"
import ResetMobile from "../../components/modal/resetMobile"
import { Helmet } from "react-helmet"

const ProfileDetailsPage = () => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isProfilePicModalOpen, setIsProfilePicModalOpen] = useState(false)
  const [resetMobileModal, setResetMobileModal] = useState(false)
  const [modalType, setModalType] = useState("details")

  const imageService = new AvatarService()

  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.user)

  useEffect(() => {
    if (isEditModalOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [isEditModalOpen])

  const handleModalOpen = (type: string) => {
    setModalType(type)
    setIsEditModalOpen(true)
  }
  const handleChangeMobileModalClose = () =>{
    setResetMobileModal(!resetMobileModal)
   
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (user.isLoggedIn) {
      getUserData()
    } else {
      navigate("/")
    }
  }, [user.isLoggedIn])

  const getUserData = async () => {
    const response = await formDataAuthRequest(user.token).post(
      "/api/getZohoRecord",
      { membership_id: user.membership_id },
    )

    if (response) {
      const childrenDetails = []

      response.data.record.Children_Details.map((child) => {
        const data = {
          Child_Name: child.Child_Name,
          DOB: child.DOB,
          Grade: child.Grade,
          Subjects: child.Subjects,
        }
        childrenDetails.push(data)
      })

      const data = {
        first_name: response.data.record.First_Name,
        last_name: response.data.record.Last_Name,
        record_source: response.data.record.Name,
        mobile: response.data.record.Mobile,
        email: response.data.record.Email,
        district: response.data.record.District,
        student_id: response.data.record.Student_ID,
        children_details: childrenDetails,
      }

      dispatch(setUserDetails({ ...user, userDetails: data }))
      dispatch(setImage({ user_image: response?.data?.image || 0 }))
    }
  }

  return (
    <>
    <Helmet>
      <title>Atlas World</title>
    </Helmet>

      {isEditModalOpen ? (
        <EditProfileModal isOpen={setIsEditModalOpen} type={modalType} />
      ) : (
        ""
      )}

      {isProfilePicModalOpen && (
        <ProfilePic
          isOpen={isProfilePicModalOpen}
          setIsOpen={()=>setIsProfilePicModalOpen(false)}
        />
      )}

{resetMobileModal && (
        <ResetMobile
          isOpen={resetMobileModal}
          setIsOpen={handleChangeMobileModalClose}
        />
      )}
      <Container sx={ContainerStyles}>
        <Container sx={InnerContainerStyles}>
          <Typography variant="h1" sx={Heading}>
            Profile Details
          </Typography>
          <Box sx={FormContainer}>
            {user.message.body ? (
              <Alert severity={user.message.severity}>
                {user.message.body}
              </Alert>
            ) : (
              ""
            )}
            <Typography variant="h2" sx={SubHeading}>
              User Details
            </Typography>
            <Grid container>
              <Grid container sx={{ gap: "65px", alignItems: "flex-start" }}>
                <Grid item style={{ paddingLeft: "24px" }}>
                  <Box sx={{ position: "relative" }}>
                    <Box
                      component="img"
                      height={"200px"}
                      src={
                        user?.user_image == 0
                          ? UserPlaceholder
                          : imageService.getAvatar(user.user_image - 1)
                      }
                    ></Box>
                    <Box
                      onClick={() => {
                        setIsProfilePicModalOpen(true)
                        // handleModalOpen("image")
                      }}
                      component="button"
                      sx={{
                        width: "35px",
                        height: "35px",
                        backgroundColor: "#EFECE8",
                        border: "3px solid #ffffff",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        bottom: "0",
                        cursor: "pointer",
                        right: "18px",
                        "& svg": {
                          width: "16px",
                          height: "16px",
                        },
                      }}
                    >
                      <MdEdit />
                    </Box>
                  </Box>
                </Grid>
                <Grid item>
                  <Tooltip
                    title={
                      (user.userDetails?.first_name || "") +
                      " " +
                      (user.userDetails?.last_name || "")
                    }
                    arrow
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 700,
                        color: "black",
                        mt: "20px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        paddingLeft: { xs: "16px", sm: "24px" },

                        width: { xs: "250px", md: "400px", lg: "500px" },
                      }}
                    >
                      {(user.userDetails?.first_name || "") +
                        " " +
                        (user.userDetails?.last_name || "")}
                    </Typography>
                  </Tooltip>
                  <Container sx={TextContainer}>
                    <Typography
                      variant="h6"
                      sx={{
                        marginRight: "12px",
                        color: "#686868",
                      }}
                    >
                      Member ID:
                    </Typography>
                    <Typography variant="h6">
                      {user?.userDetails?.student_id}
                    </Typography>
                  </Container>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  marginTop: "40px",
                  gap: { xs: "0", md: "67px" },
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <Grid item>
                  <Container sx={TextContainer}>
                    <Typography
                      variant="h6"
                      sx={{
                        marginRight: "12px",
                        color: "#686868",
                      }}
                    >
                      Mobile Number:
                    </Typography>
                    <Typography variant="h6">
                      {user.userDetails?.mobile}
                    </Typography>
                    <Typography variant="h6" sx={{color:"#5B5B5B", textDecorationLine:'underline', cursor:"pointer", marginLeft: "12px",}} onClick={()=>setResetMobileModal(true)}>
                        Edit
                    </Typography>
                  </Container>
                  <Container sx={TextContainer}>
                    <Typography
                      variant="h6"
                      sx={{
                        marginRight: "12px",
                        color: "#686868",
                      }}
                    >
                      Email:
                    </Typography>
                    <Typography variant="h6">
                      {user.userDetails?.email}
                    </Typography>
                  </Container>
                </Grid>
                <Grid item>
                  <Container sx={TextContainer}>
                    <Typography
                      variant="h6"
                      sx={{
                        marginRight: "12px",
                        color: "#686868",
                      }}
                    >
                      District:
                    </Typography>
                    <Typography variant="h6">
                      {user.userDetails?.district}
                    </Typography>
                  </Container>
                </Grid>
              </Grid>
            </Grid>
            <Divider
              sx={{
                marginLeft: { xs: "-16px", sm: "-85px" },
                marginRight: { xs: "-16px", sm: "-85px" },
              }}
            />
            <Typography variant="h2" sx={SubHeading}>
              Details of Children
            </Typography>

            {user.userDetails?.children_details?.map((child, index) => (
              <>
                <Grid
                  container
                  sx={{
                    justifyContent: "space-between",
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  <Grid item>
                    <Typography variant="h6" sx={{ marginRight: "1px" }}>
                      {index + 1}.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    sx={{ flex: "auto", width: { xs: "100%", sm: "40%" } }}
                  >
                    <Container sx={TextContainer}>
                      <Typography
                        variant="h6"
                        sx={{
                          marginRight: "12px",
                          color: "#686868",
                        }}
                      >
                        Child’s Name:
                      </Typography>

                      <Typography variant="h6">{child.Child_Name}</Typography>
                    </Container>
                    <Container sx={TextContainer}>
                      <Typography
                        variant="h6"
                        sx={{
                          marginRight: "12px",
                          color: "#686868",
                        }}
                      >
                        Subject:
                      </Typography>
                      <div>
                        {child.Subjects.map((subject: any, index: number) => (
                          <div key={index}>
                            <Typography variant="h6">{subject}</Typography>
                          </div>
                        ))}
                      </div>
                    </Container>
                  </Grid>
                  <Grid item sx={{ width: { xs: "100%", sm: "50%" } }}>
                    <Container sx={TextContainer}>
                      <Typography
                        variant="h6"
                        sx={{
                          marginRight: "12px",
                          color: "#686868",
                        }}
                      >
                        Date of Birth:
                      </Typography>

                      <Typography variant="h6">{child.DOB}</Typography>
                    </Container>
                    <Container sx={TextContainer}>
                      <Typography
                        variant="h6"
                        sx={{
                          marginRight: "12px",
                          color: "#686868",
                        }}
                      >
                        Grade:
                      </Typography>

                      <Typography variant="h6">{child.Grade}</Typography>
                    </Container>
                  </Grid>
                </Grid>
                <div>
                  <Divider />
                </div>
              </>
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: "60px",
              mb: "133px",
            }}
          >
            <PrimaryButton
              sx={{ maxWidth: "580px" }}
              onClick={() => handleModalOpen("details")}
            >
              Edit Details
            </PrimaryButton>
          </Box>
        </Container>
      </Container>
    </>
  )
}

export default ProfileDetailsPage
