import { createContext, Dispatch, SetStateAction } from "react"

interface DrawerContextType {
  drawerWidth: string
  setDrawerWidth: Dispatch<SetStateAction<string>>
}

const DrawerContext = createContext<DrawerContextType>({
  drawerWidth: "260px",
  setDrawerWidth: () => {},
})

export default DrawerContext
