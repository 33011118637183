import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material"
import { GridCloseIcon } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { PrimaryButton } from "../../button"
import { formDataRequest } from "../../../services/api-service"
import { setMessage, setUserDetails } from "../../../models/user/userSlice"
import { MuiOtpInput } from "mui-one-time-password-input"
import "./styles.css"
import { TextInputField } from "../../fields"
import { useNavigate } from "react-router-dom"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "250px", sm: "500px", md: "600px" },
  bgcolor: "#FFEFCE",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
}

const OtpModal = ({ isOpen, setIsOpen, getMobileNumber, source }) => {
  const [OTPNumber, setOTPNumber] = useState("")
  const [alertMassage, setAlertMassage] = useState({
    body: null,
    severity: null,
  })
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.user)
  const navigate = useNavigate()
  const [modalState, setModalState] = useState<string | null>("EnterOTP")
  const [newPassword, setNewPassword] = useState<string | null>(null)
  const [confirmPassword, setConfirmPassword] = useState<string | null>(null)
  const [resendTimer, setResendTimer] = useState(120)
  const [isResendDisabled, setIsResendDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)


  const checkPassword = () => {
    let validity = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(newPassword)
    return validity
  }

  const verifyOTP = async (getOTPNumber: any) => {
    if (getOTPNumber == null) {
      if (!OTPNumber || OTPNumber.length !== 5) {
        setAlertMassage({ body: "OTP is Invalid", severity: "error" })
        setOTPNumber("")
        return
      }
    }
    let formatNumber = getMobileNumber.slice(-9)
    const payload :any= {}

    payload.mobile ="+94" + formatNumber
    payload.otp = getOTPNumber || OTPNumber
    if(source)
   {
     payload.source = source
    }
    try {
      setIsLoading(true)
      const res = await formDataRequest
      .post("/api/registerZoho", payload)
      setIsLoading(false)
      dispatch(setUserDetails({ membership_id: res.data.response }))
      setModalState("addPassword")
    } catch (e: any) {
      console.log(e)
      setIsLoading(false)
      setAlertMassage({ body: e.response.data.message, severity: "error" })
      setOTPNumber("")
    }
  }

  const reSendOtp = async () => {
    setResendTimer(120)
    setIsResendDisabled(true)
    let formatNumber = getMobileNumber.slice(-9)
    const payload = {
      mobile: "+94" + formatNumber,
    }
    try {
      await formDataRequest
        .post("/api/registerZoho/resendOtp", payload)
        .then(async (res) => {})
    } catch (e: any) {
      
      setAlertMassage({ body: e.response.data.message, severity: "error" })
    }
  }

  const handlePasswordAdd = async () => {
    if (!newPassword) {
      setAlertMassage({ body: "You Have to type password", severity: "error" })
      return
    }
    if (!confirmPassword) {
      setAlertMassage({ body: "You Have to type Confirm Password", severity: "error" })
      return
    }
    if (newPassword != confirmPassword) {
      setAlertMassage({ body: "Password doesn't Match", severity: "error" })
      return
    }
    if (!checkPassword()) {
      setAlertMassage({
        body: "password must contain a minimum of 8 characters, have a uppercase and lowercase letters, a number and a special character. Spaces are not permitted",
        severity: "error",
      })
      return
    }

    let formatNumber = getMobileNumber.slice(-9)
    const payload = {
      membership_id: "+94" + formatNumber,
      password: newPassword,
    }
    setIsLoading(true)
    await formDataRequest
      .post("/api/register", payload)
      .then((response) => {
        setIsLoading(false)
        setAlertMassage({ body: "Register Successful", severity: "success" })
        setTimeout(() => {
          navigate("/")
        }, 3000)
      })
      .catch((e) => {
        setIsLoading(false)
        setAlertMassage({ body: e.response.data.message, severity: "error" })
      })
  }

  const formatTime = (seconds: any) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes}:${
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
    }`
  }

  const ShowPasswordFunction = ()=> {
    setShowPassword(!showPassword);
  }

  const ShowConfirmPasswordFunction = ()=> {
    setShowConfirmPassword(!showConfirmPassword);
  }

  useEffect(() => {
    if (alertMassage.body != null) {
      const interval = setTimeout(() => {
        setAlertMassage({ body: null, severity: null })
      }, 2000)

      return () => {
        clearTimeout(interval)
      }
    }
  }, [alertMassage])

  useEffect(() => {
    let countdownInterval: any

    if (resendTimer > 0 && isResendDisabled) {
      countdownInterval = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1)
      }, 1000)
    } else {
      setIsResendDisabled(false)
    }

    return () => {
      clearInterval(countdownInterval)
    }
  }, [resendTimer, isResendDisabled])

  useEffect(() => {
    if (modalState === "EnterOTP") {
      setIsResendDisabled(true)
      setResendTimer(120)
    } else {
      setResendTimer(0)
    }
  }, [modalState])

  const modalViewer = () => {
    switch (modalState) {
      case "EnterOTP":
        return (
          <>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "5px",
              }}
            >
              <Typography style={{ fontSize: "1.5em" }}>
                {"Mobile Number Verification"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "5px",
              }}
            >
              <Typography style={{ fontSize: "1em" }}>
                {
                  `Enter the 5 digit OTP code sent to the number ${"+94" + getMobileNumber.slice(-9)}.`
                }
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <MuiOtpInput
                value={OTPNumber}
                onChange={(e) => {
                  setOTPNumber(e)
                }}
                length={5}
                autoFocus
                className="my-custom-class"
                onComplete={(e) => {
                  verifyOTP(e)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              {isLoading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </div>
              ) : (
                <PrimaryButton
                  onClick={() => {
                    verifyOTP(null)
                  }}
                >
                  Verify
                </PrimaryButton>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                marginTop: "5px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography>
                Didn't receive the code yet ?{" "}
                {isResendDisabled ? (
                  <span>{formatTime(resendTimer)}</span>
                ) : (
                  <span
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: "blue",
                    }}
                    onClick={() => {
                      reSendOtp()
                    }}
                  >
                    {`Resend Code`}
                  </span>
                )}
              </Typography>
            </Grid>
          </>
        )
      case "addPassword":
        return (
          <>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "5px",
              }}
            >
              <Typography style={{ fontSize: "1.5em" }}>
                {"Create Password"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "5px",
              }}
            >
              <Typography style={{ fontSize: "1em" }}>
                {
                  "Your password must contain a minimum of 8 characters, have a uppercase and lowercase letters, a number and a special character. Spaces are not permitted."
                }
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextInputField
                placeholder="Enter New Password"
                name="password"
                onChange={(e: any) => {
                  setNewPassword(e.target.value)
                }}
                type="password"
                showPasswordFunction={ShowPasswordFunction}
                showPassword={showPassword}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInputField
                placeholder="Confirm New Password"
                name="confirmPassword"
                onChange={(e: any) => {
                  setConfirmPassword(e.target.value)
                }}
                type="password"
                showPasswordFunction={ShowConfirmPasswordFunction}
                showPassword={showConfirmPassword}
              />
            </Grid>
            <Grid item xs={12}>
              {isLoading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </div>
              ) : (
                <PrimaryButton
                  onClick={() => {
                    handlePasswordAdd()
                  }}
                >
                  Create Password
                </PrimaryButton>
              )}
            </Grid>
          </>
        )
      default:
        break
    }
  }

  return (
    <>
      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={2} direction="column">
            <Grid item xs={12}>
              <IconButton
                aria-label="close"
                onClick={() => {
                  setIsOpen(false)
                }}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "black",
                }}
              >
                <GridCloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              {alertMassage.body ? (
                <Alert severity={alertMassage.severity} sx={{ mb: "20px" }}>
                  {alertMassage.body}
                </Alert>
              ) : (
                ""
              )}
            </Grid>
            {modalViewer()}
          </Grid>
        </Box>
      </Modal>
    </>
  )
}

export default OtpModal
