import { Box } from "@mui/material"

interface SocialMediaIconProps {
  children?: React.ReactNode
  linkUrl?: string
}

export const SocialMediaIcon = (props: SocialMediaIconProps) => {
  return (
    <Box
      sx={{
        width: "33.25px",
        height: "33.25px",
        background: "#ffffff",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#FF5600",
        cursor: "pointer",
      }}
      onClick={() => {
        window.open(props.linkUrl, "_blank")
      }}>
      {props.children}
    </Box>
  )
}
