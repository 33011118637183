import { Button, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState,useEffect } from 'react'
import {  gridStyles, headingstyles, innerheadingStyles, monthStyle, noclassGridStyles, noclassmessagecarStyles, weekdaysStyles } from './calenderstyles'
import axios from 'axios';
import { useAppSelector } from '../../app/hooks';

const Calender = () => {
  const [activeButtons, setActiveButtons] = useState("");
  const [gradeButtons,setGradeButtons]=useState([])
  const [calendarData,setCalendarData]=useState([])
  const user = useAppSelector((state) => state.user)

  useEffect(()=>{
    let childrenDetails = user.userDetails?.children_details
    let grades=[]

    for (let index = 0; index < childrenDetails?.length; index++) {
      grades.push(childrenDetails[index].Grade)
    }

    setActiveButtons(grades[0])
    getCalendar(grades[0])
    const filteredArr = grades.filter((item, index) => {
      return grades.indexOf(item) === index;
    })
    setGradeButtons(filteredArr)
  },[user.userDetails])

  const getCalendar=async(button : string)=>{
    await  axios.get(
      `https://atlas.smsplus.lk/api/v1/class/schedules?grade=${button}`,
      { headers: { Authorization: `Bearer 1eAMTBHRwNcvA0JqBsup9xkwsywcHmnp3eoh9Wkj9a2ea2cc` } }
    )
    .then((response)=>{
      let data = [
        {
          day: "Monday",  
          schedule: []
        },
        {
          day: "Tuesday",
          schedule: []
        },
        {
          day: "Wednesday",
          schedule: []
        },
        {
          day: "Thursday",
          schedule: []
        },
        {
          day: "Friday",
          schedule: []
        },
        {
          day: "Saturday",
          schedule: []
        },
        {
          day: "Sunday",
          schedule: []
        }
      ];

      if(response.data.length>0){ 
        response.data.map((item:any, index:number)=>{
          switch (item.on) {
            case "monday":
              data[0].schedule.push(item)
              break
            case "tuesday":
              data[1].schedule.push(item)
              break
            case "wednesday":
              data[2].schedule.push(item)
              break
            case "thursday":
              data[3].schedule.push(item)
              break
            case "friday":
              data[4].schedule.push(item)
              break
            case "saturday":
              data[5].schedule.push(item)
              break
            case "sunday":
              data[6].schedule.push(item)
              break
            default:
              break
          }
        })

        data.map((item:any, index:number)=>{
          item.schedule.sort((a:any, b:any) => {
            if (a.start_at < b.start_at) {
              return -1;
            }
            if (a.start_at > b.start_at) {
              return 1;
            }
            return 0;
          });
        })
      }
      setCalendarData(data)
    });
  }
  const getBackgroundColor =(subject:string)=>{
    let background = "#3BA6F4"
    switch (subject) {
      case "Art Classes":
        background = "#ED1B6D"
        break
      case "Math Classes":
        background = "#016CA0"
        break
      case "English Classes":
        background = "#01939D"
        break
        case "Sinhala Classes":
          background = "#9F0177"
          break
      default:
        break
    }
    return background
  }

  const getBackgroundColorRound =(subject:string)=>{
    let background = "#2875ac"
    switch (subject) {
      case "Art Classes":
        background = "#9b1448"
        break
      case "Math Classes":
        background = "#003955"
        break
      case "English Classes":
        background = "#00545a"
        break
        case "Sinhala Classes":
          background = "#52003d"
          break
      default:
        break
    }
    return background
  }

  const dynamicButtonStyles = {
    backgroundColor: 'white',
    color: 'black',
    borderColor: 'black',
    border: '1px solid transparent', // Ensure there's an initial transparent border
    padding: '4px',
    width: '153px',
    fontFamily: 'Arial',
    transition: 'border-color 0.3s ease', // Add transition for smooth effect
    '&:hover': {
      color: 'black',
      borderColor: 'black', // Change border color on hover
    },
  };

  const handleClick = (value :string) => {
    setActiveButtons(value);
    getCalendar(value)
  };
  
  return (
    <Container sx={{
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center',
      pb:'10px',
      backgroundColor: '#F7F7F7',
      borderRadius: '10px',
    }}>
      <Container
        sx={{
          ...headingstyles,
          backgroundColor: '#F7F7F7',
        }}>
        <h1 style={{ fontSize: "46px" }}>WEEKLY TIME TABLE</h1>
        <Grid container rowGap={1} columnGap={2} sx={{
          innerheadingStyles,
          alignItems: "flex-start",
          backgroundColor: '#F7F7FF',
          flexDirection: {xs:"column-reverse",sm:"column-reverse", md: "row",lg:"row"},
          background:"#EFEFEF",
          padding:"5px"
          }}>
          {gradeButtons.map((grade, index) => (
            <Grid item xs={2} >
              <Button
              key={index}
                sx={{
                  ...dynamicButtonStyles,
                  backgroundColor:
                    activeButtons === grade ? "rgb(255,86,0)" : "#EFEFEF",
                  color: activeButtons === grade ? "white" : "black",
                  fontWeight:600,
                  fontSize:"16px",
                  "&:hover": {
                    color: "black",
                    borderColor: "black",
                  },
                }}
                onClick={() => handleClick(grade)}
              >
                {grade}
              </Button>
          </Grid>
          ))}
        </Grid>
        <Container sx={{
          minWidth: '200px',
          overflow: 'scroll',
        }}>
          <Grid container sx={{...weekdaysStyles, width:'1400px', minHeight:"600px", overflowX:'auto'}}>
            {calendarData.map((day: any, index: number) => (
              <Grid item xs={1.71} key={index} >
                <Typography sx={{mb:"30px"}}>{day.day}</Typography>
                <Grid item xs={1.71} sx={{mb: "40px"}}>
                  {[...Array(4)].map((_, gridIndex) => (
                    <Grid key={gridIndex}>
                      {gridIndex < day.schedule.length ? (
                        <Grid sx={gridStyles} style={{backgroundColor:getBackgroundColor(day.schedule[gridIndex].curriculum.subject.name)}}>
                          <Grid sx={{fontWeight: 'bold',display:"flex"}}>
                            <div style={{backgroundColor:getBackgroundColorRound(day.schedule[gridIndex].curriculum.subject.name), borderRadius:10, width:"15px" ,height:"15px", marginRight:"10px"}}></div>
                            {day.schedule[gridIndex].curriculum.subject.name}
                          </Grid>
                          <Grid sx={{marginLeft:"22px"}} >{day.schedule[gridIndex].teacher}</Grid>
                          <Grid sx={{fontWeight: 'bold',marginLeft:"22px"}}>
                            {day.schedule[gridIndex].start_at.slice(0, 5)} -{" "}
                            {day.schedule[gridIndex].finish_at.slice(0, 5)}
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid sx={noclassGridStyles}>
                        </Grid>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Container>
    </Container>
  )
}

export default Calender