import { Button } from "@mui/material"

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  sx?: any
  children?: React.ReactNode
  onClick?: any
  href?: string
}

export const PrimaryButton = (props: ButtonProps) => {
  if (props.href) {
    return (
      <a href={props.href}>
        <Button
          onClick={props.onClick}
          sx={{
            fontSize: { xs: "14px", lg: "18px" },
            height: { xs: "45px", lg: "60px" },
            fontWeight: 700,
            color: "#ffffff",
            borderRadius: "10px",
            backgroundColor: "#FF5600",
            width: "100%",
            "&:hover": {
              color: "#FF5600",
              backgroundColor: "#ffffff",
              border: "1px solid #FF5600",
            },
            ...props.sx,
          }}
        >
          {props.children}
        </Button>
      </a>
    )
  } else {
    return (
      <Button
        onClick={props.onClick}
        sx={{
          fontSize: { xs: "10px", lg: "18px" },
          height: { xs: "45px", lg: "60px" },
          fontWeight: 700,
          color: "#ffffff",
          borderRadius: "10px",
          backgroundColor: "#FF5600",
          width: "100%",
          "&:hover": {
            color: "#FF5600",
            backgroundColor: "#ffffff",
            border: "1px solid #FF5600",
          },
          ...props.sx,
        }}
      >
        {props.children}
      </Button>
    )
  }
}
