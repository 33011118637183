import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState, AppThunk } from "../../app/store"
import { defaultRequest, formDataRequest } from "../../services/api-service"

export interface UserState {
  id: string
  isLoggedIn: boolean,
  name: string
  token:string
  email: string
  message: {
    body: string
    severity: "success" | "error"
  }
  adminDetails: any
}

const initialState: UserState = {
    id: "",
    isLoggedIn: false,
    name: "",
    token: "",
    message: {
        body: "",
        severity: "error",
    },
    adminDetails: {},
    email: ""
}



export const adminSlice = createSlice({
  name: "admin",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAdminDetails(state, action) {
      state.token = action.payload.token
      state.id = action.payload.id
      state.isLoggedIn = action.payload.isLoggedIn
      state.email=action.payload.username,
      state.name=action.payload.name
    },
    // setImage(state, action) {
    //   state.admin_image = action.payload.user_image
    // },
    setMessage(state, action) {
      state.message = action.payload
    },
    resetAdmin() {
      return initialState
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {},
})

export const { setAdminDetails, setMessage, resetAdmin } = adminSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default adminSlice.reducer
