// auth.js

import axios from "axios"

const API_URL = import.meta.env.VITE_BASE_URL

let isAuthenticated = false

export const login = async (email, name) => {
  try {
    const response = await axios.post("/api/admin/login", {
      email,
      name,
    })

    const { token } = response.data.token

    localStorage.setItem("token", token)
    console.log('ctuyvgbhn')

    isAuthenticated = true
    return true
  } catch (error) {
    console.error("Login failed!", error)
    return false
  }
}

export const logout = () => {
  localStorage.removeItem("token")

  isAuthenticated = false
}

export const isUserAuthenticated = () => {
  const token = JSON.parse(JSON.parse(localStorage.getItem("persist:admin")).name) === ("Admin")
  console.log(token)
  return token
}


    // verify otp
    export const verifyOtpApi = async (body: any, token: string) => {
      return axios.post(
        `${API_URL}api/admin/verifyOtp`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    };

    export const resetPassword = async (body: any, token: string) => {
      return axios.post(
        `${API_URL}api/admin/changePassword`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    };