import { Box, Dialog, Divider, Modal, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import CustomButton from '../buttons'
import { addMessageModal } from '../../models/messageModal/messageModalSlice'
import done_icon from "../../assets/img/Done.svg"
import { resetAdmin } from '../../models/admin/adminSlice'

const LogoutModal = () => {
  const messageModalData = useSelector((state: any) => state.messageModal);
  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(
      addMessageModal({
        modalState: false,
        modalComponentName: '',
      })
    )
  
  }
  const logoutButFunction = async() => {
    await dispatch(
      addMessageModal({
        modalState: false,
        modalComponentName: '',
      })
    )
   await dispatch(
    resetAdmin()
   )
    localStorage.removeItem("persist:admin")
   window.location.assign('/admin/login')
  }
  return (
    <Dialog
       open={messageModalData.modalState}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: 12,
            width: "400px",
            padding: "20px",
          },
        }}>
        <img src={done_icon} width={"48px"} height={"48px"} />
        <Typography
          sx={{ fontSize: "18px", fontWeight: 600, paddingTop: "15px" }}>
          Confirmation
        </Typography>

        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            color: "#667085",
            paddingTop: "10px",
          }}>
          Do you want to Logout?
        </Typography>

        <Box
          sx={{
            display: "flex",
            gap: 3,
            justifyContent: "space-between",
            marginTop: "40px",
          }}>
          <CustomButton
            variant={"outlined"}
            buttonText={"Cancel"}
            id={0}
            buttonFunction={() => {
              handleClose()
            }}
            type="secondary"
            buttonWidth="170px"
          />
          <CustomButton
            variant={"contained"}
            buttonText={"Logout"}
            id={1}
            buttonFunction={() => {
              logoutButFunction()
            }}
            buttonWidth="170px"
          />
        </Box>
      </Dialog>
  )
}

export default LogoutModal
