import React, { useState } from "react"
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material"
import {
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
} from "react-share"
import { GridCloseIcon } from "@mui/x-data-grid"

const ShareModal = ({ data, setData }) => {
    const [open, setOpen] = useState(true)
    return (
        <>
            <Dialog
                open={data}
                fullWidth
            >
                <DialogTitle>Share ATLAS World</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => { setData(false) }}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: "black",
                    }}
                >
                    <GridCloseIcon />
                </IconButton>
                <DialogContent>
                    <div style={{display:"flex",flexDirection:"row",justifyContent:"center",gap:30}}>
                        <div>
                            <FacebookShareButton url={"https://atlasworld.lk/"} >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                        </div>
                        <div >
                            <WhatsappShareButton url={"https://atlasworld.lk/"} title={"Atlas World"} separator=":: ">
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ShareModal
