import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material"
import OtpVerification from "../../components/modal/otpVerification"
import { useEffect, useState } from "react"
import { PrimaryButton } from "../../components/button"
import { useNavigate } from "react-router-dom"
import { Helmet } from "react-helmet"

const DeleteAccountPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(true)
  const [alertMessage, setAlertMessage] = useState({
    body: null,
    severity: null,
  })

  const navigate = useNavigate()


  useEffect(() => {
    if (alertMessage.body != null) {
      const interval = setTimeout(() => {
        setAlertMessage({ body: null, severity: null })
      }, 2000)

      return () => {
        clearTimeout(interval)
      }
    }
  }, [alertMessage])


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' 
    });
  }, []); 

  return (
    <>
     <Helmet>
      <title>Atlas World</title>
    </Helmet>
      <Container
        sx={{
          width: "100%",
          maxWidth: "unset !important",
          background:
            "linear-gradient(183.57deg, rgba(255, 155, 78, 0.9) 2.93%, rgba(252, 183, 85, 0.9) 33.26%, rgba(248, 214, 93, 0.9) 55.9%, rgba(255, 255, 255, 0.9) 89.85%)",
          padding: { xs: "20px", md: "100px" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            background: "#ffffff",
            display: "flex",
            flexDirection: "column",
            px: { xs: "20px", md: "84px" },
            py: { xs: "10px", md: "74px" },
            borderRadius: "10px",
            width: { xs: "70%", md: "40%" },
          }}
        >
             <Grid container spacing={2} direction="column">
            {alertMessage.body && (
              <Grid item xs={12}>
                <Container>
                  <Alert
                    severity={alertMessage.severity}
                    sx={{ width: { xs: "180px", sm: "220px", md: "350px" } }}
                  >
                    <div
                      style={{
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {alertMessage.body}
                    </div>
                  </Alert>
                </Container>
              </Grid>
            )}
          {isModalOpen ? (
            <OtpVerification
              mobileAPI="api/remove-account/request"
              otpAPI="api/remove-account/check"
              handleClosePopup={() => {
                setIsModalOpen(false)
              }}
              isForgotPassword={false}
              setAlertMessage={(value:any) =>{setAlertMessage(value)}}
            />
          ) : (
            <Grid
              container
              sx={{
                height: "250px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                xs={12}
                style={{
                  marginTop: "80px",
                }}
              >
                <Typography
                  style={{
                    fontSize: "22px",
                    textAlign: "center",
                    fontWeight: 700,
                  }}
                >
                  Mobile Number successfully verified
                </Typography>
              </Grid>
              <Grid item xs={12}>
  
                  <PrimaryButton
                    onClick={() => {
                      navigate("/")
                    }}
                  >
                    Done
                  </PrimaryButton>
              </Grid>
            </Grid>
          )}
          </Grid>
        </Box>
      </Container>
    </>
  )
}

export default DeleteAccountPage
