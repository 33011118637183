export const ContainerStyles = {
  maxWidth: "unset !important",
  paddingRight: { xs: "16px", sm: "90px" },
  paddingLeft: { xs: "16px", sm: "90px" },
  backgroundColor: "#FF5600",
  paddingBottom: "64px",
}

export const LinkStyles = {
  fontSize: "20px",
  fontWeight: 700,
  color: "#FFFFFF",
  "& a": {
    fontWeight: 700,
    color: "#FFFFFF",
    textDecoration: "none",
  },
}

export const FooterTextStyles = {
  lineHeight: "16.1px",
  fontSize: "14px",
  fontWeight: 400,
  color: "#FFFFFF",
  "& a": {
    color: "#FFFFFF",
    textDecoration: "none",
  },
}
