import axios from "axios"


const API_URL = import.meta.env.VITE_BASE_URL

export const defaultRequest = axios.create({
  baseURL: API_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "multipart/form-data; charset=UTF-8",
    Accept: "application/json",
  },
})

export const formDataRequest = axios.create({
  baseURL: API_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "application/json",
  },
})

export const formDataAuthRequest = (token: string) =>
  axios.create({
    baseURL: API_URL,
    timeout: 30000,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  })

  // admin

  const adminToken = JSON.parse(localStorage.getItem("persist:admin"))?.token.replaceAll("\"","")

  export const AXIOSINSTANCE =() =>
    axios.create({
      baseURL: API_URL,
      timeout: 30000,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `Bearer ${adminToken}`,
      },
    })
  
