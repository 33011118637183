export const ContainerStyles = {
  width: "100%",
  maxWidth: "unset !important",
  background:
    "linear-gradient(183.57deg, rgba(255, 155, 78, 0.9) 2.93%, rgba(252, 183, 85, 0.9) 33.26%, rgba(248, 214, 93, 0.9) 55.9%, rgba(255, 255, 255, 0.9) 89.85%)",
}

export const InnerContainerStyles = {
  maxWidth: "1092px !important",
  pt: "86px",
}

export const FormContainer = {
  background: "rgba(255, 255, 255, 0.9)",
  display: "flex",
  flexDirection: "column",
  mt: "47px",
  px: { xs:"20px", md:"84px"},
  py: { xs:"10px", md:"74px"},
  borderRadius: "10px",
}

export const Heading = {
  fontSize: "40px",
  fontWeight: "700",
  lineHeight: "43px",
  letterSpacing: "0em",
  textAlign: "center",
}

export const SubHeading = {
  fontSize: "29px",
  fontWeight: "700",
  lineHeight: "30px",
  letterSpacing: "0em",
  textAlign: "left",
  mt: "26px",
  mb: "0px",
}

export const TwoColFields = {
  display: "flex",
  mt: "20px",
  gap: { xs: "20px", md: "20px" },
  flexDirection: { xs: "column", md: "row" },
  position: "relative",
}

export const ButtonStyles = {
  width: "auto",
  height: "25px",
  borderRadius: "50%",
  fontSize: "25px",
  cursor: "pointer",
}
