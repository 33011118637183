import { MuiOtpInput } from "mui-one-time-password-input";
import styled from "styled-components";

export const StyledOTPContainer = styled(MuiOtpInput)`
  display: flex;
  gap: 30px;
  margin-inline: auto;
  justify-content: center;
  margin-bottom: 10px;
  border-color: #FF5600; 

  & .MuiOutlinedInput-root {
    border-radius: 30px;
    width: 50px;
    height: 50px;
   
    &:focus {
      border-color: #FF5600; 
    }
  }

 
`;
