import { Box, CircularProgress, Grid, Typography, } from "@mui/material"
import { useEffect, useState } from "react"
import PageTitleSection from "../../../components/pageTitle"
import BannerCard from "../../../components/bannerCard"
import { AXIOSINSTANCE } from "../../../services/api-service"
import { useDispatch } from "react-redux"
import { addAlert } from "../../../models/alert/alertSlice"
import BannerDetails from "./bannerDetails"
const API_URL = import.meta.env.VITE_BASE_URL

const CampaignManagement = () => {
  const [isDetailPopupOpen, setIsDetailPopupOpen] = useState<boolean>(false)
  const [bannerData,setBannerData] = useState<any>([])
  const [selectedID,setSelectedID] = useState<number>()
  const [loading, setLoading] = useState(true)
  const token = JSON.parse(localStorage.getItem("persist:admin"))

  const dispatch = useDispatch()

  const bannerType= [{
    id:1,
    title:"Banner 1 (Hero Without Log in)"
  },
{
  id:2,
  title:"Banner 2 (Hero Banner With Log in)"
},
{
  id:3,
  title:"Campaign Banner"
},
]

const fetchBannerData = async()=>{
 try
 { 
  const response = await AXIOSINSTANCE().get('api/campaign/get')
const banners = await response.data.data.data
  const withoutLoginBanner= await banners.filter(item=>item.place ===1)
 
  const withLoginBanner = await banners.filter(item=>item.place ==2)
  const campaignBanner = await banners.filter(item=>item.place ==3)

const flatBannerData = [...withoutLoginBanner, ...withLoginBanner, ...campaignBanner];
  setBannerData(flatBannerData)
setLoading(false)
}catch(error:any)
{
  setLoading(false)
  dispatch(
    addAlert({
      alertState: true,
      alertType: "Error",
      alertMessage: "Error",
      alertDescription: "Something is Wrong",
    })
  )
}
  }


  const handleOpenDetailPopup = () => {
    setIsDetailPopupOpen(true)
  }

  const handleCloseDetailPopup = () => {
    fetchBannerData()
    setIsDetailPopupOpen(false)
  }

  useEffect(()=>{
    fetchBannerData()
  },[])
  useEffect(() => {
    if (token.isLoggedIn =="false") {
      window.location.href = "/admin/login";
    } 
  }, [])

  return (
    <> 
     {loading ? (
        // Show loader while loading
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}>
          <CircularProgress />
        </Box>
      ) : (
      <Box sx={{ width: "100%", height: "100%" }}>
        {/* ........................page Title Component......................... */}
        <PageTitleSection
        btnState={false}
          title={"Campaign & Banners Management "}
        />
        <Grid
          container
          xs={12}
          spacing={2}
          marginTop={"10px"}
          marginBottom={"20px"}
          padding="80px 0px 0px 0px"
          >
            {bannerData.map((item,index)=>(
 <Grid
 item
 key={index}
 xs={12}
sm={6}
 md={4}
>
  <Typography sx={{fontSize:"16px", color:"#9B9B9B", fontWeight:500, marginBottom:"10px"}}>{bannerType[index].title}</Typography>
<BannerCard image={`${API_URL+item.image}`}  fileType={item.file_type}
onClick={()=>{
  setSelectedID(item.id)
  handleOpenDetailPopup()}}
/>
</Grid>
            ))}
         
        </Grid>
      </Box>
)}

{isDetailPopupOpen&& (
  <BannerDetails 
  handleClosePopup={handleCloseDetailPopup}
  isPopupOpen={isDetailPopupOpen}
  selectedBannerID={selectedID}
  />
)}
    </>
  )
}

export default CampaignManagement
