import { MdDelete } from "react-icons/md"

import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  TextField,
  Typography,
} from "@mui/material"
import {
  ButtonStyles,
  ContainerStyles,
  FormContainer,
  Heading,
  InnerContainerStyles,
  SubHeading,
  TwoColFields,
} from "./styles"
import {
  CheckBox,
  FormDatePicker,
  FormInputTextField,
  FormSelect,
  FormSelectMultiple,
} from "../../components/fields"
import { useEffect, useRef, useState } from "react"
import { PrimaryButton } from "../../components/button"
import { districts } from "./districts"
import { formDataRequest } from "../../services/api-service"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { setMessage, setUserDetails } from "../../models/user/userSlice"
import { useLocation, useNavigate } from "react-router-dom"
import RegisterModal from "../../components/register-modal"
import dayjs, { Dayjs } from "dayjs"
import OtpModal from "../../components/modal/Otp"
import { Helmet } from "react-helmet"

interface childrenData {
  Child_Name: string
  DOB: string
  Grade: string
  Subjects: string[]
}

const RegisterPage = () => {
  const [childrenData, setChildrenData] = useState<childrenData[]>([
    {
      Child_Name: "",
      DOB: dayjs().format("YYYY-MM-DD"),
      Grade: "",
      Subjects: [],
    },
  ])
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState()
  const [mobile, setMobile] = useState("")
  const [district, setDistrict] = useState()
  const [optInContent, setOptInContent] = useState(true)
  const [optInPromition, setOptInPromition] = useState(true)
  const [optInAlert, setOptInAlert] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [OTPModal, setOTPModal] = useState(false)
  const [OTPToken, setOTPToken] = useState(false)

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const source = queryParams.get('source')


  const checkEmail = () => {
    let validity = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
    return validity
  }
  const checkFirstname = () => {
    let validity = /^\s*[a-zA-Z]+(?:\s[a-zA-Z]+)*\s*$/.test(firstName)
    return validity
  }
  const checkLastname = () => {
    let validity = /^\s*[a-zA-Z]+(?:\s[a-zA-Z]+)*\s*$/.test(lastName)
    return validity
  }
  const checkMobile = () => {
    let validity = /^[^+94\s-]*\d{9}$/.test(mobile)
    if (mobile.length > 10) {
      validity = false
    }
    if (!mobile.startsWith('0') && mobile.length == 10) {
      validity = false
    }
    return validity
  }
  const checkChildName = (name) => {
    let validity = /^\s*[a-zA-Z]+(?:\s[a-zA-Z]+)*\s*$/.test(name)
    return validity
  }

  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.user)

  const addField = () => {
    setChildrenData([
      ...childrenData,
      {
        Child_Name: "",
        DOB: dayjs().format("YYYY-MM-DD"),
        Grade: "",
        Subjects: [],
      },
    ])
  }

  const removeField = (index: number) => {
    if (index !== 0) {
      const newFields = [...childrenData]
      newFields.splice(index, 1)
      setChildrenData(newFields)
    }
  }

  const handleChange = (index: number, field_name: any, value: any) => {
    const newFields = [...childrenData]
    newFields[index][field_name] = value
    setChildrenData(newFields)
  }

  const alertRef = useRef(null)

  const navigate = useNavigate()

  const handleSubmit = async () => {
    let formatNumber = mobile.slice(-9)
    let payload = {
      record_source: "Atlas World Website Registration",
      first_name: firstName,
      last_name: lastName,
      email: email,
      mobile: "+94" + formatNumber,
      district: district,
      children_details: childrenData,
      opt_in_content: optInContent,
      opt_in_promotion: optInPromition,
      opt_in_alert: optInAlert,
    }

    try {
      await formDataRequest
        .post("/api/registerTempUser", payload)
        .then(async (res) => {
          setOTPModal(true)
          // setShowModal(true)
        })
    } catch (e: any) {
      console.log(e)
      const errorList = e.response.data.errors
      const errorMessages = Object.values(errorList).flat()
      dispatch(setMessage({ body: errorMessages[0], severity: "error" }))
    }
  }
  useEffect(() => {
    if (showModal) {
      // const scrollY = window.scrollY;
      document.body.style.position = "fixed"
      document.body.style.top = ``
      document.body.style.width = "100%"
    } else {
      const scrollY = document.body.style.top
      document.body.style.position = ""
      document.body.style.top = ""
      document.body.style.width = ""
      window.scrollTo(0, parseInt(scrollY || "0") * -1)
    }

    return () => {
      document.body.style.position = ""
      document.body.style.top = ""
      document.body.style.width = ""
    }
  }, [showModal])

  useEffect(() => {
    if (user.message.body) {
      alertRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [user.message.body])

  const validateForm = () => {
    if (!firstName) {
      dispatch(
        setMessage({ body: "First Name is Required", severity: "error" }),
      )
      return
    }
    if (!checkFirstname()) {
      dispatch(
        setMessage({
          body: "First Name cannot contain numbers and special characters",
          severity: "error",
        }),
      )
      return
    }

    if (!lastName) {
      dispatch(setMessage({ body: "Last Name is Required", severity: "error" }))
      return
    }
    if (!checkLastname()) {
      dispatch(
        setMessage({
          body: "Last Name cannot contain numbers and special characters",
          severity: "error",
        }),
      )
      return
    }
    if (!email) {
      dispatch(setMessage({ body: "Email is Required", severity: "error" }))
      return
    }
    if (!checkEmail()) {
      dispatch(setMessage({ body: "Invalid Email", severity: "error" }))
      return
    }
    if (!mobile) {
      dispatch(setMessage({ body: "Mobile is Required", severity: "error" }))
      return
    }
    if (!checkMobile()) {
      dispatch(setMessage({ body: "Invalid Mobile Number", severity: "error" }))
      return
    }
    if (!district) {
      dispatch(setMessage({ body: "District is Required", severity: "error" }))
      return
    }
    if (!handleChange) {
      dispatch(setMessage({ body: "Required", severity: "error" }))
      return
    }

    let isChildrenDataEmpty = childrenData.length === 0
    let isInvalidChildName = false

    for (let i = 0; i < childrenData.length; i++) {
      const child = childrenData[i]
      if (
        !child.Child_Name ||
        child.Child_Name == "" ||
        !child.DOB ||
        !child.Grade ||
        !child.Subjects ||
        child.Subjects.length === 0
      ) {
        isChildrenDataEmpty = true
        break
      }
      if (!checkChildName(child.Child_Name)) {
        isInvalidChildName = true
        break
      }
    }

    if (isInvalidChildName) {
      dispatch(
        setMessage({
          body: "Child Name cannot contain numbers and special characters",
          severity: "error",
        }),
      )
      return
    }

    if (isChildrenDataEmpty) {
      dispatch(
        setMessage({
          body: "Children Details are Required",
          severity: "error",
        }),
      )
      return
    }

    handleSubmit()
  }

  useEffect(() => {
    if (user.isLoggedIn) {
      navigate("/home")
    }
  }, [user.isLoggedIn])

  return (
    <>
     <Helmet>
      <title>Atlas World</title>
    </Helmet>
    
      <Container sx={ContainerStyles}>
        {OTPModal && (
          <OtpModal
            isOpen={OTPModal}
            setIsOpen={setOTPModal}
            getMobileNumber={mobile}
            source={source}
          />
        )}
        <Container sx={InnerContainerStyles}>
          <Typography variant="h1" sx={Heading}>
            ATLAS World Registration
          </Typography>
          <Box sx={FormContainer}>
            <Box sx={TwoColFields}>
              <FormInputTextField
                rounded
                placeholder="Enter your First Name"
                label="First Name"
                onChange={(e: any) => setFirstName(e.target.value)}
                type="text"
                required
                name="first_name"
              />
              <FormInputTextField
                rounded={true}
                placeholder="Enter your Last Name"
                label="Last Name"
                onChange={(e: any) => setLastName(e.target.value)}
                required
                name="last_name"
              />
            </Box>
            <Box sx={TwoColFields}>
              <FormInputTextField
                rounded
                placeholder="Enter your Email Address"
                label="Email Address"
                onChange={(e: any) => setEmail(e.target.value)}
                type="email"
                required
                name="email_address"
                value={email}
              />

              <FormInputTextField
                rounded
                placeholder="Enter your Mobile Number"
                label="Mobile Number"
                prefix="+94"
                required
                value={mobile}
                onChange={(e: any) => {
                  const inputValue = e.target.value
                  if (!/^[0-9]*$/.test(inputValue) || inputValue.length > 10) {
                    return
                  } else {
                    setMobile(inputValue)
                  }
                }}
                onKeyDown={(e) => {
                  if (mobile === "" && e.key === "+") {
                    e.preventDefault()
                  }

                  if (mobile.length >= 10) {
                    e.preventDefault()
                  }
                }}
                name="mobile_number"
              />
            </Box>
            <Box sx={TwoColFields}>
              <FormSelect
                sx={{
                  width: "50%",
                }}
                rounded
                handleChange={(e: any) => setDistrict(e.target.value)}
                placeholder="District"
                label="District"
                required
                name="district"
                options={districts}
              />
            </Box>
            <Typography variant="h2" sx={SubHeading}>
              Details of Children
            </Typography>
            <Box>
              {childrenData.map((field, index) => (
                <Box
                  key={index}
                  sx={{
                    ...TwoColFields,
                    marginBottom: "20px",
                    alignItems: "center",
                  }}
                >
                  <FormInputTextField
                    rounded
                    placeholder="Sarah Smith"
                    label="Child’s Name"
                    value={field.Child_Name}
                    required
                    onChange={(e: any) =>
                      handleChange(index, "Child_Name", e.target.value)
                    }
                    name="child_name"
                  />
                  <FormDatePicker
                    rounded
                    label="Date of Birth"
                    value={field.DOB}
                    onChange={(e: any) => {
                      handleChange(index, "DOB", dayjs(e).format("YYYY-MM-DD"))
                    }}
                  />
                  <FormSelect
                    sx={{
                      width: "50%",
                    }}
                    rounded
                    placeholder="Grade"
                    label="Grade"
                    required
                    value={field.Grade}
                    handleChange={(e: any) =>
                      handleChange(index, "Grade", e.target.value)
                    }
                    name="grade"
                    options={[
                      { value: "Pre-School", label: "Pre-School" },
                      { value: "Grade 01", label: "Grade 01" },
                      { value: "Grade 02", label: "Grade 02" },
                      { value: "Grade 03", label: "Grade 03" },
                      { value: "Grade 04", label: "Grade 04" },
                      { value: "Grade 05", label: "Grade 05" },
                      { value: "Grade 06", label: "Grade 06" },
                      { value: "Grade 07", label: "Grade 07" },
                      { value: "Grade 08", label: "Grade 08" },
                      { value: "Grade 09", label: "Grade 09" },
                      { value: "Grade 10", label: "Grade 10" },
                      { value: "Grade 11", label: "Grade 11" },
                    ]}
                  />
                  <FormSelectMultiple
                    sx={{
                      width: { xs: "100%", lg: "50%" },
                    }}
                    handleChange={(e: any) => {
                      handleChange(index, "Subjects", e.target.value)
                    }}
                    name={"subject"}
                    label={"Subject"}
                    rounded
                    value={field.Subjects || []}
                    options={[
                      {
                        value: "Scholarship Seminars",
                        label: "Scholarship Seminars",
                      },
                      { value: "Math Classes", label: "Math Classes" },
                      { value: "Science Classes", label: "Science Classes" },
                      { value: "English Classes", label: "English Classes" },
                      { value: "ICT Classes", label: "ICT Classes" },
                      { value: "Art Classes", label: "Art Classes" },
                    ]}
                    placeholder={"Subject"}
                    required
                  />
                  {index != 0 ? (
                    <Box
                      sx={{
                        ...ButtonStyles,
                        width: "25px",
                      }}
                    >
                      <MdDelete onClick={() => removeField(index)} />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        height: "25px",
                        borderRadius: "50%",
                        fontSize: "25px",
                        width: "25px",
                      }}
                    >
                      <div style={{ width: "25px" }}></div>
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                ...ButtonStyles,
              }}
            >
              <Button
                sx={{
                  color: "black",
                  textDecoration: "underline",
                  fontWeight: 700,
                }}
                onClick={addField}
              >
                + Add a child
              </Button>
            </Box>
            <Divider
              sx={{
                mt: "30px",
                mb: "20px",
              }}
            />
            <CheckBox
              label="I wish to continue to receive ATLAS World Content."
              onChange={(e: any) => setOptInContent(e.target.checked)}
              checked={optInContent}
            />
            <CheckBox
              label="I wish to continue to receive ATLAS World Promotional Content."
              onChange={(e: any) => setOptInPromition(e.target.checked)}
              checked={optInPromition}
            />
            <CheckBox
              label="I wish to continue to receive Important news related ATLAS World."
              onChange={(e: any) => setOptInAlert(e.target.checked)}
              checked={optInAlert}
            />

            <Typography>
              <br />
              Terms & Conditions of "ATLAS World"
              <br />
              <a href="/Atlas-World-T&C-English.pdf" target="_blank">
                View in English
              </a>
              <br />
              <a href="/Atlas-World-T&C-Sinhala.pdf" target="_blank">
                View in Sinhala
              </a>
              <br />
              <br />
              <strong>
                By continuing to 'Submit' the above details, you are agreeing to
                the Terms and Conditions contained within the above Links.
              </strong>
            </Typography>
            {user.message.body ? (
              <Alert ref={alertRef} severity={user.message.severity}>
                {user.message.body}
              </Alert>
            ) : (
              ""
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: "133px",
            }}
          >
            <PrimaryButton
              sx={{ maxWidth: "580px" }}
              onClick={() => {
                validateForm()
              }}
            >
              Register
            </PrimaryButton>
          </Box>
        </Container>
      </Container>
    </>
  )
}

export default RegisterPage
