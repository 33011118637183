import { ChangeEvent, useEffect, useState } from "react"
import { Box, CircularProgress, Grid, Typography } from "@mui/material"
import { useDispatch } from "react-redux"
import { addAlert } from "../../../../models/alert/alertSlice"
import SidePopup from "../../../../components/sidePopUp"
import CustomInput from "../../../../components/customInput"
import Uploader from "../../../../components/uploader"
import CustomButton from "../../../../components/buttons"
import { AXIOSINSTANCE } from "../../../../services/api-service"
const API_URL = import.meta.env.VITE_BASE_URL

interface EditBannerProps {
  isPopupOpen: boolean
  selectedID: number
  handleClosePopup: any
}

interface BannerFormData {
  id:number| null
  title: string
  url: string
  btnText: string
  image: any
  image_file_name: any
}

const EditBanner = ({
  isPopupOpen,
  selectedID,
  handleClosePopup,
}: EditBannerProps) => 
    {
  const dispatch = useDispatch()

  const [formData, setFormData] = useState<BannerFormData>({
    id:null,
    title: "",
    url: "",
    btnText:"",
    image: "",
    image_file_name: null,
  })

  const [currentImage, setCurrentImage] = useState()
  const [loading, setLoading] = useState<boolean>(false)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [fileType,setFileType] = useState()


//   get banner details
  const getBanner = async () =>{
    try{

      const response = await AXIOSINSTANCE().get(`/api/campaign/${selectedID}`)

        if(response.data){
setFormData({
  id:response.data.data.id,
  title:response.data.data.title,
  url: response.data.data.link,
  btnText:response.data.data.description,
  image: "",
  image_file_name: null,
})
setCurrentImage(response.data.data.image)
setFileType(response.data.data.file_type)
setPageLoading(false)
        }
    } catch (error :any)
    {
      setPageLoading(false)
   addAlert({
      alertState: true,
      alertType: "Error",
      alertMessage: "Error",
      alertDescription: "Something is Wrong",
    })
    }
  }

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }))
  }


  const setBannerImageData = (e: File) => {
    setFormData((prevData) => ({
      ...prevData,
      ["image"]: e,
    }))
  }

  const setBannerImageFileNameData = (e: string) => {
    setFormData((prevData) => ({
      ...prevData,
      image_file_name: e,
    }))
  }

// Validate url
const isValidUrl = (url) => {
    const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
    return urlRegex.test(url);
  };

  async function handleButtonaction(): Promise<void> {
    try {
      setLoading(true)
    
      if (
        !formData.title ||
        !formData.btnText
      ) {
        dispatch(
          addAlert({
            alertState: true,
            alertType: "Warning",
            alertMessage: "Error",
            alertDescription: "All fields Required",
          })
        )
        return
      }

      if(formData.url&&!isValidUrl(formData.url)){
        dispatch(
            addAlert({
              alertState: true,
              alertType: "Warning",
              alertMessage: "Error",
              alertDescription: "Button URL field must be URL",
            })
          )
          return
      }

      const formEditData = new FormData();
 formEditData.append('id',formData.id.toString())
 if (formData.title) {
 formEditData.append('title',formData.title)
 }
 if (formData.image) {
 formEditData.append('file',formData.image[0])
 }

 if (formData.url) {
 formEditData.append('link',formData.url)
 }

 if(formData.btnText){
 formEditData.append('description',formData.btnText)
 }

      const response = await AXIOSINSTANCE().post('/api/campaign/update',formEditData)

      if (response.status == 201) {
        dispatch(
          addAlert({
            alertState: true,
            alertType: "Success",
            alertMessage: "Successfully Updated",
            alertDescription:
              "Banner successfully updated.",
          })
        )
        handleClosePopup()
      }
    } catch (error: any) {
      const errorMessage = error.response.data.errors
      let alertDescription = ""

      // Concatenate error messages
      for (const key in errorMessage) {
        if (errorMessage.hasOwnProperty(key)) {
          const errorMessagesArray = errorMessage[key]
          errorMessagesArray.forEach((message: any) => {
            alertDescription += `${message}\n`
          })
        }
      }

      dispatch(
        addAlert({
          alertState: true,
          alertType: "Error",
          alertMessage: "Error",
          alertDescription: alertDescription.trim(),
        })
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(()=>{
    getBanner()
  },[])
  return (
    <SidePopup
      onClose={handleClosePopup}
      title={"Edit Banner"}
      open={isPopupOpen}>
      {pageLoading ? (
        // Show loader while loading
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}>
          <CircularProgress />
        </Box>
      ) :
     ( <>
        <Box
          sx={{
            padding: "30px",
            flex: 1,
            overflow: "hidden",
            overflowY: "auto",
          }}>

<Grid container marginBottom={3} >
<Grid xs={6}  >
    <div style = {{ borderRadius:"10px",width:"100%", overflow:"hidden"}}>
{ fileType=="image"? (<img
      src={`${API_URL}${currentImage}`}
      style={{ width: "100%", height:"354px",borderRadius:"10px"}}
  />)
:(
  <video
  style={{ 
    width: "100%", height:"354px",borderRadius:"10px",
    objectFit: "cover",
}}
  src={`${API_URL}${currentImage}`}
  autoPlay
  muted
  loop
/> 
)}
    </div>

</Grid>
</Grid>

          <Grid container spacing={2}>

            {/* Banner title */}
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color:"#344054"
                    }}>
                   Banner Title
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <CustomInput
                    id={0}
                    TextFieldName={"title"}
                    labelText={""}
                    TextFieldType={"text"}
                    variant={"outlined"}
                    onchangeFunction={handleFieldChange}
                    errorTextState={false}
                    errorText={""}
                    value={formData.title}
                    textFieldSize={"small"}
                    placeHolderText={"AddTitle"}
                  />
                </Grid>
              </Grid>
            </Grid>
           

           {/* url */}
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color:"#344054"
                    }}>
                   Button URL
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <CustomInput
                    id={0}
                    TextFieldName={"url"}
                    labelText={""}
                    TextFieldType={"text"}
                    variant={"outlined"}
                    onchangeFunction={handleFieldChange}
                    errorTextState={false}
                    errorText={""}
                    value={formData.url}
                    textFieldSize={"small"}
                    placeHolderText={"https://add URl here"}
                  />
                </Grid>
              </Grid>
            </Grid>

{/* button text */}
<Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color:"#344054"
                    }}>
                   Button Text
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <CustomInput
                    id={0}
                    TextFieldName={"btnText"}
                    labelText={""}
                    TextFieldType={"text"}
                    variant={"outlined"}
                    onchangeFunction={handleFieldChange}
                    errorTextState={false}
                    errorText={""}
                    value={formData.btnText}
                    textFieldSize={"small"}
                    placeHolderText={"Add Button Text"}
                  />
                </Grid>
              </Grid>
            </Grid>

{/* image */}
            <Grid item xs={12}>
              <Grid container spacing={1} sx={{ marginTop: 1, paddingLeft:1 }}>
                <Grid item xs={12} container spacing={1}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      marginBottom: "13px",
                      fontSize: "14px",
                      color:"#344054"
                    }}>
                   Image or Video
                  </Typography>
                  <Grid
                    sx={{ height: "176px", width: "100%", marginTop: "5px" }}>
                    <Uploader
                      containerText={
                        "Click to upload or drag and drop JPG,PNG or MP4"
                      }
                      setImageData={setBannerImageData}
                      setImageFileNameData={setBannerImageFileNameData}
                      allowMultipleUpload={false}
                      fileTypes={["image/jpeg", "image/png","video/mp4"]}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <div style={{ marginTop: "auto" }}>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            sx={{
              padding: 2,
              backgroundColor: "#ffffff",
              gap: "8px",
              marginTop: "auto",
              borderTop: "1px solid #ccc",
            }}>
            <Grid item>
              <CustomButton
                variant={"outlined"}
                buttonText={"Cancel"}
                id={1}
                buttonFunction={() => {
                  handleClosePopup()
                }}
                style={{
                  borderRadius: "10px",
                  width: "110px",
                }}
                type="secondary"
                buttonWidth="110px"
              />
            </Grid>
            <Grid item>
              <CustomButton
                variant={"contained"}
                buttonText={
                  loading ? (
                    <CircularProgress sx={{ color: "white" }} size={28} />
                  ) : (
                    "Update"
                  )
                }
                disableState={loading ? true : false}
                id={0}
                buttonFunction={() => {
                  handleButtonaction()
                }}
                style={{
                    fontSize: "14px",
                    fontWeight:500,
                  borderRadius: "10px",
                }}
                buttonWidth="110px"
              />
            </Grid>
          </Grid>
        </div>
      </>)}
    </SidePopup>
  )
}

export default EditBanner
