import { Box, Dialog, Typography } from "@mui/material"
import done_icon from "../../assets/img/Done.svg"
import CustomButton from "../buttons"

function ActionModalPopup({
  open,
  onClose,
  title,
  onAction,
}: {
  open: boolean
  onClose: () => void
  title: string
  isClose?: boolean
  onAction: () => void
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          borderRadius: 12,
          width: "400px",
          padding: "20px",
        },
      }}
    >
      <img src={done_icon} width={"48px"} height={"48px"} />
      <Typography
        sx={{ fontSize: "18px", fontWeight: 600, paddingTop: "15px" }}
      >
        Confirmation
      </Typography>

      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 400,
          color: "#667085",
          paddingTop: "10px",
        }}
      >
        {title}
      </Typography>

      <Box
        sx={{
          display: "flex",
          gap: 3,
          justifyContent: "space-between",
          marginTop: "40px",
        }}
      >
        <CustomButton
          variant={"outlined"}
          buttonText={"Cancel"}
          id={0}
          buttonFunction={() => {
            onClose()
          }}
          type="secondary"
          buttonWidth="170px"
        />
        <CustomButton
          variant={"contained"}
          buttonText={"Confirm"}
          id={1}
          buttonFunction={() => {
            onAction()
          }}
          buttonWidth="170px"
        />
      </Box>
    </Dialog>
  )
}

export default ActionModalPopup
