import React, { useEffect, useState } from "react"
import {
  formDataRequest,
} from "../../../services/api-service"
import {
  CircularProgress,
  FormControl,
  Grid,
  Typography,
} from "@mui/material"
import { TextInputField } from "../../fields"
import { PrimaryButton } from "../../button"
import { MuiOtpInput } from "mui-one-time-password-input"

interface OtpVerificationProps {
  isForgotPassword: boolean
  mobileAPI: string
  otpAPI: string
  handleClosePopup: any
  setOtpToken?: any
  setMobileNumber?: any
  setAlertMessage: any
}

const OtpVerification = (props: OtpVerificationProps) => {
  const [userNumber, setUserNumber] = useState<string>("")
  const [OTPNumber, setOTPNumber] = useState("")
  const [modalState, setModalState] = useState<string | null>("enterNumber")
  const [resendTimer, setResendTimer] = useState(120)
  const [isResendDisabled, setIsResendDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(false)


  const checkMobile = () => {
    let validity = /^[0-9+]{9,12}$/.test(userNumber)
    return validity
  }

  const handleMobileSubmit = async () => {
    if (!userNumber) {
      props.setAlertMessage({
        body: "Mobile is Required",
        severity: "error",
      })
      return
    }
    if (!checkMobile()) {
      props.setAlertMessage({
        body: "Invalid Mobile Number",
        severity: "error",
      })
      return
    }
    let formatNumber = userNumber.slice(-9)
    const payload = {
      membership_id: "+94" + formatNumber,
    }

    try {
      setIsLoading(true)
      await formDataRequest
        .post(props.mobileAPI, payload)
        .then((res) => {
          setIsLoading(false)
          setModalState("EnterOTP")
        })
    } catch (error) {
      setIsLoading(false)
      console.log(error)
      props.setAlertMessage({
        body: error.response.data.error,
        severity: "error",
      })
    }
  }

  const handleOtpSubmit = async (getOTPNumber: any) => {
    if (getOTPNumber == null) {
      if (!OTPNumber || OTPNumber.length !== 5) {
        props.setAlertMessage({ body: "OTP is Invalid", severity: "error" })
        setOTPNumber("")
        return
      }
    }

    try {
      setIsLoading(true)
      let formatNumber = userNumber.slice(-9)
      const payload = {
        membership_id: "+94" + formatNumber,
        otp: getOTPNumber || OTPNumber,
      }

      await formDataRequest
        .post(props.otpAPI, payload)
        .then((res) => {
          setIsLoading(false)
          if (props.setOtpToken) {
            props.setOtpToken(res.data.token)
          }
          if (props.setMobileNumber) {
            props.setMobileNumber(userNumber)
          }
          props.handleClosePopup()
        })
    } catch (error) {
      setOTPNumber("")
      setIsLoading(false)
      props.setAlertMessage({
        body: error.response.data.message,
        severity: "error",
      })
    }
  }

  const handleResendSubmit = async () => {
    setResendTimer(120)
    setIsResendDisabled(true)
    let formatNumber = userNumber.slice(-9)
    const payload = {
      membership_id: "+94" + formatNumber,
    }

    try {
      await formDataRequest
        .post(props.mobileAPI, payload)
        .then((res) => {
          props.setAlertMessage({ body: "OTP resent", severity: "success" })
        })
    } catch (error) {
      console.log(error)
      props.setAlertMessage({
        body: error.response.data.message,
        severity: "error",
      })
    }
  }

  const formatTime = (seconds: any) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes}:${
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
    }`
  }

  useEffect(() => {
    let countdownInterval: any

    if (resendTimer > 0 && isResendDisabled) {
      countdownInterval = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1)
      }, 1000)
    } else {
      setIsResendDisabled(false)
    }

    return () => {
      clearInterval(countdownInterval)
    }
  }, [resendTimer, isResendDisabled])

  useEffect(() => {
    if (modalState === "EnterOTP") {
      setIsResendDisabled(true)
      setResendTimer(120)
    } else {
      setResendTimer(0)
    }
  }, [modalState])

  const modalViewer = () => {
    switch (modalState) {
      case "enterNumber":
        return (
          <>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "5px",
              }}
            >
              <Typography style={{ fontSize: "1.5em" }}>
                {props.isForgotPassword ? "Forgot Password" : "Delete Profile"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "5px",
              }}
            >
              <Typography style={{ fontSize: "1em" }}>
                {props.isForgotPassword
                  ? "Lost your password? Please enter your phone number."
                  : "Please enter your phone number"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ width: "100%", mb: "33px", display: "flex" }}>
                <TextInputField
                  placeholder="Enter your Mobile Number"
                  name="phone"
                  value={userNumber}
                  prefix="+94"
                  sx={{
                    border: "1px solid #D0D5DD",
                  }}
                  onChange={(e: any) => {
                    const inputValue = e.target.value.replace(/^\+94/, "")
                    if (
                      !/^[0-9]*$/.test(inputValue) ||
                      inputValue.length > 10
                    ) {
                      return
                    } else {
                      setUserNumber(inputValue)
                    }
                  }}
                  keyPressFunction={() => {
                    handleMobileSubmit()
                  }}
                  type="text"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {isLoading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </div>
              ) : (
                <PrimaryButton
                  onClick={() => {
                    handleMobileSubmit()
                  }}
                >
                  Continue
                </PrimaryButton>
              )}
            </Grid>
          </>
        )

      case "EnterOTP":
        return (
          <>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "5px",
              }}
            >
              <Typography style={{ fontSize: "1.5em" }}>
                OTP Verification
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "5px",
              }}
            >
              <Typography style={{ fontSize: "1em" }}>
                Enter the 5 digit OTP code sent to the number{" "}
                {"+94" + userNumber.slice(-9)}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <MuiOtpInput
                value={OTPNumber}
                onChange={(e) => {
                  setOTPNumber(e)
                }}
                length={5}
                autoFocus
                className="my-custom-class"
                onComplete={(e) => {
                  handleOtpSubmit(e)
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: "15px" }}>
              {isLoading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </div>
              ) : (
                <PrimaryButton
                  onClick={() => {
                    handleOtpSubmit(null)
                  }}
                >
                  Verify
                </PrimaryButton>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                marginTop: "5px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography>
                Didn't receive the code yet ?{" "}
                {isResendDisabled ? (
                  <span>{formatTime(resendTimer)}</span>
                ) : (
                  <span
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: "blue",
                    }}
                    onClick={() => {
                      handleResendSubmit()
                    }}
                  >
                    Resend Code
                  </span>
                )}
              </Typography>
            </Grid>
          </>
        )
      default:
        break
    }
  }

  return <>{modalViewer()}</>
}

export default OtpVerification
