import { Box, CircularProgress, Grid, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import {  useSelector } from "react-redux"
import { AXIOSINSTANCE } from "../../../services/api-service"
import PageTitleSection from "../../../components/pageTitle"
import ChangePassword from "./changePassword"
import EditUser from "./editUser"

const Profile = () => {

  const [currentUser, setCurrentUser] = useState<any>()
  const [loading, setLoading] = useState(true)
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
  const [isEditPopupOpen, setIsEditPopupOpen] = useState<boolean>(false)
  const token = JSON.parse(localStorage.getItem("persist:admin"))
  const adminData = useSelector((state: any) => state.admin);

  // get User data
  const fetchUserDetail = async () => {
    try {
      const response = await AXIOSINSTANCE().get(`/api/admin/${adminData.id}`)

      if (response.status == 200) {
        setCurrentUser(response.data.data)
      }
    } catch (error) {
      setLoading(false)
      console.log("Fetch trader error", error)
    } finally {
      setLoading(false)
    }
  }

  const handleOpenPopup = () => {
    setIsPopupOpen(true)
  }

  const handleClosePopup = () => {
    setIsPopupOpen(false)
  }

  const handleEditOpenPopup = () => {
    setIsEditPopupOpen(true)
  }

  const handleEditClosePopup = () => {
    fetchUserDetail()
    setIsEditPopupOpen(false)
  }

  useEffect(() => {
  fetchUserDetail()
  }, [])

  useEffect(() => {
    if (token.isLoggedIn =="false") {
      window.location.href = "/admin/login";
    } 
  }, [])
  
  return (
    <>
      {loading ? (
        // Show loader while loading
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ width: "100%", height: "100%" }}>
            <PageTitleSection
          title={"Profile"}
          btnText={"Edit Profile"}
          btnFunction={() => {
            handleEditOpenPopup()
          }}
        />

          {/* profile pic */}
<Box sx={{paddingTop:"50px"}}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "116px",
              width: "100%",
              backgroundColor: "#FFF2EC",
              padding: "0 20px 0 20px",
              borderRadius: "10px",
              border: "1px solid #E6E6E6",
              marginTop:"30px"
            }}>
            <img
              style={{ width: "88px", height: "88px", borderRadius: 50 }}
              src={`https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png`}
              alt="Profile pic"
            />
            <Typography
              sx={{
                fontSize: '22px',
                fontWeight: 500,
                paddingLeft: "40px",
              }}>
              {currentUser.name}
            </Typography>
          </Box>
          </Box>

          {/* profile details */}
   
          <Grid
            container
            alignItems="baseline"
            spacing={2}
            xs={6}
            sx={{
              padding: "50px 0 0 30px",
              display: "flex",
              alignItems: "flex-end",
            }}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontWeight: 500,
                  paddingBottom: "6px",
                }}>
                Email
              </Typography>
              <Box
                sx={{
                  border: "1px solid #D0D5DD",
                  borderRadius: "8px",
                  padding: "12px 16px 12px 16px",
                }}>
                <Typography
                  sx={{
                    fontWeight: 500,
                  }}>
                  {currentUser.email}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Typography
                sx={{
                  fontWeight: 500,
                  paddingBottom: "6px",
                }}>
                Mobile Number
              </Typography>
              <Box
                sx={{
                  border: "1px solid #D0D5DD",
                  borderRadius: "8px",
                  padding: "12px 16px 12px 16px",
                }}>
                <Typography
                  sx={{
                    fontWeight: 500,
                  }}>
                  {currentUser.mobile}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <div
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}>
                <Typography
                  sx={{
                    fontWeight: 500,
                    paddingBottom: "6px",
                  }}>
                  Password
                </Typography>
                <Box
                  sx={{
                    border: "1px solid #D0D5DD",
                    borderRadius: "8px",
                    padding: "12px 16px 12px 16px",
                  }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                    }}>
                    **************
                  </Typography>
                </Box>
              </div>
            </Grid>

            {/* change password btn */}
            <Grid item xs={6}>
              <Typography
                sx={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  display: "block",
                  marginBottom: "15px",
                }}
                onClick={() => {
                  handleOpenPopup()
                }}>
                Change Password
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}

{/* change pwd */}
      {isPopupOpen && (
        <ChangePassword
          handleClosePopup={handleClosePopup}
          isPopupOpen={isPopupOpen}
          selectedId={adminData.id}
        />
      )}

{/* edit profile */}
{isEditPopupOpen &&(
  <EditUser  handleClosePopup={handleEditClosePopup}
  isPopupOpen={isEditPopupOpen}
  selectedID={adminData.id} />
)}

    </>
  )
}

export default Profile
