/* eslint-disable prettier/prettier */
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { addAlert } from "../../../../models/alert/alertSlice"
import SidePopup from "../../../../components/sidePopUp"
import CustomInput from "../../../../components/customInput"
import CustomButton from "../../../../components/buttons"
import { AXIOSINSTANCE } from "../../../../services/api-service"

interface ChangePasswordProps {
  isPopupOpen: boolean
  selectedId: any
  handleClosePopup: any
}

export interface ChangePasswordFormData {
  password: string | number | undefined
  confirmpassword: string | number
}

const ChangePassword = ({
  isPopupOpen,
  selectedId,
  handleClosePopup,
}: ChangePasswordProps) => {
  const dispatch = useDispatch()

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [fieldValue, setFieldValue] = useState({
    password: "",
    passwordError: false,
    passwordErrorMsg: "",
    passwordBorderColor: "#C4C4C4",
    confirmpassword: "",
    confirmPasswordError: false,
    confirmPasswordErrorMsg: "",
  })
  const [error, setError] = useState({
    state: false,
    massage: "",
  })
  const [loading, setLoading] = useState<boolean>(false)

  const handleFieldChange = (e: any) => {
    if (e.target.name == "password") {
      if (/^.{8,}$/.test(fieldValue.password)) {
        setFieldValue((pre) => {
          return {
            ...pre,
            passwordBorderColor: "#00ff2a",
          }
        })
      } else {
        setFieldValue((pre) => {
          return {
            ...pre,
            passwordBorderColor: "#C4C4C4",
          }
        })
      }
    }
    if (e.target.name == "confirmpassword") {
      if (/^.{8,}$/.test(fieldValue.confirmpassword)) {
        setFieldValue((pre) => {
          return {
            ...pre,
            passwordBorderColor: "#00ff2a",
          }
        })
      } else {
        setFieldValue((pre) => {
          return {
            ...pre,
            passwordBorderColor: "#C4C4C4",
          }
        })
      }
    }
    if (fieldValue.confirmPasswordError && e.target.name == "confirmpassword") {
      setFieldValue((pre) => {
        return { ...pre, passwordError: false, passwordErrorMsg: "" }
      })
    }
    if (error.state) {
      setError({
        state: false,
        massage: "",
      })
    }
    setFieldValue((pre) => {
      return { ...pre, [e.target.name]: e.target.value }
    })
  }
  async function handleUpdate(): Promise<void> {
    if (!fieldValue.password || !fieldValue.confirmpassword) {
      dispatch(
        addAlert({
          alertState: true,
          alertType: "Error",
          alertMessage: "Error",
          alertDescription: "All fields are required",
        }),
      )
    } else if (fieldValue.password !== fieldValue.confirmpassword) {
      dispatch(
        addAlert({
          alertState: true,
          alertType: "Error",
          alertMessage: "Password Not matched",
          alertDescription: "Password should be same!",
        }),
      )
    } else {
      try {
        setLoading(true)
        const body = {
          password: fieldValue.password,
        }
        const response = await AXIOSINSTANCE().post(
          `/api/admin/password/${selectedId}`,
          body,
        )

        if (response.status == 200) {
          dispatch(
            addAlert({
              alertState: true,
              alertType: "Success",
              alertMessage: "Successfully Updated",
              alertDescription: "User Password is successfully changed.",
            }),
          )

          handleClosePopup()
        }
      } catch (error: any) {
        const errorMessage = error.response.data.errors
        let alertDescription = ""

        // Concatenate error messages
        for (const key in errorMessage) {
          if (errorMessage.hasOwnProperty(key)) {
            const errorMessagesArray = errorMessage[key]
            errorMessagesArray.forEach((message: any) => {
              alertDescription += `${message}\n`
            })
          }
        }

        dispatch(
          addAlert({
            alertState: true,
            alertType: "Error",
            alertMessage: "Error",
            alertDescription: alertDescription.trim(),
          }),
        )
      } finally {
        setLoading(true)
      }
    }
  }

  return (
    <>
      <SidePopup
        onClose={handleClosePopup}
        title={"Update Password"}
        open={isPopupOpen}
      >
        <Box sx={{ flex: 1, overflow: "hidden", overflowY: "auto" }}>
          <Grid
            container
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
            sx={{
              padding: 3,
              backgroundColor: "#ffffff",
              marginTop: "5px",
            }}
          >
            <Grid item xs={6}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography>New Password</Typography>
                </Grid>
                <Grid item xs={12}>
                  <CustomInput
                    id={1}
                    TextFieldName={"password"}
                    labelText={""}
                    placeHolderText={"New Password"}
                    TextFieldType={showPassword ? "text" : "password"}
                    variant={"outlined"}
                    value={fieldValue.password}
                    style={{
                      width: "350px",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                      },
                      "& .MuiOutlinedInput-input": {
                        marginLeft: "10px",
                      },
                    }}
                    textFieldSize={"small"}
                    onchangeFunction={handleFieldChange}
                    errorTextState={fieldValue.passwordError}
                    errorText={fieldValue.passwordErrorMsg}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{ marginRight: "10px" }}
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setShowPassword((show) => !show)
                          }}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {error.state ? (
                    <Typography sx={{ color: "#ff2929", display: "block" }}>
                      {error.massage}
                    </Typography>
                  ) : (
                    <div style={{ height: "24px" }}></div>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography>Confirm Password</Typography>
                </Grid>
                <Grid item xs={12}>
                  <CustomInput
                    id={2}
                    TextFieldName={"confirmpassword"}
                    labelText={""}
                    placeHolderText={"Confirm Password"}
                    TextFieldType={showConfirmPassword ? "text" : "password"}
                    variant={"outlined"}
                    value={fieldValue.confirmpassword}
                    style={{
                      width: "350px",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                      },
                      "& .MuiOutlinedInput-input": {
                        marginLeft: "10px",
                      },
                    }}
                    textFieldSize={"small"}
                    onchangeFunction={handleFieldChange}
                    errorTextState={fieldValue.confirmPasswordError}
                    errorText={fieldValue.confirmPasswordErrorMsg}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{ marginRight: "10px" }}
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setShowConfirmPassword((show) => !show)
                          }}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {error.state ? (
                    <Typography sx={{ color: "#ff2929", display: "block" }}>
                      {error.massage}
                    </Typography>
                  ) : (
                    <div style={{ height: "24px" }}></div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <div style={{ marginTop: "auto" }}>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            sx={{
              padding: 2,
              backgroundColor: "#ffffff",
              gap: "8px",
              marginTop: "auto",
              borderTop: "1px solid #ccc",
            }}
          >
            <Grid item>
              <CustomButton
                variant={"outlined"}
                buttonText={"Cancel"}
                id={1}
                buttonFunction={() => {
                  handleClosePopup()
                }}
                style={{
                  borderRadius: "10px",
                  width: "110px",
                }}
                type="secondary"
                buttonWidth="110px"
              />
            </Grid>
            <Grid item>
              <CustomButton
                variant={"contained"}
                buttonText={
                  loading ? (
                    <CircularProgress sx={{ color: "white" }} size={28} />
                  ) : (
                    "Update Password"
                  )
                }
                disableState={loading ? true : false}
                id={0}
                buttonFunction={() => {
                  handleUpdate()
                }}
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  borderRadius: "10px",
                }}
                buttonWidth="166px"
              />
            </Grid>
          </Grid>
        </div>
      </SidePopup>
    </>
  )
}

export default ChangePassword
