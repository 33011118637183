import { ChangeEvent, useState } from "react"
import { Box, CircularProgress, Grid, Typography } from "@mui/material"
import { useDispatch } from "react-redux"
import { addAlert } from "../../../../models/alert/alertSlice"
import SidePopup from "../../../../components/sidePopUp"
import CustomInput from "../../../../components/customInput"
import Uploader from "../../../../components/uploader"
import CustomButton from "../../../../components/buttons"
import { AXIOSINSTANCE } from "../../../../services/api-service"

interface AddProductProps {
  isPopupOpen: boolean
  handleClosePopup: any
}

export interface ProductFormData {
  productName: string
  url: string
  image: any
  image_file_name: any
}

const AddProduct = ({
  isPopupOpen,
  handleClosePopup,
}: AddProductProps) => 
    {
  const dispatch = useDispatch()

  const [formData, setFormData] = useState<ProductFormData>({
    productName: "",
    url: "",
   image: "",
   image_file_name: null,
  })


  const [loading, setLoading] = useState<boolean>(false)

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }))
  }


  const setProductImageData = (e: string) => {
    setFormData((prevData) => ({
      ...prevData,
      ["image"]: e,
    }))
  }

  const setProductImageFileNameData = (e: string) => {
    setFormData((prevData) => ({
      ...prevData,
      product_file_name: e,
    }))
  }

  async function handleButtonaction(): Promise<void> {
    try {
      setLoading(true)
      if (
        !formData.productName ||
        !formData.url ||
        !formData.image
      ) {
        dispatch(
          addAlert({
            alertState: true,
            alertType: "Warning",
            alertMessage: "Error",
            alertDescription: "All fields Required",
          })
        )
        return
      }

      const formAddData = new FormData();

      if (formData.productName) {
        formAddData.append('title',formData.productName)
      }
     
      if (formData.url) {
        formAddData.append('link',formData.url)
      }

      if (formData.image) {
        formAddData.append('file',formData.image[0])
      }


      const response = await AXIOSINSTANCE().post('api/product/add',formAddData)

      if (response.status == 201) {
        dispatch(
          addAlert({
            alertState: true,
            alertType: "Success",
            alertMessage: "Successfully Added",
            alertDescription:
              "Product successfully added.",
          })
        )
        handleClosePopup()
      }
    } catch (error: any) {
      const errorMessage = error.response.data.errors
      let alertDescription = ""

      // Concatenate error messages
      for (const key in errorMessage) {
        if (errorMessage.hasOwnProperty(key)) {
          const errorMessagesArray = errorMessage[key]
          errorMessagesArray.forEach((message: any) => {
            alertDescription += `${message}\n`
          })
        }
      }

      dispatch(
        addAlert({
          alertState: true,
          alertType: "Error",
          alertMessage: "Error",
          alertDescription: alertDescription.trim(),
        })
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <SidePopup
      onClose={handleClosePopup}
      title={"Add Product"}
      open={isPopupOpen}>
      <>
        <Box
          sx={{
            padding: "30px",
            flex: 1,
            overflow: "hidden",
            overflowY: "auto",
          }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color:"#344054"
                    }}>
                    Product Name
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <CustomInput
                    id={0}
                    TextFieldName={"productName"}
                    labelText={""}
                    TextFieldType={"text"}
                    variant={"outlined"}
                    onchangeFunction={handleFieldChange}
                    errorTextState={false}
                    errorText={""}
                    value={formData.productName}
                    textFieldSize={"small"}
                    placeHolderText={"Add Product Name"}
                  />
                </Grid>
              </Grid>
            </Grid>
           

           {/* url */}
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color:"#344054"
                    }}>
                    Product URL
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <CustomInput
                    id={0}
                    TextFieldName={"url"}
                    labelText={""}
                    TextFieldType={"text"}
                    variant={"outlined"}
                    onchangeFunction={handleFieldChange}
                    errorTextState={false}
                    errorText={""}
                    value={formData.url}
                    textFieldSize={"small"}
                    placeHolderText={"https://add URl here"}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={1} sx={{ marginTop: 1, paddingLeft:1 }}>
                <Grid item xs={12} container spacing={1}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      marginBottom: "13px",
                      fontSize: "14px",
                      color:"#344054"
                    }}>
                   Image
                  </Typography>
                  <Grid
                    sx={{ height: "176px", width: "100%", marginTop: "5px" }}>
                    <Uploader
                      containerText={
                        "Click to upload or drag and drop JPG or PNG"
                      }
                      setImageData={setProductImageData}
                      setImageFileNameData={setProductImageFileNameData}
                      allowMultipleUpload={false}
                      fileTypes={["image/jpeg", "image/png"]}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <div style={{ marginTop: "auto" }}>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            sx={{
              padding: 2,
              backgroundColor: "#ffffff",
              gap: "8px",
              marginTop: "auto",
              borderTop: "1px solid #ccc",
            }}>
            <Grid item>
              <CustomButton
                variant={"outlined"}
                buttonText={"Cancel"}
                id={1}
                buttonFunction={() => {
                  handleClosePopup()
                }}
                style={{
                  borderRadius: "10px",
                  width: "110px",
                }}
                type="secondary"
                buttonWidth="110px"
              />
            </Grid>
            <Grid item>
              <CustomButton
                variant={"contained"}
                buttonText={
                  loading ? (
                    <CircularProgress sx={{ color: "white" }} size={28} />
                  ) : (
                    "Create"
                  )
                }
                disableState={loading ? true : false}
                id={0}
                buttonFunction={() => {
                  handleButtonaction()
                }}
                style={{
                    fontSize: "14px",
                    fontWeight:500,
                  borderRadius: "10px",
                }}
                buttonWidth="110px"
              />
            </Grid>
          </Grid>
        </div>
      </>
    </SidePopup>
  )
}

export default AddProduct
