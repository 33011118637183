import { Box, Typography } from "@mui/material"
import CustomButton from "../buttons"
import { useContext } from "react"
import DrawerContext from "../../util/DrawerContext"

const PageTitleSection = ({
  title,
  btnState = true,
  btnText,
  btnIcon,
  btnFunction,
}: {
  title: string
  btnState?: boolean
  btnText?: string
  btnIcon?: JSX.Element
  btnFunction?: () => void
}) => {
  const userString: any = localStorage.getItem("user")
  const localAuthorizedRoute = JSON.parse(userString)
  const { drawerWidth } = useContext(DrawerContext)

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          height: "55px",
          borderBottom: "1px solid #E6E6E6",
          width: `calc(100% - ${drawerWidth == "260px" ? "330px" : "70px"} )`,
          backgroundColor: "#ffffff",
          position: "fixed",
          zIndex: 10,
        }}>
        <Typography sx={{ fontSize: "18px", fontWeight: 600 }} key={0}>
          {title}
        </Typography>
        {btnState && (
          <CustomButton
            variant={"contained"}
            buttonText={btnText}
            id={0}
            iconStart={btnIcon}
            style={{
              width: "200px",
            }}
            buttonFunction={btnFunction ? btnFunction : () => {}}
          />
        )}
      </Box>
    </>
  )
}

export default PageTitleSection
