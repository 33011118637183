import { Divider, Drawer, List } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined"
import {AccountCircleOutlined}from '@mui/icons-material';
import SingleListItem from "./singleListItem"

const SideBar = (props: { drawerwidth: string }) => {
  const { drawerwidth } = props
  

  const topFilteredRoutes = [
    {
      id: 1,
      icon: <EditIcon />,
      label: "Product Management",
      path: "/admin/producttable",
      action: "navigate",
    },
    {
      id: 2,
      icon: <LayersOutlinedIcon />,
      label: "Campaign Management",
      path: "/admin/campaignmanagement",
      action: "navigate",
    },
  ]

  const bottomFilteredRoutes = [
    {
      id: 5,
      icon: <AccountCircleOutlined/>,
      label: "Profile",
      path: "/admin/Profile",
      action: "navigate",
    },
    {
      id: 6,
      icon: "",
      label: "Logout",
      path: "",
      action: "logout",
    },
  ]


  return (
    <>
      <Drawer
        anchor="left"
        variant="permanent"
        sx={{
          width: drawerwidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerwidth,
            boxSizing: "border-box",
            bgcolor: "#FF5600",
            color: "#ffffff",
            zIndex: 10,
          },
        }}
      >
        {/* <SidebarList /> */}
        <div style={{ marginTop: "60px", marginBottom: "10px" }}>
        <List>
          {topFilteredRoutes.map((data: any, index: number) => (
            <div style={{ padding: "10px" }} key={index}>
              <SingleListItem
                id={data.id}
                icon={data.icon}
                Text={data.label}
                path={data.path}
                action={data.action}
              />
            </div>
          ))}
        </List>
      </div>
      <div style={{ marginTop: "auto", paddingBottom: "10px" }}>
        <Divider variant="middle" />
        <List>
          {bottomFilteredRoutes.map((data: any, index: number) => (
            <SingleListItem
              key={index}
              id={data.id}
              icon={data.icon}
              Text={data.label}
              path={data.path}
              action={data.action}
            />
          ))}
        </List>
      </div>

      </Drawer>
    </>
  )
}

export default SideBar
