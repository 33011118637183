import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import logo from "../../../assets/img/dashboard-logo.jpg"
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight"
import { useSelector } from "react-redux"

const Header = (props: { drawerWidth: string; setDrawerWidth: any }) => {
  const navigate = useNavigate()
  const { drawerWidth, setDrawerWidth } = props
  const adminData = useSelector((state: any) => state.admin);

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          width: `calc(100%)`,
          height: "70px",
          ml: drawerWidth,
          backgroundColor: "#ffffff",
          color: "#000",
          borderBottom: 1,
          borderColor: "#afafaf92",
          zIndex: 100,
        }}
      >
        <Toolbar>
          <Box
            sx={{
              position: "absolute",
              display: "flex",
              width: "260px",
              height: "75px",
              backgroundColor: "#FF5600",
              left: 0,
              paddingLeft: "0px",
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                height: "70px",
                padding: "10px 0px 0px 0px",
                marginBottom: "10px",
                marginRight: "40px",
              }}
            >
              <img src={logo} alt="Logo" height={"70px"} width={"140px"} />
            </Box>
            <IconButton
              size="large"
              edge="start"
              aria-label="menu"
              sx={{
                backgroundColor: "#FF7936",
                borderRadius: "5px",
                height: "50px",
                marginTop: "15px",
                "&:hover": {
                  backgroundColor: "#FF7936",
                },
              }}
              onClick={() => {
                if (drawerWidth == "260px") {
                  setDrawerWidth("0px")
                } else {
                  setDrawerWidth("260px")
                }
              }}
            >
              <FormatAlignRightIcon
                sx={{ color: "#ffffff", fontSize: "25px" }}
              />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              width: "100%",
              backgroundColor: "#ffffff",
              gap: 5,
            }}
          >
            <Typography sx={{ fontSize: "16px" }}>
         {adminData.name}
            </Typography>
            <Avatar
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/admin/profile")
              }}
            >
              <Typography sx={{ fontSize: "16px" }}>UN</Typography>
            </Avatar>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default Header
