import avatar1 from '../assets/img/avatars/Untitled-1.png';
import avatar2 from '../assets/img/avatars/Untitled-2.png';
import avatar3 from '../assets/img/avatars/Untitled-3.png';
import avatar4 from '../assets/img/avatars/Untitled-4.png';
import avatar5 from '../assets/img/avatars/Untitled-5.png';
import avatar6 from '../assets/img/avatars/Untitled-6.png';

const avatars: Array<string> = [avatar1, avatar2, avatar3, avatar4, avatar5, avatar6];

const getAllImages = () => {
  return avatars
}

export const getImage = (image: number) =>{
  return avatars[image]
}

class AvatarService {
  getAvatars() {
      return getAllImages();
  }

  getAvatar(key: number) {
      return getImage(key);
  }
}

export default AvatarService;